import { useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";
import { parseNumericParam } from "../../utils/parseNumericParam";

export const useFastTrackInquiryItemsTools = () => {
  const history = useHistory();

  const { inquiryId: rawInquiryId, itemId: rawItemId } = useParams<{
    inquiryId: string;
    itemId?: string;
  }>();

  const inquiryId = parseInt(rawInquiryId);
  const itemId = parseNumericParam(rawItemId);

  const closeDrawer = useCallback(() => {
    history.goBack();
  }, [history]);

  return {
    inquiryId,
    itemId,
    closeDrawer,
  };
};
