import { alpha, Box, Tooltip } from "@mui/material";
import React from "react";
import { colors } from "../../../theme/astra/colors";
import photoPlaceholder from "../../../assets/no-photo.svg";

type ItemsPreviewsProps = {
  fastTrack?: boolean;
  items: Array<{
    id: number;
    image?: string | null | undefined;
    tooltip: string;
  }>;
};

export const ItemsPreviews = ({ fastTrack, items }: ItemsPreviewsProps) => {
  const itemsElement = items.map((item) => (
    <Tooltip key={item.id} title={item.tooltip}>
      <Box
        sx={{
          width: "128px",
          height: "128px",
          flexShrink: 0,
          flexGrow: 0,
          border: `1px solid ${
            fastTrack ? alpha(colors.pink, 0.5) : colors.dustBlue4
          }`,
          borderRadius: "4px",
          backgroundColor: colors.white,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: item.image ? 0 : "16px",
        }}
      >
        <Box
          component="img"
          src={item.image ?? photoPlaceholder}
          sx={{
            width: "auto",
            maxWidth: "100%",
            height: "auto",
            maxHeight: "100%",
          }}
        />
      </Box>
    </Tooltip>
  ));

  if (itemsElement.length === 0) {
    return null;
  }

  return <>{itemsElement}</>;
};
