import React, { useCallback, useState } from "react";
import {
  Alert,
  Collapse,
  Container,
  Paper,
  Stack,
  TextField,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { PostSignInRequestDto as SignInFormProp } from "../../slices/auth/types";
import { useDispatch } from "../../store";
import { signIn } from "../../slices/auth/actions";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { fieldError, useSubmitFormErrorHander } from "../../utils/formHelpers";
import { createYupSchema, useYup } from "../../utils/validation";
import {
  FormHelperTextProps,
  InputLabelProps,
  NewDesignInputProps,
  StyledFieldTitle,
  StyledLink,
  StyledSubmitSpinnerButton,
  StyledTitleBox,
} from "./StyledComponents";
import { SignInBackground } from "./SignInBackground";
import { useWindowSize } from "usehooks-ts";

const signInFormSchemaCreator = createYupSchema((t) =>
  yup.object({
    email: yup.string().email(t("incorrectEmail")).required(t("required")),
    password: yup
      .string()
      .min(3, t("tooShort", { minLength: 3 }))
      .required(t("required")),
  })
);

export const SignIn: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const schema = useYup(signInFormSchemaCreator);
  const { control, handleSubmit } = useForm<SignInFormProp>({
    resolver: yupResolver(schema),
  });
  const [loading, setLoading] = useState(false);
  const { catchFormError, formError, fieldsErrors } = useSubmitFormErrorHander(
    t("error.signIn")
  );
  const size = useWindowSize();

  const onSubmit = useCallback(
    (fields: SignInFormProp) => {
      setLoading(true);
      dispatch(signIn(fields))
        .unwrap()
        .then(() => {
          setLoading(false);
        })
        .catch((error: Error) => {
          setLoading(false);
          catchFormError(error);
        });
    },
    [catchFormError, dispatch]
  );

  return (
    <Container maxWidth="sm">
      <SignInBackground />
      <Paper elevation={0} sx={{ position: "sticky", zIndex: "10" }}>
        <Stack
          spacing={4}
          sx={{
            marginTop: `${
              size.height < 556 ? "20" : (size.height - 516) / 2
            }px`,
            marginBottom: `${size.height < 556 ? "20" : "0"}px`,
            paddingTop: "65px",
            paddingInline: "110px",
            paddingBottom: "80px",
            border: "solid 1px #E7F1FF",
            borderRadius: "4px",
          }}
          component="form"
          autoComplete="on"
          onSubmit={handleSubmit(onSubmit)}
        >
          <StyledTitleBox>{t("sign in")}</StyledTitleBox>
          {formError && (
            <Collapse in={!!formError}>
              <Alert severity="error">{formError}</Alert>
            </Collapse>
          )}
          <Stack>
            <StyledFieldTitle sx={{ marginTop: "7px" }}>
              {t("emailNew")}
            </StyledFieldTitle>
            <Controller
              name="email"
              control={control}
              render={({ field, fieldState: { invalid, error } }) => {
                const [serverInvalid, serverError] = fieldError(
                  "email",
                  fieldsErrors
                );
                return (
                  <TextField
                    variant="outlined"
                    size="small"
                    placeholder={t("emailNew")}
                    {...field}
                    error={invalid || serverInvalid}
                    helperText={error?.message || serverError}
                    InputLabelProps={InputLabelProps}
                    InputProps={NewDesignInputProps}
                    FormHelperTextProps={FormHelperTextProps}
                  />
                );
              }}
            />
            <StyledFieldTitle sx={{ marginTop: "12px" }}>
              {t("password")}
            </StyledFieldTitle>
            <Controller
              name="password"
              control={control}
              render={({ field, fieldState: { invalid, error } }) => {
                const [serverInvalid, serverError] = fieldError(
                  "password",
                  fieldsErrors
                );
                return (
                  <TextField
                    variant="outlined"
                    size="small"
                    placeholder={t("password")}
                    type="password"
                    {...field}
                    error={invalid || serverInvalid}
                    helperText={error?.message || serverError}
                    InputLabelProps={InputLabelProps}
                    InputProps={NewDesignInputProps}
                    FormHelperTextProps={FormHelperTextProps}
                  />
                );
              }}
            />
          </Stack>
          <StyledSubmitSpinnerButton
            loading={loading}
            fullWidth
            sx={{
              backgroundColor: loading ? "#C5D2E0" : "#2191FF",
            }}
          >
            {t("actions.submit", { ns: "common" })}
          </StyledSubmitSpinnerButton>
          <StyledLink to={`/forgotpassword`}>
            {t("forgotpassword.forgotpasswordLink", { ns: "pages" })}
          </StyledLink>
        </Stack>
      </Paper>
    </Container>
  );
};
