import { Box, styled, Typography } from "@mui/material";
import React from "react";
import { colors } from "../../../../theme/astra/colors";

type DataBoxProps = {
  label: string;
  children: string | React.ReactNode;
  reverse?: boolean;
};

export const DataBox = ({ label, children, reverse }: DataBoxProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        gap: "8px",
        alignItems: "center",
        flexDirection: reverse ? "row-reverse" : "row",
      }}
    >
      <Typography
        component="span"
        sx={{
          fontSize: "14px",
          lineHeight: "24px",
          fontWeight: "400",
          color: colors.dustBlue1,
        }}
      >
        {label}
      </Typography>
      {typeof children === "string" ? (
        <DataBoxValue>{children}</DataBoxValue>
      ) : (
        children
      )}
    </Box>
  );
};

export const DataBoxValue = styled(Typography)({
  fontSize: "16px",
  fontWeight: "500",
  lineHeight: "24px",
  color: colors.darkBlue,
});
