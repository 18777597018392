import React, { useCallback, useEffect, useMemo } from "react";
import {
  useEditUserMutation,
  useGetUsersQuery,
} from "../../../slices/users/api";
import { useTranslation } from "react-i18next";
import { useTableUrlTools } from "../../../utils/useTableUrlTools";
import { useFeedSuccess } from "../../../utils/feedHooks";
import { FormProvider, useForm } from "react-hook-form";
import { EditUserForm } from "./types";
import { UserForm, userFormSchemaCreator } from "./UserForm";
import { useDispatch, useSelector } from "../../../store";
import { logOut } from "../../../slices/auth/actions";
import { EditUserRequestDto } from "../../../slices/users/types";
import { useSubmitFormErrorHander } from "../../../utils/formHelpers";
import { yupResolver } from "@hookform/resolvers/yup";
import { useYup } from "../../../utils/validation";
import { Spinner } from "../../../components/wideDrawer/Spinner";
import { AstraForm } from "../../../components/astraWideDrawer/AstraForm";
import { selectSelfEmail, selectSelfId } from "../../../slices/auth/selectors";

export const Edit: React.FC = () => {
  const { t } = useTranslation("pages");
  const dispatch = useDispatch();
  const { id, closeDrawer } = useTableUrlTools("/users");
  const feedSuccess = useFeedSuccess();
  const schema = useYup(userFormSchemaCreator);
  const formMethods = useForm<EditUserForm>({
    resolver: yupResolver(schema),
  });
  const { handleSubmit, reset } = formMethods;
  const selfId = useSelector(selectSelfId);
  const selfEmail = useSelector(selectSelfEmail);
  const editingSelf = selfId === id;
  const { catchFormError, formError, fieldsErrors } = useSubmitFormErrorHander(
    t("users.errors.edit")
  );

  const {
    isLoading: areUsersLoading,
    data: users,
    error: usersLoadingError,
  } = useGetUsersQuery();

  const user = useMemo(
    () => users?.find((user) => user.id === id),
    [id, users]
  );

  useEffect(() => {
    if (!user) {
      return;
    }

    const fields: EditUserForm = {
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      title: null,
      role: user.role,
      active: user.active,
    };

    if (
      user.role === "principal" ||
      user.role === "headOfDepartment" ||
      user.role === "teacher"
    ) {
      fields.title = user.title;
      fields.schoolId = user.school?.id;
    }

    if (user.role === "inquiryManager") {
      fields.inquiryManagerInquiriesIds =
        user.inquiryManagerInquiries?.map((inquiry) => inquiry.id) ?? [];
    }

    if (user.role === "orderManager") {
      fields.orderManagerInquiriesIds =
        user.orderManagerInquiries?.map((inquiry) => inquiry.id) ?? [];
    }

    reset(fields);
  }, [reset, user]);

  const [editUser, { isLoading: isEditLoading }] = useEditUserMutation();

  const onSubmit = useCallback(
    (form: EditUserForm) => {
      if (!id) {
        return;
      }

      const userToSave: EditUserRequestDto = {
        id,
        email: form.email,
        firstName: form.firstName,
        lastName: form.lastName,
        title: null,
        role: form.role,
        schoolId: null,
        active: form.active,
      };

      if (
        form.role === "teacher" ||
        form.role === "headOfDepartment" ||
        form.role === "principal"
      ) {
        userToSave.title = form.title ?? null;
        userToSave.schoolId = form.schoolId ?? null;
      }

      if (form.role === "inquiryManager") {
        userToSave.inquiryManagerInquiriesIds = form.inquiryManagerInquiriesIds;
      }

      if (form.role === "orderManager") {
        userToSave.orderManagerInquiriesIds = form.orderManagerInquiriesIds;
      }

      editUser(userToSave)
        .unwrap()
        .then(() => {
          feedSuccess(t("users.editSuccess"), `/users/${id}`);
          closeDrawer();

          /*
           * Email is used as subject in JWT so when you're editing your own
           * email, you have to relogin in order for it to update.
           */
          if (editingSelf && selfEmail !== form.email) {
            dispatch(logOut());
          }
        })
        .catch(catchFormError);
    },
    [
      catchFormError,
      closeDrawer,
      dispatch,
      editUser,
      editingSelf,
      feedSuccess,
      id,
      selfEmail,
      t,
    ]
  );

  useEffect(() => {
    if (usersLoadingError) {
      closeDrawer();
    }
  }, [closeDrawer, usersLoadingError]);

  if (areUsersLoading) {
    return <Spinner />;
  }

  if (!user) {
    return null;
  }

  return (
    <AstraForm
      heading={t("users.editTitle")}
      onSubmit={handleSubmit(onSubmit)}
      loading={isEditLoading}
      formError={formError}
      submitText={t("actions.submit", { ns: "common" })}
      sx={{
        color: "#164173", //Dark blue
        padding: 4,
      }}
    >
      <FormProvider {...formMethods}>
        <UserForm
          fieldsErrors={fieldsErrors}
          isActiveCheckboxShown
          editing={id}
          isEditSchoolUserPropsDisabled={user.isEditSchoolUserPropsDisabled}
        />
      </FormProvider>
    </AstraForm>
  );
};
