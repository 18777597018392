import { createAsyncThunk } from "@reduxjs/toolkit";
import { saveObjectToLocalStorage } from "../../utils/localStorageTools";
import { shopLocalStorageKeys } from "./localStorageKeys";
import { ThunkApi } from "../../store";

export const setShopList = createAsyncThunk<
  number | null,
  number | null,
  ThunkApi
>("setShopList", (list) => {
  saveObjectToLocalStorage(shopLocalStorageKeys.list, list);
  return list;
});
