import React, { useMemo } from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Button } from "../../../../../components/astraComponents/Button";
import { Plus } from "../../../../../components/astraIcons/Plus";
import { colors } from "../../../../../theme/astra/colors";
import { baseUrl } from "../../../baseUrl";
import { Link } from "react-router-dom";
import { MoveToFavorites } from "./MoveToFavorites";
import { RemoveItems } from "./RemoveItems";

export const TableControls = ({
  inquiryId,
  isAstra,
  selectedIds,
}: {
  inquiryId: number;
  selectedIds: number[];
  isAstra: boolean;
}) => {
  const { t } = useTranslation("pages");

  const buttons = useMemo(() => {
    if (selectedIds.length > 0) {
      return (
        <>
          <Typography
            component="span"
            sx={{
              fontSize: "12px",
              lineHeight: "16px",
              fontWeight: "400",
              color: colors.darkBlue,
              marginRight: "24px",
            }}
          >
            {t("fastTrackInquiries.itemsSelected", {
              count: selectedIds.length,
            })}
          </Typography>
          <MoveToFavorites inquiryId={inquiryId} selectedIds={selectedIds} />
          <RemoveItems
            inquiryId={inquiryId}
            isAstra={isAstra}
            selectedIds={selectedIds}
          />
        </>
      );
    }

    if (isAstra) {
      return null;
    }

    return (
      <Button
        component={Link}
        variant="outlined"
        startIcon={<Plus />}
        to={`${baseUrl}/${inquiryId}/add-item`}
      >
        {t("fastTrackInquiries.listControls.addItem")}
      </Button>
    );
  }, [inquiryId, isAstra, selectedIds, t]);

  return (
    <Box
      sx={{
        marginTop: "24px",
        marginBottom: "16px",
        display: "flex",
        alignItems: "center",
      }}
    >
      {buttons}
    </Box>
  );
};
