import { useSelector } from "../store";
import { selectSelfRole } from "../slices/auth/selectors";
import { useLayoutEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { RouteState } from "../types";

export const useDefaultRoutes = () => {
  const history = useHistory<RouteState>();
  const location = useLocation<RouteState>();

  const selfRole = useSelector(selectSelfRole);

  useLayoutEffect(() => {
    if (!selfRole || location.pathname !== "/") {
      return;
    }

    switch (selfRole) {
      case "headOfDepartment":
      case "teacher":
      case "principal":
        history.replace("/schoolinquiries");
        break;
      default:
        history.replace("/inquiries");
    }
  }, [history, location.pathname, selfRole]);
};
