import { State } from "../../store";

export const isAuthenticated = ({ auth }: State) => !!auth.token;

export const selectPermissions = ({ auth }: State) => auth.permissions;

export const selectSelf = ({ auth }: State) => auth.user;

export const selectSelfId = ({ auth }: State) => auth.user && auth.user.id;

export const selectSelfEmail = ({ auth }: State) =>
  auth.user && auth.user.email;
export const selectSelfRole = ({ auth }: State) => auth.user && auth.user.role;

export const selectSelfStatus = ({ auth }: State) =>
  auth.user && auth.user.active;

export const selectSelfSchoolId = ({ auth }: State) => auth.user?.school?.id;

export const selectSelfSchoolCurrency = ({ auth }: State) =>
  auth.user?.school?.currency;
