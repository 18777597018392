import { Box, styled } from "@mui/material";

type GlobalContainerProps = {
  header?: boolean;
};

export const GlobalContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "header",
})<GlobalContainerProps>(({ header }) => ({
  display: "flex",
  flexDirection: "column",
  minHeight: "100vh",
  height: "fit-content",
  boxSizing: "border-box",
  paddingTop: header ? "64px" : 0,
}));
