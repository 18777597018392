import { api } from "../api";
import {
  AddItemToFastTrackInquiryRequestDto,
  AddItemToFastTrackInquiryResponseDto,
  ChangeQuantityRequestDto,
  ChangeQuantityResponseDto,
  GetCurrentFastTrackInquiryResponseDto,
  GetFastTrackInquiriesRequestDto,
  GetFastTrackInquiriesResponseDto,
  GetFastTrackInquiryRequestDto,
  GetFastTrackInquiryResponseDto,
  RemoveFastTrackInquiryRequestDto,
  RemoveFastTrackInquiryResponseDto,
  RemoveItemsFromFastTrackInquiryRequestDto,
  RemoveItemsFromFastTrackInquiryResponseDto,
  SendFastTrackInquiryToAstraRequestDto,
  SendFastTrackInquiryToAstraResponseDto,
} from "./types";

const baseUrl = "fast-track-inquiries";

export const fastTrackInquiriesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getCurrentFastTrackInquiry: builder.query<
      GetCurrentFastTrackInquiryResponseDto,
      void
    >({
      query: () => `${baseUrl}/current`,
      providesTags: () => [
        {
          type: "FastTrackInquiry",
          id: "current",
        },
      ],
    }),
    getFastTrackInquiry: builder.query<
      GetFastTrackInquiryResponseDto,
      GetFastTrackInquiryRequestDto
    >({
      query: ({ fastTrackInquiryId }) => `${baseUrl}/${fastTrackInquiryId}`,
      providesTags: (_result, _error, { fastTrackInquiryId }) => [
        {
          type: "FastTrackInquiry",
          id: "current",
        },
        {
          type: "FastTrackInquiry",
          id: fastTrackInquiryId,
        },
      ],
    }),
    getFastTrackInquiries: builder.query<
      GetFastTrackInquiriesResponseDto,
      GetFastTrackInquiriesRequestDto
    >({
      query: ({ page, pageSize }) => {
        const params = new URLSearchParams({
          page: JSON.stringify(page),
          pageSize: JSON.stringify(pageSize),
        });

        return `${baseUrl}?${params.toString()}`;
      },
      providesTags: (result) =>
        result
          ? [
              {
                type: "FastTrackInquiry",
                id: "list",
              },
              ...result.data.map(({ id }) => ({
                type: "FastTrackInquiry" as const,
                id,
              })),
            ]
          : [
              {
                type: "FastTrackInquiry",
                id: "list",
              },
            ],
    }),
    sendFastTrackInquiryToAstra: builder.mutation<
      SendFastTrackInquiryToAstraResponseDto,
      SendFastTrackInquiryToAstraRequestDto
    >({
      query: ({ fastTrackInquiryId }) => ({
        url: `${baseUrl}/${fastTrackInquiryId}/send-to-astra`,
        method: "PATCH",
      }),
      invalidatesTags: (_result, _error, { fastTrackInquiryId }) => [
        {
          type: "FastTrackInquiry",
          id: "current",
        },
        {
          type: "FastTrackInquiry",
          id: fastTrackInquiryId,
        },
        {
          type: "FastTrackInquiry",
          id: "list",
        },
        {
          type: "CartAssignments",
          id: "list",
        },
      ],
    }),
    removeFastTrackInquiry: builder.mutation<
      RemoveFastTrackInquiryResponseDto,
      RemoveFastTrackInquiryRequestDto
    >({
      query: ({ fastTrackInquiryId }) => ({
        url: `${baseUrl}/${fastTrackInquiryId}`,
        method: "DELETE",
      }),
      invalidatesTags: (_result, _error, { fastTrackInquiryId }) => [
        {
          type: "FastTrackInquiry",
          id: "current",
        },
        {
          type: "FastTrackInquiry",
          id: fastTrackInquiryId,
        },
        {
          type: "RequestedItem",
          id: "list",
        },
      ],
    }),
    addItemToFastTrackInquiry: builder.mutation<
      AddItemToFastTrackInquiryResponseDto,
      AddItemToFastTrackInquiryRequestDto
    >({
      query: ({ fastTrackInquiryId, ...body }) => ({
        url: `${baseUrl}/${fastTrackInquiryId}/items`,
        method: "POST",
        body,
      }),
      invalidatesTags: (_result, _error, { fastTrackInquiryId }) => [
        {
          type: "FastTrackInquiry",
          id: "current",
        },
        {
          type: "FastTrackInquiry",
          id: fastTrackInquiryId,
        },
        {
          type: "RequestedItem",
          id: "list",
        },
      ],
    }),
    removeItemsFromFastTrackInquiry: builder.mutation<
      RemoveItemsFromFastTrackInquiryResponseDto,
      RemoveItemsFromFastTrackInquiryRequestDto
    >({
      query: ({ fastTrackInquiryId, requestedItemsIds }) => ({
        url: `${baseUrl}/${fastTrackInquiryId}/items`,
        method: "DELETE",
        body: {
          requestedItemsIds,
        },
      }),
      invalidatesTags: (_result, _error, { fastTrackInquiryId }) => [
        {
          type: "FastTrackInquiry",
          id: "current",
        },
        {
          type: "FastTrackInquiry",
          id: fastTrackInquiryId,
        },
        {
          type: "RequestedItem",
          id: "list",
        },
      ],
    }),
    changeQuantity: builder.mutation<
      ChangeQuantityResponseDto,
      ChangeQuantityRequestDto
    >({
      query: ({ requestedItemId, quantity }) => ({
        url: `${baseUrl}/${requestedItemId}/change-quantity`,
        method: "PATCH",
        body: {
          quantity,
        },
      }),
      invalidatesTags: (_result, _error, { fastTrackInquiryId }) => [
        {
          type: "FastTrackInquiry",
          id: "current",
        },
        {
          type: "FastTrackInquiry",
          id: fastTrackInquiryId,
        },
        {
          type: "RequestedItem",
          id: "list",
        },
      ],
    }),
  }),
});

export const {
  useGetCurrentFastTrackInquiryQuery,
  useGetFastTrackInquiryQuery,
  useGetFastTrackInquiriesQuery,
  useSendFastTrackInquiryToAstraMutation,
  useRemoveFastTrackInquiryMutation,
  useAddItemToFastTrackInquiryMutation,
  useRemoveItemsFromFastTrackInquiryMutation,
  useChangeQuantityMutation,
} = fastTrackInquiriesApi;
