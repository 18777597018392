import React, { useCallback, useState } from "react";
import {
  NumericField,
  NumericFieldProps,
} from "../../../../../components/fields/NumericField";
import { useTranslation } from "react-i18next";
import { useAddToCartMutation } from "../../../../../slices/shop/api";
import { useIsFastTrack, useShopList } from "../../../../../slices/shop/hooks";
import { useMessageHandlers } from "../../../../../utils/useMessageHandlers";
import { colors } from "../../../../../theme/astra/colors";
import { Button } from "../../../../../components/astraComponents/Button";

type AddProps = {
  id: number;
};

export const Add = ({ id }: AddProps) => {
  const { t } = useTranslation("pages");
  const { handleSuccess, handleError } = useMessageHandlers();

  const shopList = useShopList();
  const isFastTrack = useIsFastTrack();

  const [addToCart] = useAddToCartMutation();

  const [quantity, setQuantity] = useState<NumericFieldProps["value"]>(1);

  const addClickHandler = useCallback(() => {
    if (!shopList || !quantity) {
      return null;
    }

    addToCart({
      inquiryId: shopList.inquiryId,
      departmentInquiryId: shopList.departmentInquiryId,
      assignmentId: shopList.id,
      productId: id,
      quantity: typeof quantity === "string" ? parseInt(quantity) : quantity,
    })
      .unwrap()
      .then(handleSuccess)
      .catch(handleError);
  }, [addToCart, handleError, handleSuccess, id, quantity, shopList]);

  return (
    <>
      <NumericField
        name={`product-${id}-quantity`}
        value={quantity}
        onChange={setQuantity}
        positiveOnly
        sx={{
          flexBasis: "110px",
          flexGrow: 0,
          flexShrink: 0,
          position: "relative",

          ".MuiOutlinedInput-root": {
            paddingLeft: 0,
            paddingRight: 0,
          },

          ".MuiInputBase-input": {
            fontSize: "13px",
            lineHeight: "24px",
            fontWeight: "500",
            height: "38px",
            paddingTop: 0,
            paddingBottom: 0,
            textAlign: "center",
          },

          ".MuiOutlinedInput-notchedOutline": {
            borderColor: `${
              isFastTrack ? colors.pink : colors.blue
            } !important`,
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
          },

          ".MuiIconButton-root": {
            width: "30px",
            height: "30px",

            svg: {
              color: `${isFastTrack ? colors.pink : colors.blue} !important`,
            },

            "&.Mui-disabled svg": {
              opacity: 0.5,
            },
          },

          ".MuiInputAdornment-root": {
            position: "absolute",
          },

          ".MuiInputAdornment-positionStart": {
            left: "6px",
          },

          ".MuiInputAdornment-positionEnd": {
            right: "6px",
          },
        }}
      />
      <Button
        onClick={addClickHandler}
        variant="outlined"
        sx={{
          flexGrow: 1,
          marginLeft: "-1px",
          borderTopLeftRadius: "0 !important",
          borderBottomLeftRadius: "0 !important",
          fontSize: "13px",
          lineHeight: "24px",
          fontWeight: "500",
          textTransform: "none",
        }}
        fastTrack={isFastTrack}
      >
        + {t("add", { ns: "common" })}
      </Button>
    </>
  );
};
