import React from "react";
import { DepartmentInquirySchoolStatus } from "../../../../../types";
import { DEPARTMENT_INQUIRY_STATUS_CHIP_STYLES } from "../../../../../consts";
import { StatusChip } from "../../../../../components/StatusChip";
import { useDisplayDepartmentInquirySchoolStatus } from "../../../../../slices/schoolInquiries/hooks";
import { SchoolTitles } from "../../../../../slices/schoolInquiries/types";

type StatusProps = {
  schoolStatus: DepartmentInquirySchoolStatus;
  schoolTitles: Pick<SchoolTitles, "principal" | "headOfDepartment">;
};

export const Status = ({ schoolStatus, schoolTitles }: StatusProps) => {
  const displayDepartmentInquirySchoolStatus =
    useDisplayDepartmentInquirySchoolStatus();

  return (
    <StatusChip
      chipStyle={DEPARTMENT_INQUIRY_STATUS_CHIP_STYLES[schoolStatus]}
      label={displayDepartmentInquirySchoolStatus({
        schoolStatus,
        ...schoolTitles,
      })}
    />
  );
};
