import React from "react";
import { useSchoolInquiryItemsTools } from "../useSchoolInquiryItemsTools";
import { ItemDetails as ItemDetailsDrawer } from "../../../reusable-containers/ItemDetails";

export const ItemDetails = () => {
  const { inquiryId, itemId, closeDrawer } = useSchoolInquiryItemsTools();

  if (!itemId) {
    return null;
  }

  return (
    <ItemDetailsDrawer
      inquiryId={inquiryId}
      itemId={itemId}
      closeDrawer={closeDrawer}
    />
  );
};
