import React, { useCallback, useEffect } from "react";
import { useSchoolInquiriesTableUrlTools } from "../hooks";
import { Form as SchoolInquiryForm } from "./Form/Form";
import { useTranslation } from "react-i18next";
import { useFeedSuccess } from "../../../utils/feedHooks";
import { useYup } from "../../../utils/validation";
import { schoolInquirySchemaCreator } from "./Form/schema";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSubmitFormErrorHander } from "../../../utils/formHelpers";
import { skipToken } from "@reduxjs/toolkit/query/react";
import { Spinner } from "../../../components/wideDrawer/Spinner";
import { SchoolInquiryForm as SchoolInquiryFormProp } from "../../../slices/schoolInquiries/types";
import {
  useEditSchoolInquiryMutation,
  useGetSchoolInquiryQuery,
} from "../../../slices/schoolInquiries/api";
import { AstraForm } from "../../../components/astraWideDrawer/AstraForm";
import { useGetSchoolCurrency } from "../../../slices/currencies/hooks";

export const Edit = () => {
  const { t } = useTranslation("pages");
  const feedSuccess = useFeedSuccess();
  const { closeDrawer, id } = useSchoolInquiriesTableUrlTools();

  const schema = useYup(schoolInquirySchemaCreator);
  const { handleSubmit, reset, control } = useForm<SchoolInquiryFormProp>({
    resolver: yupResolver(schema),
  });
  const { catchFormError, formError, fieldsErrors } = useSubmitFormErrorHander(
    t("schoolInquiries.errors.add")
  );

  const { data: inquiry, isLoading: isInquiryLoading } =
    useGetSchoolInquiryQuery(id ?? skipToken);

  const { result: schoolCurrency } = useGetSchoolCurrency();

  useEffect(() => {
    if (inquiry) {
      reset({
        schoolId: inquiry.school.id,
        userId: inquiry.user.id,
        name: inquiry.name,
        isBudget: !!inquiry.budget,
        budget: inquiry.budget && inquiry.budget / 100,
        budgetCurrencyId: inquiry.budgetCurrency?.id ?? schoolCurrency?.id,
      });
    }
  }, [inquiry, reset, schoolCurrency?.id]);

  const [editInquiry, { isLoading: isEditing }] =
    useEditSchoolInquiryMutation();

  const onSubmit = useCallback(
    ({
      isBudget,
      budget,
      budgetCurrencyId,
      ...form
    }: SchoolInquiryFormProp) => {
      if (!id) {
        return null;
      }
      const formData = {
        isBudget,
        budget: isBudget && !!budget ? budget : null,
        budgetCurrencyId: isBudget && !!budget ? budgetCurrencyId : null,
        ...form,
      };

      editInquiry({ id, ...formData })
        .unwrap()
        .then(() => {
          feedSuccess(t("schoolInquiries.successes.edit"));
          closeDrawer();
        })
        .catch(catchFormError);
    },
    [catchFormError, closeDrawer, editInquiry, feedSuccess, id, t]
  );

  if (isInquiryLoading) {
    return <Spinner />;
  }

  return (
    <AstraForm
      heading={t("schoolInquiries.edit")}
      onSubmit={handleSubmit(onSubmit)}
      loading={isEditing}
      formError={formError}
      submitText="Save Changes"
      sx={{
        padding: 4,
      }}
    >
      <SchoolInquiryForm control={control} fieldsErrors={fieldsErrors} />
    </AstraForm>
  );
};
