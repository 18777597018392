import React from "react";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ActiveShoppingList } from "./ActiveShoppingList";
import { Title } from "./Title";
import { Block } from "./Block";
import { useIsCartDisabled, useShopList } from "../../../slices/shop/hooks";
import { ChangeList } from "./ChangeList/ChangeList";
import { useDisplayPrice } from "../../../utils/useDisplayPrice";
import { GoFastTrack } from "./GoFastTrack";
import { FastTrackTitle } from "./FastTrackTitle";

export const CurrentList = () => {
  const { t } = useTranslation("pages");
  const displayPrice = useDisplayPrice();

  const shopList = useShopList();
  const isCartDisabled = useIsCartDisabled();

  if (!shopList || isCartDisabled) {
    return null;
  }

  const na = t("na", { ns: "common" });

  return (
    <Box
      sx={{
        marginTop: "40px",
      }}
    >
      <ActiveShoppingList fastTrack={shopList.fastTrack} />
      <Box
        sx={{
          display: "flex",
          gap: "20px",
          marginTop: "20px",
        }}
      >
        {shopList.fastTrack ? (
          <FastTrackTitle>{shopList.inquiryName}</FastTrackTitle>
        ) : (
          <>
            <Title
              inquiry={shopList.inquiryName}
              department={shopList.departmentName}
            />
            <Block
              label={t("shopCatalog.currentList.budget")}
              value={`${shopList.budget ? displayPrice(shopList.budget) : na} ${
                shopList.budgetCurrency ?? na
              }`}
            />
          </>
        )}
        <ChangeList />
        <GoFastTrack />
      </Box>
    </Box>
  );
};
