export default {
  fastTrackInquiries: {
    addItem: {
      defaultErrorMessage:
        "An unexpected error occurred while adding the product",
    },
  },

  itemDetails: {
    fields: {
      productCode: "Product code",
      price: "Price",
      weight: "Weight",
      wdhCm: "WDH, cm",
      volume: "Volume",
      country: "Country",
      color: "Color",
      packSize: "Pack size",
    },
  },
};
