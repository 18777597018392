import React from "react";
import { Helmet } from "react-helmet-async";
import { getTitle } from "../../utils/getTitle";
import { useTranslation } from "react-i18next";
import { WideDrawer } from "../../components/wideDrawer/WideDrawer";
import { usePermissions } from "../../utils/usePermissions";
import { useTableUrlTools } from "../../utils/useTableUrlTools";
import { DataTable } from "./DataTable/DataTable";
import { useHeaderTitle } from "../../slices/ui/useHeaderTitle";
import { baseUrl } from "./baseUrl";
import { Details } from "./drawers/Details/Details";
import { useTableTools } from "../../utils/useContentTools";

export const NewSearchRequestItems = () => {
  const { t } = useTranslation("pages");
  const can = usePermissions();

  const { detailsDrawerOpen, closeDrawer } = useTableUrlTools(baseUrl);

  const params = useTableTools("newItemsFromSchoolInquiries", {
    defaultSortModel: [],
  });

  const title = t("newSearchRequestItems.title");

  useHeaderTitle(title);

  return (
    <>
      <Helmet>
        <title>{getTitle(title)}</title>
      </Helmet>
      {can("getItems") && (
        <>
          <DataTable params={params} />
          <WideDrawer open={detailsDrawerOpen} close={closeDrawer}>
            <Details
              queryParams={{
                page: params.page,
                pageSize: params.pageSize,
                sortModel: params.sortModel,
                search: params.debouncedSearch,
              }}
            />
          </WideDrawer>
        </>
      )}
    </>
  );
};
