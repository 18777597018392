import React, { useCallback } from "react";
import { IconButton } from "../../../../../components/astraComponents/IconButton";
import { Trash } from "../../../../../components/astraIcons/Trash";
import { useMessageHandlers } from "../../../../../utils/useMessageHandlers";
import { colors } from "../../../../../theme/astra/colors";
import { useRemoveItemsFromFastTrackInquiryMutation } from "../../../../../slices/fastTrackInquiries/api";

export const RemoveItem = ({
  fastTrackInquiryId,
  requestedItemId,
}: {
  fastTrackInquiryId: number;
  requestedItemId: number;
}) => {
  const { handleSuccess, handleError } = useMessageHandlers();

  const [removeItems, { isLoading: isRemoving }] =
    useRemoveItemsFromFastTrackInquiryMutation();

  const clickHandler = useCallback(() => {
    removeItems({
      fastTrackInquiryId,
      requestedItemsIds: [requestedItemId],
    })
      .unwrap()
      .then(handleSuccess)
      .catch(handleError);
  }, [
    fastTrackInquiryId,
    handleError,
    handleSuccess,
    removeItems,
    requestedItemId,
  ]);

  return (
    <IconButton
      variant="transparent"
      loading={isRemoving}
      onClick={clickHandler}
      sx={{
        color: colors.dustBlue1,
      }}
    >
      <Trash />
    </IconButton>
  );
};
