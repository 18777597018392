import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FastTrackInquiriesState } from "./types";
import { logOut } from "../auth/actions";

export const fastTrackInquiriesSlice = createSlice({
  name: "fastTrackInquiries",
  initialState: {
    isOpen: false,
  } as FastTrackInquiriesState,
  reducers: {
    setIsOpen: (state, action: PayloadAction<boolean>) => {
      state.isOpen = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logOut.pending, (state) => {
      state.isOpen = false;
    });
  },
});

export const {
  actions: { setIsOpen },
} = fastTrackInquiriesSlice;
