import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useFeedSuccess } from "../../../utils/feedHooks";
import { useYup } from "../../../utils/validation";
import { editDepartmentInquirySchemaCreator } from "./DepartmentForm/schema";
import { useForm } from "react-hook-form";
import { EditSchoolDepartmentInquiryForm } from "../../../slices/schoolInquiries/types";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSubmitFormErrorHander } from "../../../utils/formHelpers";
import {
  useDeleteSchoolDepartmentInquiryMutation,
  useEditSchoolDepartmentInquiryMutation,
  useGetSchoolDepartmentInquiryQuery,
} from "../../../slices/schoolInquiries/api";
import { Spinner } from "../../../components/wideDrawer/Spinner";
import { DepartmentForm } from "./DepartmentForm/DepartmentForm";
import { useGetCurrenciesQuery } from "../../../slices/currencies/api";
import { AstraForm } from "../../../components/astraWideDrawer/AstraForm";
import { useSchoolInquiriesTableUrlTools } from "../hooks";
import { useMessageHandlers } from "../../../utils/useMessageHandlers";
import { useHistory } from "react-router-dom";
import { Box, Button } from "@mui/material";
import styled from "@emotion/styled";
import { ConfirmationButton } from "../../../components/ConfirmationButton";
import { usePreActionCheck } from "../../../slices/schoolInquiries/hooks";

export const EditDepartment = () => {
  const { t } = useTranslation("pages");
  const history = useHistory();
  const feedSuccess = useFeedSuccess();
  const {
    baseUrl,
    closeDrawer,
    inquiryId,
    departmentInquiryId,
    inquiryResult: { data: inquiry, isLoading: isInquiryLoading },
  } = useSchoolInquiriesTableUrlTools();

  const schema = useYup(editDepartmentInquirySchemaCreator);
  const { handleSubmit, control, reset } =
    useForm<EditSchoolDepartmentInquiryForm>({
      resolver: yupResolver(schema),
    });
  const { catchFormError, formError, fieldsErrors } = useSubmitFormErrorHander(
    t("schoolInquiry.errors.addDepartment")
  );

  const { handleSuccess, handleError } = useMessageHandlers();

  const [deleteSchoolDepartmentInquiry, { isLoading: isDeleting }] =
    useDeleteSchoolDepartmentInquiryMutation();

  const deleteHandler = useCallback(() => {
    if (departmentInquiryId) {
      deleteSchoolDepartmentInquiry({ inquiryId, departmentInquiryId })
        .unwrap()
        .then(handleSuccess)
        .then(() => {
          history.push(baseUrl);
        })
        .catch(handleError);
    }
  }, [
    deleteSchoolDepartmentInquiry,
    departmentInquiryId,
    handleError,
    handleSuccess,
    history,
    inquiryId,
    baseUrl,
  ]);

  const departmentInquiry = useMemo(
    () =>
      inquiry?.departmentInquiries.find(({ id }) => id === departmentInquiryId),
    [departmentInquiryId, inquiry?.departmentInquiries]
  );

  const disabledDelete = useMemo(() => {
    if (!departmentInquiry) {
      return false;
    }

    return departmentInquiry.schoolStatus !== "new";
  }, [departmentInquiry]);

  const [editSchoolDepartmentInquiry, { isLoading }] =
    useEditSchoolDepartmentInquiryMutation();

  const onSubmit = useCallback(
    (form: EditSchoolDepartmentInquiryForm) => {
      if (departmentInquiryId) {
        editSchoolDepartmentInquiry({
          inquiryId,
          departmentInquiryId,
          ...form,
        })
          .unwrap()
          .then((res) => {
            feedSuccess(res.message);
            closeDrawer();
            reset();
          })
          .catch(catchFormError);
      }
    },
    [
      catchFormError,
      closeDrawer,
      departmentInquiryId,
      editSchoolDepartmentInquiry,
      feedSuccess,
      inquiryId,
      reset,
    ]
  );

  const { isLoading: areCurrenciesLoading, data: currencies } =
    useGetCurrenciesQuery();

  const { data: departmentInquiryServer } = useGetSchoolDepartmentInquiryQuery({
    inquiryId,
    departmentInquiryId,
  });

  const preActionCheck = usePreActionCheck();

  const disabledEdit = useMemo(() => {
    if (!inquiry || !departmentInquiry) {
      return true;
    }

    if (!inquiry.budget) {
      return true;
    }

    if (preActionCheck(inquiry.schoolStatus)) {
      return true;
    }

    return (
      departmentInquiry.schoolStatus !== "new" &&
      departmentInquiry.schoolStatus !== "inProgress" &&
      departmentInquiry.schoolStatus !== "pendingApproval" &&
      departmentInquiry.schoolStatus !== "readyForSending" &&
      departmentInquiry.schoolStatus !== "approvedByHead" &&
      departmentInquiry.schoolStatus !== "needReviewByHead"
    );
  }, [departmentInquiry, inquiry, preActionCheck]);

  useEffect(() => {
    if (currencies && departmentInquiry) {
      reset({
        budget:
          (departmentInquiryServer?.budget &&
            departmentInquiryServer?.budget / 100) ||
          undefined,
        budgetCurrencyId:
          departmentInquiryServer?.budgetCurrency?.id ??
          currencies.find(({ code }) => code === "QAR")?.id,
      });
    }
  }, [currencies, departmentInquiry, departmentInquiryServer, reset]);

  if (isInquiryLoading || areCurrenciesLoading) {
    return <Spinner />;
  }

  if (!inquiry) {
    return null;
  }

  return (
    <>
      <AstraForm
        heading={t("schoolInquiryDetails.editDepartment")}
        onSubmit={handleSubmit(onSubmit)}
        loading={isLoading}
        formError={formError}
        submitText={t("schoolInquiryDetails.submitEdit")}
        disabledSubmit={disabledEdit}
        sx={{
          padding: 4,
        }}
      >
        <DepartmentForm
          control={control}
          fieldsErrors={fieldsErrors}
          schoolId={inquiry.school.id}
          isBudget={!!inquiry?.budget || false}
          disabledEditing={disabledEdit}
          editing
        />
      </AstraForm>
      <Box
        sx={{
          width: "100%",
          padding: "0 20px 20px 20px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <DeleteButton
          component={Button}
          variant="text"
          onClick={deleteHandler}
          confirmationTitle={t(
            "schoolInquiryDetails.departmentControls.delete.confirmationTitle"
          )}
          confirmationDescription={t("noUndo", { ns: "common" })}
          loading={isDeleting}
          disabled={disabledDelete}
        >
          {t("schoolInquiryDetails.departmentControls.delete.buttonLabel")}
        </DeleteButton>
      </Box>
    </>
  );
};

const DeleteButton = styled(ConfirmationButton)({
  textDecoration: "underline",
  color: "#D20032",
  fontSize: "13px",
  "&:hover": {
    backgroundColor: "inherit",
  },
});
