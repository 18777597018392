import React from "react";
import { useTranslation } from "react-i18next";
import { ContainerBase } from "../../../components/layout/ContainerBase";
import { Back } from "../../../components/layout/Back";
import { Header } from "./Header/Header";
import { Items } from "./Items/Items";
import { useGetFastTrackInquiryQuery } from "../../../slices/fastTrackInquiries/api";

export const Content = ({ inquiryId }: { inquiryId: number }) => {
  const { t } = useTranslation("pages");

  const { data: fastTrackInquiry } = useGetFastTrackInquiryQuery({
    fastTrackInquiryId: inquiryId,
  });

  if (!fastTrackInquiry) {
    return null;
  }

  return (
    <ContainerBase
      sx={{
        maxWidth: "100%",
        paddingTop: "30px",
      }}
      maxWidth="xl"
    >
      <Back to="/schoolinquiries" sx={{ marginLeft: "-8px" }}>
        {t("fastTrackInquiries.backToInquiries")}
      </Back>
      <Header
        inquiryId={inquiryId}
        title={fastTrackInquiry.name}
        budgetSpent={fastTrackInquiry.budgetSpent}
        currency={fastTrackInquiry.currency}
        totalProducts={fastTrackInquiry.totalProducts}
        productsWithoutPrice={fastTrackInquiry.productsWithoutPrice}
        isAstra={fastTrackInquiry.isAstra}
      />
      <Items
        inquiryId={inquiryId}
        isAstra={fastTrackInquiry.isAstra}
        budgetSpent={fastTrackInquiry.budgetSpent}
        currency={fastTrackInquiry.currency}
        totalProducts={fastTrackInquiry.totalProducts}
        productsWithoutPrice={fastTrackInquiry.productsWithoutPrice}
        requestedItems={fastTrackInquiry.requestedItems}
      />
    </ContainerBase>
  );
};
