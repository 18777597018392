import {
  GetCartItemsResponse,
  GetShopListsResponseDto,
  GetShopProductResponseDto,
  GetShopProductsRequestDto,
  GetShopProductsResponseDto,
} from "./types";
import { api } from "../api";
import { allSchoolLevels } from "../products/types";

const baseUrl = "shop";

export const shopApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getShopLists: builder.query<GetShopListsResponseDto, void>({
      query: () => `${baseUrl}/lists`,
      providesTags: () => [{ type: "CartAssignments", id: "list" }],
    }),
    getShopProducts: builder.query<
      GetShopProductsResponseDto,
      GetShopProductsRequestDto
    >({
      query: ({
        page,
        pageSize,
        search,
        currentCategoryId,
        minPrice,
        maxPrice,
        tags,
        checkedSchoolLevel,
        fromWishlistOnly,
      }) => {
        const params: Record<string, string> = {
          page: JSON.stringify(page),
          pageSize: JSON.stringify(pageSize),
        };

        if (typeof minPrice === "number") {
          params.minPrice = JSON.stringify(minPrice);
        }

        if (typeof maxPrice === "number") {
          params.maxPrice = JSON.stringify(maxPrice);
        }

        const checkedSchoolLevelArray = allSchoolLevels.reduce<string[]>(
          (prev, curr) => {
            if (checkedSchoolLevel[curr]) {
              prev.push(curr);
            }
            return prev;
          },
          []
        );

        if (checkedSchoolLevelArray.length > 0) {
          params.checkedSchoolLevel = checkedSchoolLevelArray
            .reduce((prev, curr) => `${prev},_,${curr}`, "")
            .substring(3);
        }

        if (tags.length > 0) {
          params.tags = tags
            .map((tag) => encodeURIComponent(tag))
            .reduce((prev, curr) => `${prev},_,${curr}`, "")
            .substring(3);
        }

        if (currentCategoryId) {
          params.currentCategoryId = JSON.stringify(currentCategoryId);
        }

        if (search) {
          params.search = search;
        }

        if (fromWishlistOnly) {
          params.fromWishlistOnly = JSON.stringify(fromWishlistOnly);
        }

        const query = new URLSearchParams(params);
        return `${baseUrl}?${query.toString()}`;
      },
      providesTags: (result) =>
        result
          ? [
              { type: "Product", id: "list" },
              ...result.data.map(({ id }) => ({
                type: "Product" as const,
                id,
              })),
            ]
          : [{ type: "Product", id: "list" }],
    }),
    getShopProduct: builder.query<GetShopProductResponseDto, number>({
      query: (id) => `${baseUrl}/${id}`,
      providesTags: (result, error, id) => [{ type: "Product", id }],
    }),
    getCart: builder.query<GetCartItemsResponse, number>({
      query: (id) => `${baseUrl}/cart/${id}`,
      providesTags: [{ type: "RequestedItem", id: "list" }],
    }),
    addToCart: builder.mutation<
      { message: string },
      {
        inquiryId: number;
        departmentInquiryId: number;
        assignmentId: number;
        productId: number;
        quantity: number;
      }
    >({
      query: (body) => ({
        url: `${baseUrl}/add`,
        method: "POST",
        body,
      }),
      invalidatesTags: (
        _result,
        _error,
        { inquiryId, departmentInquiryId, assignmentId }
      ) => [
        { type: "RequestedItem", id: "list" },
        { type: "Inquiry", id: inquiryId },
        { type: "DetailedInquiry", id: departmentInquiryId },
        { type: "Assignment", id: assignmentId },
        { type: "FastTrackInquiry", id: "current" },
      ],
    }),
    addGeneralToCart: builder.mutation<
      { message: string },
      {
        inquiryId: number;
        departmentInquiryId: number;
        assignmentId: number;
        name: string;
        quantity: number;
        image?: string;
      }
    >({
      query: (body) => ({
        url: `${baseUrl}/generalAdd`,
        method: "POST",
        body,
      }),
      invalidatesTags: (
        _result,
        _error,
        { inquiryId, departmentInquiryId, assignmentId }
      ) => [
        { type: "RequestedItem", id: "list" },
        { type: "Inquiry", id: inquiryId },
        { type: "DetailedInquiry", id: departmentInquiryId },
        { type: "Assignment", id: assignmentId },
        { type: "FastTrackInquiry", id: "current" },
      ],
    }),
    removeFromCart: builder.mutation<
      { message: string },
      {
        inquiryId: number;
        departmentInquiryId: number;
        assignmentId: number;
        productId: number;
        quantity: number;
      }
    >({
      query: (body) => ({
        url: `${baseUrl}/remove`,
        method: "POST",
        body,
      }),
      invalidatesTags: (
        _result,
        _error,
        { inquiryId, departmentInquiryId, assignmentId }
      ) => [
        { type: "RequestedItem", id: "list" },
        { type: "Inquiry", id: inquiryId },
        { type: "DetailedInquiry", id: departmentInquiryId },
        { type: "Assignment", id: assignmentId },
        { type: "FastTrackInquiry", id: "current" },
      ],
    }),
    removeGeneralFromCart: builder.mutation<
      { message: string },
      {
        inquiryId: number;
        departmentInquiryId: number;
        assignmentId: number;
        name: string;
        quantity: number;
      }
    >({
      query: (body) => ({
        url: `${baseUrl}/generalRemove`,
        method: "POST",
        body,
      }),
      invalidatesTags: (
        _result,
        _error,
        { inquiryId, departmentInquiryId, assignmentId }
      ) => [
        { type: "RequestedItem", id: "list" },
        { type: "Inquiry", id: inquiryId },
        { type: "DetailedInquiry", id: departmentInquiryId },
        { type: "Assignment", id: assignmentId },
        { type: "FastTrackInquiry", id: "current" },
      ],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetShopListsQuery,
  useGetShopProductQuery,
  useGetShopProductsQuery,
  useGetCartQuery,
  useAddToCartMutation,
  useAddGeneralToCartMutation,
  useRemoveFromCartMutation,
  useRemoveGeneralFromCartMutation,
} = shopApi;
