import { useDispatch, useSelector } from "../../store";
import { selectShopList } from "../../slices/shop/selectors";
import { useGetShopListsQuery } from "../../slices/shop/api";
import { useEffect } from "react";
import { setShopList } from "../../slices/shop/actions";

export const useCurrentList = () => {
  const dispatch = useDispatch();

  const listId = useSelector(selectShopList);

  const { data: shopLists } = useGetShopListsQuery();

  useEffect(() => {
    if (!shopLists) {
      return;
    }

    const isListIdRelevant =
      typeof listId === "number" &&
      shopLists.some((list) => list.id === listId);

    if (!isListIdRelevant) {
      const defaultList = shopLists.find((list) => !list.fastTrack);
      dispatch(setShopList(defaultList ? defaultList.id : shopLists[0].id));
    }
  }, [dispatch, listId, shopLists]);
};
