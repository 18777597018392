import React from "react";
import { createSvgIcon } from "@mui/material";

export const AddToFavorites = createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.70312 7.84326C5.93354 7.50439 6.22 7.18938 6.5617 6.90973C8.37787 5.42336 10.7951 5.86 12.2446 7.48321C13.6941 5.86 16.0801 5.439 17.9275 6.90973C19.7748 8.38047 19.9993 10.8812 18.5731 12.6392L12.2446 18.5L8.91348 15.4151"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 10L8.5 12.25M8.5 12.25L6 14.5M8.5 12.25H3"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
  "AddToFavorites"
);
