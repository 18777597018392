import React from "react";
import { createSvgIcon } from "@mui/material";

export const ShoppingCart = createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.75 7.75H18.25L16.6128 14.7081C16.4002 15.6115 15.5941 16.25 14.666 16.25H10.5395C9.63201 16.25 8.83827 15.639 8.60606 14.7618L6.75 7.75Z"
      fill="#E7F1FF"
    />
    <path
      d="M6.75 7.75H18.25L16.6128 14.7081C16.4002 15.6115 15.5941 16.25 14.666 16.25H10.5395C9.63201 16.25 8.83827 15.639 8.60606 14.7618L6.75 7.75ZM6.75 7.75L6 4.75H3.75"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.5 19C9.5 19.2761 9.27614 19.5 9 19.5C8.72386 19.5 8.5 19.2761 8.5 19C8.5 18.7239 8.72386 18.5 9 18.5C9.27614 18.5 9.5 18.7239 9.5 19Z"
      fill="currentColor"
      stroke="currentColor"
    />
    <path
      d="M16.5 19C16.5 19.2761 16.2761 19.5 16 19.5C15.7239 19.5 15.5 19.2761 15.5 19C15.5 18.7239 15.7239 18.5 16 18.5C16.2761 18.5 16.5 18.7239 16.5 19Z"
      fill="currentColor"
      stroke="currentColor"
    />
  </svg>,
  "ShoppingCart"
);

export const ShoppingCartLight = createSvgIcon(
  <svg
    width="41"
    height="40"
    viewBox="0 0 41 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.3671 12.9166H30.7335L27.9764 24.5134C27.6184 26.0191 26.2608 27.0833 24.6979 27.0833H17.7487C16.2205 27.0833 14.8839 26.065 14.4928 24.6029L11.3671 12.9166Z"
      fill="white"
      fillOpacity="0.8"
    />
    <path
      d="M11.3671 12.9166H30.7335L27.9764 24.5134C27.6184 26.0191 26.2608 27.0833 24.6979 27.0833H17.7487C16.2205 27.0833 14.8839 26.065 14.4928 24.6029L11.3671 12.9166ZM11.3671 12.9166L10.1041 7.91663H6.31506"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.9982 31.6667C15.9982 32.1269 15.6213 32.5 15.1562 32.5C14.6912 32.5 14.3142 32.1269 14.3142 31.6667C14.3142 31.2065 14.6912 30.8334 15.1562 30.8334C15.6213 30.8334 15.9982 31.2065 15.9982 31.6667Z"
      fill="currentColor"
      stroke="currentColor"
    />
    <path
      d="M27.7864 31.6667C27.7864 32.1269 27.4095 32.5 26.9444 32.5C26.4794 32.5 26.1024 32.1269 26.1024 31.6667C26.1024 31.2065 26.4794 30.8334 26.9444 30.8334C27.4095 30.8334 27.7864 31.2065 27.7864 31.6667Z"
      fill="currentColor"
      stroke="currentColor"
    />
  </svg>,
  "ShoppingCartLight"
);
