import React, { useCallback } from "react";
import { Divider } from "@mui/material";
import { useHistory } from "react-router-dom";
import { colors } from "../../../theme/astra/colors";
import { ListProp } from "./types";
import { useTranslation } from "react-i18next";
import { Button } from "../../astraComponents/Button";
import { useDispatch } from "../../../store";
import { setShopList } from "../../../slices/shop/actions";
import { RouteState } from "../../../types";

type HeaderProps = {
  list: ListProp;
  fastTrack: boolean;
};

export const Header = ({ list, fastTrack }: HeaderProps) => {
  const { t } = useTranslation("components");
  const dispatch = useDispatch();
  const history = useHistory<RouteState>();

  const clickHandler = useCallback(() => {
    dispatch(setShopList(list));

    history.push({
      pathname: "/shop",
      state: {
        restoreParams: true,
        scrollToTop: true,
      },
    });
  }, [dispatch, history, list]);

  return (
    <>
      <Button
        fastTrack={fastTrack}
        variant="contained"
        fullWidth
        sx={{
          marginTop: "56px",
        }}
        onClick={clickHandler}
      >
        {t("addItem.addFromEshop")}
      </Button>
      <Divider
        sx={{
          marginTop: "40px",
          fontSize: "13px",
          lineHeight: "24px",
          fontWeight: "500",
          color: colors.dustBlue2,
        }}
      >
        {t("addItem.orAddManually")}
      </Divider>
    </>
  );
};
