import React from "react";
import { useItemsTableBaseUrl } from "../hooks";
import { useTableUrlTools } from "../../../utils/useTableUrlTools";
import { AssignMainOrder as AssignMainOrderDrawer } from "../../../reusable-containers/AssignMainOrder/AssignMainOrder";

export const AssignMainOrder = () => {
  const { baseUrl, inquiryId } = useItemsTableBaseUrl();
  const { closeDrawer } = useTableUrlTools(baseUrl);

  return (
    <AssignMainOrderDrawer inquiryId={inquiryId} closeDrawer={closeDrawer} />
  );
};
