import React from "react";
import { IconButton } from "../../../components/astraComponents/IconButton";
import { Search as SearchIcon } from "../../../components/astraIcons/Search";
import { useIsFastTrack } from "../../../slices/shop/hooks";
import { colors } from "../../../theme/astra/colors";

export const SearchButton = () => {
  const isFastTrackInquiry = useIsFastTrack();

  return (
    <IconButton
      variant="outlined"
      color="secondary"
      sx={{
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        marginLeft: "-1px",

        ...(isFastTrackInquiry
          ? {
              color: colors.pink,
            }
          : {}),
      }}
    >
      <SearchIcon />
    </IconButton>
  );
};
