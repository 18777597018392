import { api, coreApi } from "../api";
import {
  AddWishlistRecordRequestDto,
  AddWishlistRecordResponseDto,
  RemoveWishlistRecordRequestDto,
  RemoveWishlistRecordResponseDto,
} from "./types";

const baseUrl = "wishlist-records";

export const wishlistRecordsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    addWishlistRecord: builder.mutation<
      AddWishlistRecordResponseDto,
      AddWishlistRecordRequestDto
    >({
      query: ({ productsIds }) => ({
        url: `${baseUrl}`,
        method: "POST",
        body: {
          productsIds,
        },
      }),
      invalidatesTags: (
        _result,
        _error,
        { productsIds, params, fastTrackInquiryId }
      ) => {
        if (productsIds.length > 1) {
          return [
            {
              type: "Product",
              id: "list",
            },
            {
              type: "FastTrackInquiry",
              id: "current",
            },
          ];
        }

        return [
          ...(!params ? [{ type: "Product" as const, id: "list" }] : []),
          ...(!fastTrackInquiryId
            ? [{ type: "FastTrackInquiry" as const, id: "current" }]
            : []),
        ];
      },
      onQueryStarted: async (
        { productsIds, params, fastTrackInquiryId },
        { dispatch, queryFulfilled }
      ) => {
        try {
          await queryFulfilled;

          if (productsIds.length === 0 || productsIds.length > 1) {
            return;
          }

          const productId = productsIds[0];

          if (params) {
            dispatch(
              coreApi.util.updateQueryData(
                "getShopProducts",
                params,
                (draft) => ({
                  ...draft,
                  data: draft.data.map((product) => ({
                    ...product,
                    isInWishlist:
                      product.id === productId ? true : product.isInWishlist,
                  })),
                })
              )
            );
          }

          dispatch(
            coreApi.util.updateQueryData(
              "getShopProduct",
              productId,
              (draft) => ({
                ...draft,
                isInWishlist: true,
              })
            )
          );

          if (fastTrackInquiryId) {
            dispatch(
              coreApi.util.updateQueryData(
                "getFastTrackInquiry",
                {
                  fastTrackInquiryId,
                },
                (draft) => ({
                  ...draft,
                  requestedItems: draft.requestedItems.map((requestedItem) =>
                    requestedItem.productId === productId
                      ? {
                          ...requestedItem,
                          isInWishlist: true,
                        }
                      : requestedItem
                  ),
                })
              )
            );
          }
        } catch {
          /* empty */
        }
      },
    }),
    removeWishlistRecord: builder.mutation<
      RemoveWishlistRecordResponseDto,
      RemoveWishlistRecordRequestDto
    >({
      query: ({ productsIds }) => ({
        url: `${baseUrl}`,
        method: "DELETE",
        body: {
          productsIds,
        },
      }),
      invalidatesTags: (
        _result,
        _error,
        { productsIds, params, fastTrackInquiryId }
      ) => {
        if (productsIds.length > 1) {
          return [
            {
              type: "Product",
              id: "list",
            },
            {
              type: "FastTrackInquiry",
              id: "current",
            },
          ];
        }

        return [
          ...(!params ? [{ type: "Product" as const, id: "list" }] : []),
          ...(!fastTrackInquiryId
            ? [{ type: "FastTrackInquiry" as const, id: "current" }]
            : []),
        ];
      },
      onQueryStarted: async (
        { productsIds, params, fastTrackInquiryId },
        { dispatch, queryFulfilled }
      ) => {
        try {
          await queryFulfilled;

          if (productsIds.length === 0 || productsIds.length > 1) {
            return;
          }

          const productId = productsIds[0];

          if (params) {
            dispatch(
              coreApi.util.updateQueryData(
                "getShopProducts",
                params,
                (draft) => ({
                  ...draft,
                  data: draft.data.map((product) => ({
                    ...product,
                    isInWishlist:
                      product.id === productId ? false : product.isInWishlist,
                  })),
                })
              )
            );
          }

          dispatch(
            coreApi.util.updateQueryData(
              "getShopProduct",
              productId,
              (draft) => ({
                ...draft,
                isInWishlist: false,
              })
            )
          );

          if (fastTrackInquiryId) {
            dispatch(
              coreApi.util.updateQueryData(
                "getFastTrackInquiry",
                {
                  fastTrackInquiryId,
                },
                (draft) => ({
                  ...draft,
                  requestedItems: draft.requestedItems.map((requestedItem) =>
                    requestedItem.productId === productId
                      ? {
                          ...requestedItem,
                          isInWishlist: false,
                        }
                      : requestedItem
                  ),
                })
              )
            );
          }
        } catch {
          /* empty */
        }
      },
    }),
  }),
});

export const { useAddWishlistRecordMutation, useRemoveWishlistRecordMutation } =
  wishlistRecordsApi;
