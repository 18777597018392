import React, { useCallback } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";

import { useDispatch } from "../../../store";

import { useChangePasswordMutation } from "../../../slices/users/api";
import { ChangePasswordRequestDto } from "../../../slices/users/types";
import { logOut } from "../../../slices/auth/actions";

import { useSubmitFormErrorHander } from "../../../utils/formHelpers";
import { useFeedSuccess } from "../../../utils/feedHooks";

import {
  changeSelfFormSchemaCreator,
  ChangeSelfPasswordForm,
  ChangeSelfPasswordFormType,
} from "./ChangeSelfPasswordForm";
import { useYup } from "../../../utils/validation";
import { AstraForm } from "../../astraWideDrawer/AstraForm";

type ChangeSelfPasswordProps = {
  closeDrawer: () => void;
  selfId?: number;
};

export const ChangeSelfPassword: React.FC<ChangeSelfPasswordProps> = ({
  selfId,
  closeDrawer,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const feedSuccess = useFeedSuccess();
  const schema = useYup(changeSelfFormSchemaCreator);
  const formMethods = useForm<ChangePasswordRequestDto>({
    resolver: yupResolver(schema),
  });
  const { handleSubmit } = formMethods;

  const [changePassword, { isLoading: isEditLoading }] =
    useChangePasswordMutation();
  const { catchFormError, formError, fieldsErrors } = useSubmitFormErrorHander(
    t("users.errors.edit")
  );

  const onSubmit = useCallback(
    (form: ChangeSelfPasswordFormType) => {
      changePassword(form)
        .unwrap()
        .then(() => {
          feedSuccess(t("passwordChanged"));
          closeDrawer();
          dispatch(logOut());
        })
        .catch(catchFormError);
    },
    [catchFormError, closeDrawer, dispatch, changePassword, feedSuccess, t]
  );

  if (!selfId) {
    return null;
  }

  return (
    <AstraForm
      heading={t("changeSelfPassword")}
      onSubmit={handleSubmit(onSubmit)}
      loading={isEditLoading}
      formError={formError}
      sx={{
        padding: 4,
      }}
    >
      <FormProvider {...formMethods}>
        <ChangeSelfPasswordForm selfId={selfId} fieldsErrors={fieldsErrors} />
      </FormProvider>
    </AstraForm>
  );
};
