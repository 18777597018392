import React from "react";
import { useFastTrackItemsColumns } from "./useFastTrackItemsColumns";
import { List } from "../../../../components/List/List";
import { TableControls } from "./TableControls/TableControls";
import { useListTools } from "../../../../components/List/useListTools";
import { GetFastTrackInquiryResponseDto } from "../../../../slices/fastTrackInquiries/types";
import { BottomRow } from "./BottomRow";

export const Items = ({
  inquiryId,
  isAstra,
  budgetSpent,
  currency,
  totalProducts,
  productsWithoutPrice,
  requestedItems,
}: {
  inquiryId: number;
  isAstra: boolean;
  budgetSpent: number;
  currency: string;
  totalProducts: number;
  productsWithoutPrice: number;
  requestedItems: GetFastTrackInquiryResponseDto["requestedItems"];
}) => {
  const { selectedIds, selectedIdsChangeHandler } = useListTools();

  const columns = useFastTrackItemsColumns({
    inquiryId,
    isAstra,
  });

  return (
    <>
      <TableControls
        inquiryId={inquiryId}
        selectedIds={selectedIds}
        isAstra={isAstra}
      />
      <List
        selectedIds={selectedIds}
        onSelectedIdsChange={selectedIdsChangeHandler}
        columns={columns}
        rows={requestedItems}
        bottomRow={
          <BottomRow
            budgetSpent={budgetSpent}
            currency={currency}
            totalProducts={totalProducts}
            productsWithoutPrice={productsWithoutPrice}
          />
        }
      />
    </>
  );
};
