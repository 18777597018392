import React from "react";
import { Box, Drawer, DrawerProps, IconButton } from "@mui/material";
import { ChevronLeft } from "@mui/icons-material";
import { colors } from "../../theme/astra/colors";

type AstraWideDrawerProps = Omit<DrawerProps, "right" | "onClose"> & {
  close: () => void;
};

export const AstraWideDrawer = ({
  children,
  close,
  ...props
}: AstraWideDrawerProps) => {
  return (
    <Drawer
      anchor="right"
      sx={{
        width: "465px",

        "& .MuiDrawer-paper": {
          width: "465px",
          backgroundColor: colors.white,
        },
      }}
      onClose={close}
      {...props}
    >
      <Box
        sx={{
          margin: "10px",
          borderRadius: "4px",
          backgroundColor: colors.white,
        }}
      >
        <IconButton
          onClick={close}
          sx={{
            top: "15px",
            left: "15px",
          }}
        >
          <ChevronLeft />
        </IconButton>
        {children}
      </Box>
    </Drawer>
  );
};
