import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Control, UseFormSetValue, useWatch } from "react-hook-form";
import { AddItemForm } from "./types";
import { FieldsErrorState } from "../../../utils/formHelpers";
import { Box } from "@mui/material";
import { StyledFieldTitle } from "../../../containers/SignIn/StyledComponents";
import { ControlledCustomField } from "../../controlledFields/ControlledCustomField";
import { NewDesignSelectSupplier } from "../../prefilledSelectors/NewDesignSelectSupplier";
import { NewDesignFreeAutocompleteSupplierCode } from "../../prefilledSelectors/NewDesignFreeAutocompleteSupplierCode";
import { NewDesignFreeAutocompleteItemName } from "../../prefilledSelectors/NewDesignFreeAutocompleteItemName";
import { NewDesignControlledNumericField } from "../../controlledFields/NewDesignControlledNumericField";
import { colors } from "../../../theme/astra/colors";

type FieldsProps = {
  fieldsErrors: FieldsErrorState;
  control: Control<AddItemForm>;
  setValue: UseFormSetValue<AddItemForm>; // This is clearly wrong, I inherited it from another piece of code
  fastTrack: boolean;
};

export const Fields = ({
  fieldsErrors,
  control,
  setValue,
  fastTrack,
}: FieldsProps) => {
  const { t } = useTranslation("components");

  const selectedSupplierId = useWatch({
    control,
    name: "supplierId",
  });

  const setName = useCallback(
    (name: string) => {
      setValue("name", name);
    },
    [setValue]
  );

  const setSupplier = useCallback(
    (id: number) => {
      setValue("supplierId", id);
    },
    [setValue]
  );

  const setSupplierProductCode = useCallback(
    (spc: string) => {
      setValue("supplierProductCode", spc);
    },
    [setValue]
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        marginTop: "24px",
      }}
    >
      <Box>
        <StyledFieldTitle sx={{ marginTop: "0px !important" }}>
          {`${t("addItem.fields.supplier")} *`}
        </StyledFieldTitle>
        <ControlledCustomField
          control={control}
          name="supplierId"
          fieldsErrors={fieldsErrors}
          render={(errorMessage, { field }) => (
            <NewDesignSelectSupplier
              id="item"
              label={`${t("addItem.fields.supplier")} *`}
              error={!!errorMessage}
              helperText={errorMessage}
              fullWidth
              publicOnly
              selectProps={field}
              size="small"
              value={field.value}
              sx={fastTrack ? fastTrackSx : {}}
            />
          )}
        />
      </Box>
      <Box>
        <StyledFieldTitle sx={{ marginTop: "0px !important" }}>
          {`${t("addItem.fields.code")} *`}
        </StyledFieldTitle>
        <ControlledCustomField
          control={control}
          name="supplierProductCode"
          fieldsErrors={fieldsErrors}
          render={(errorMessage, { field: { value, onChange } }) => {
            return (
              <NewDesignFreeAutocompleteSupplierCode
                id="item"
                onChange={onChange}
                setName={setName}
                value={(value as string) ?? ""}
                supplierId={selectedSupplierId}
                error={!!errorMessage}
                helperText={errorMessage}
                label={`${t("addItem.fields.code")} *`}
                sx={fastTrack ? fastTrackSx : {}}
              />
            );
          }}
        />
      </Box>
      <Box>
        <StyledFieldTitle sx={{ marginTop: "0px !important" }}>
          {`${t("addItem.fields.name")} *`}
        </StyledFieldTitle>
        <ControlledCustomField
          control={control}
          name="name"
          fieldsErrors={fieldsErrors}
          render={(errorMessage, { field: { value, onChange } }) => {
            return (
              <NewDesignFreeAutocompleteItemName
                id="item"
                onChange={onChange}
                setSupplier={setSupplier}
                setSupplierProductCode={setSupplierProductCode}
                value={(value as string) ?? ""}
                error={!!errorMessage}
                helperText={errorMessage}
                label={`${t("addItem.fields.name")} *`}
                sx={fastTrack ? fastTrackSx : {}}
              />
            );
          }}
        />
      </Box>
      <Box>
        <StyledFieldTitle sx={{ marginTop: "0px !important" }}>
          {`${t("addItem.fields.quantity")} *`}
        </StyledFieldTitle>
        <NewDesignControlledNumericField
          fullWidth
          control={control}
          name="quantity"
          label={t("addItem.fields.quantity")}
          fieldsErrors={fieldsErrors}
          defaultValue={1}
          positiveOnly
          sx={fastTrack ? fastTrackSx : {}}
        />
      </Box>
    </Box>
  );
};

const fastTrackSx = {
  "&:hover, .Mui-focused": {
    ".MuiOutlinedInput-notchedOutline": {
      borderColor: `${colors.pink} !important`,
    },

    ".MuiSvgIcon-root": {
      color: `${colors.pink} !important`,
    },
  },
};
