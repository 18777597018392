import React, { memo } from "react";
import { Badge, Box, styled } from "@mui/material";
import { Add } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { colors } from "../../../theme/astra/colors";
import { SchoolInquiriesFilter } from "../../../slices/schoolInquiries/types";
import { EnhancedSkeleton } from "../../../components/EnhancedSkeleton";
import { Button } from "../../../components/astraComponents/Button";
import { FastTrackInquiryButton } from "./FastTrackInquiryButton";

export type SchoolInquiriesToolbarProps = {
  addButtonDisabled?: boolean;
  onAddClick?: () => void;
  additionalButtonText?: string;
  additionalButtonDisabled?: boolean;
  additionalButtonDownloadInProcess?: boolean;
  onAdditionalClick?: () => void;
  hideAddButtonPlusSymbol?: boolean;
  filters?: React.ReactNode;
  buttons?: React.ReactNode;
  isLoading?: boolean;
  filter: SchoolInquiriesFilter;
  onFilterChange: (value: SchoolInquiriesFilter) => void;
  filterCount?: {
    all: number;
    active: number;
    cancelled: number;
    completed: number;
    fastTrack: number;
  };
};

export const SchoolInquiriesToolbar = memo<SchoolInquiriesToolbarProps>(
  ({
    addButtonDisabled,
    onAddClick,
    hideAddButtonPlusSymbol,
    isLoading,
    filter,
    onFilterChange,
    filterCount,
  }) => {
    const { t } = useTranslation("pages");

    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "32px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "12px",
          }}
        >
          {isLoading ? (
            <>
              {Array.from(new Array(4)).map((_value, index) => (
                <EnhancedSkeleton
                  key={index}
                  variant="rectangular"
                  width={[90, 150, "px"]}
                  height={38}
                />
              ))}
            </>
          ) : (
            <>
              <FilterBadge
                badgeContent={filterCount?.active || "0"}
                sx={{
                  color:
                    filter === "active" ? colors.darkBlue : colors.dustBlue2,
                }}
              >
                <Button
                  variant="text"
                  sx={{
                    color:
                      filter === "active" ? colors.darkBlue : colors.dustBlue2,
                  }}
                  onClick={() => onFilterChange("active")}
                >
                  {t("schoolInquiries.inquiryFilters.active")}
                </Button>
              </FilterBadge>
              <FilterBadge
                badgeContent={filterCount?.completed || "0"}
                sx={{
                  color:
                    filter === "completed" ? colors.darkBlue : colors.dustBlue2,
                }}
              >
                <Button
                  variant="text"
                  sx={{
                    color:
                      filter === "completed"
                        ? colors.darkBlue
                        : colors.dustBlue2,
                  }}
                  onClick={() => onFilterChange("completed")}
                >
                  {t("schoolInquiries.inquiryFilters.completed")}
                </Button>
              </FilterBadge>
              <FilterBadge
                badgeContent={filterCount?.cancelled || "0"}
                sx={{
                  color:
                    filter === "cancelled" ? colors.darkBlue : colors.dustBlue2,
                }}
              >
                <Button
                  variant="text"
                  sx={{
                    color:
                      filter === "cancelled"
                        ? colors.darkBlue
                        : colors.dustBlue2,
                  }}
                  onClick={() => onFilterChange("cancelled")}
                >
                  {t("schoolInquiries.inquiryFilters.cancelled", {
                    ns: "pages",
                  })}
                </Button>
              </FilterBadge>
              <FilterBadge
                badgeContent={filterCount?.all || "0"}
                sx={{
                  color: filter === "all" ? colors.darkBlue : colors.dustBlue2,
                }}
              >
                <Button
                  variant="text"
                  sx={{
                    color:
                      filter === "all" ? colors.darkBlue : colors.dustBlue2,
                  }}
                  onClick={() => onFilterChange("all")}
                >
                  {t("schoolInquiries.inquiryFilters.all")}
                </Button>
              </FilterBadge>
              <FilterBadge
                badgeContent={filterCount?.fastTrack || "0"}
                sx={{
                  color:
                    filter === "fastTrack" ? colors.darkBlue : colors.dustBlue2,
                }}
              >
                <Button
                  variant="text"
                  sx={{
                    color:
                      filter === "fastTrack"
                        ? colors.darkBlue
                        : colors.dustBlue2,
                  }}
                  onClick={() => onFilterChange("fastTrack")}
                >
                  {t("schoolInquiries.inquiryFilters.fastTrackHistory")}
                </Button>
              </FilterBadge>
            </>
          )}
        </Box>
        <Box
          sx={{
            marginLeft: "auto",
            display: "flex",
            gap: "24px",
          }}
        >
          {onAddClick && (
            <Button
              startIcon={hideAddButtonPlusSymbol ? null : <Add />}
              variant="outlined"
              onClick={onAddClick}
              disabled={addButtonDisabled}
            >
              {t("schoolInquiries.newInquiry")}
            </Button>
          )}
          <FastTrackInquiryButton />
        </Box>
      </Box>
    );
  }
);

const FilterBadge = styled(Badge)({
  "& .MuiBadge-badge": {
    marginTop: "8px",
    marginRight: "20px",
  },
});
