import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDisplayPrice } from "./useDisplayPrice";
import { useSelector } from "../store";
import { selectSelfSchoolCurrency } from "../slices/auth/selectors";

export const useDisplayTotalAmount = () => {
  const { t } = useTranslation("pages");

  const displayPrice = useDisplayPrice();

  const selfCurrency = useSelector(selectSelfSchoolCurrency);

  const na = t("na", { ns: "common" });
  const priceUponRequest = t("priceUponRequest", { ns: "common" });

  return useCallback(
    ({
      productCode,
      retailPrice,
      currency,
      totalAmount,
    }: {
      productCode: string | null;
      retailPrice: number | null | undefined;
      currency: string | null | undefined;
      totalAmount: number;
    }) => {
      const isPricePresent = retailPrice && currency;

      if (productCode && !isPricePresent) {
        return priceUponRequest;
      }

      const totalAmountFormatted = totalAmount ? displayPrice(totalAmount) : na;

      if (!productCode) {
        return totalAmountFormatted;
      }

      return `${totalAmountFormatted} ${selfCurrency?.code ?? na}`;
    },
    [displayPrice, na, priceUponRequest, selfCurrency?.code]
  );
};
