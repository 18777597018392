import React from "react";
import { colors } from "../../../../theme/astra/colors";
import { alpha, Box, ButtonBase, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { StatusChip } from "../../../../components/StatusChip";
import { Link } from "react-router-dom";

type ContainerProps = {
  inquiryId: number;
  code: string | number;
  headerElements?: React.ReactNode;
  children: React.ReactNode;
};

export const Container = ({
  inquiryId,
  code,
  headerElements,
  children,
}: ContainerProps) => {
  const { t } = useTranslation("pages");

  return (
    <Box
      sx={{
        border: `1px solid ${colors.dustBlue5}`,
        borderRadius: "4px",
        background: colors.whitishGray,
        marginBottom: "32px",
      }}
    >
      <Box
        sx={{
          borderBottom: `1px solid ${colors.dustBlue5}`,
          padding: "24px 24px 24px 40px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <ButtonBase component={Link} to={`/fast-track-inquiries/${inquiryId}`}>
          <Typography
            component="span"
            sx={{
              fontSize: "22px",
              fontWeight: "500",
              lineHeight: "29px",
              letterSpacing: "0.4%",
              color: colors.darkBlue,
            }}
          >
            {t("fastTrackInquiries.title")} #{code}
          </Typography>
        </ButtonBase>
        <StatusChip
          label={t("fastTrackInquiries.sentToAstra")}
          chipStyle={{
            backgroundColor: alpha(colors.purple, 0.1),
            color: colors.purple,
          }}
          sx={{
            marginLeft: "40px",
          }}
        />
        {headerElements}
      </Box>
      <Box
        sx={{
          padding: "40px",
          display: "flex",
          gap: "36px",
          overflow: "scroll",
        }}
      >
        {children}
      </Box>
    </Box>
  );
};
