import React from "react";
import { alpha, Button as MuiButton, ButtonProps } from "@mui/material";
import { ButtonTypeMap } from "@mui/material/Button/Button";
import { colors } from "../../theme/astra/colors";

export const Button = <
  RootComponent extends React.ElementType = ButtonTypeMap["defaultComponent"],
  // eslint-disable-next-line @typescript-eslint/ban-types
  AdditionalProps = {}
>({
  children,
  fastTrack,
  ...props
}: ButtonProps<RootComponent, AdditionalProps> & {
  fastTrack?: boolean;
}) => {
  let localSx: ButtonProps["sx"] = {};

  switch (props.variant) {
    case "outlined":
      localSx = {
        ...localSx,
        borderRadius: "4px",
        border: `1px solid ${colors.dustBlue4}`,
        color: colors.dustBlue1,
        backgroundColor: colors.white,

        "&:hover": {
          borderColor: colors.dustBlue1,
          backgroundColor: colors.dustBlue6,
        },
      };
  }

  if (fastTrack) {
    switch (props.variant) {
      case "outlined":
        localSx = {
          ...localSx,
          backgroundColor: alpha(colors.pink, 0.05),
          color: colors.pink,
          border: `1px solid ${colors.pink}`,

          "&:hover": {
            backgroundColor: alpha(colors.pink, 0.1),
            border: `1px solid ${colors.pink}`,
          },
        };
        break;
      case "contained":
        localSx = {
          ...localSx,
          backgroundColor: colors.pink,

          "&:hover": {
            backgroundColor: alpha(colors.pink, 0.75),
          },
        };
        break;
      case "text":
        localSx = {
          ...localSx,
          color: colors.pink,
          paddingTop: "2px",

          ".MuiButton-startIcon svg": {
            color: `${colors.pink}`,
          },
        };
        break;
    }
  }

  return (
    <MuiButton
      {...props}
      sx={{
        fontSize: "13px",
        lineHeight: "24px",
        paddingTop: 0,

        ...(props.startIcon !== undefined
          ? {
              paddingLeft: "16px",
            }
          : {}),

        ".MuiButton-startIcon .MuiSvgIcon-fontSizeMedium": {
          width: 24,
          height: 24,
        },

        ...props.sx,
        ...localSx,
      }}
    >
      {children}
    </MuiButton>
  );
};
