import React, { useCallback } from "react";
import { AstraConfirmationDialog } from "./AstraConfirmationDialog";

type ConfirmationProp = {
  title: string;
  description: string | React.ReactNode;
  proceed?: string;
  cancel?: string;
};

type CustomConfirmationProp = (props: {
  open: boolean;
  onCancel: () => void;
  onProceed: () => void;
  loading?: boolean;
}) => React.ReactNode;

export const AstraConfirmationButton = <Component extends React.ElementType>({
  component,
  loading,
  confirmation,
  onProceed,
  ...props
}: Omit<React.ComponentPropsWithRef<Component>, "component"> & {
  component: Component;
} & {
  confirmation: ConfirmationProp | CustomConfirmationProp;
  loading?: boolean;
  onProceed: () => void;
}) => {
  const [isConfirmationOpen, setIsConfirmationOpen] = React.useState(false);

  const handleClick = useCallback(() => {
    setIsConfirmationOpen(true);
  }, []);

  const handleCancel = useCallback(() => {
    setIsConfirmationOpen(false);
  }, []);

  const handleProceed = useCallback(() => {
    setIsConfirmationOpen(false);
    onProceed();
  }, [onProceed]);

  return (
    <>
      {React.createElement(component, { ...props, onClick: handleClick })}
      {typeof confirmation === "function" ? (
        confirmation({
          open: isConfirmationOpen,
          onCancel: handleCancel,
          onProceed: handleProceed,
          loading,
        })
      ) : (
        <AstraConfirmationDialog
          open={isConfirmationOpen}
          title={confirmation.title}
          {...(typeof confirmation.description === "string"
            ? {
                bodyType: "description",
                description: confirmation.description,
              }
            : {
                bodyType: "custom",
                body: confirmation.description,
              })}
          buttons={<></>}
        />
      )}
    </>
  );
};
