import React, { useMemo } from "react";
import { Tab, Tabs, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { usePermissions } from "../../utils/usePermissions";
import { Link, useRouteMatch } from "react-router-dom";
import { useIsSchoolTeam } from "../../utils/useIsSchoolTeam";

export const Menu = () => {
  const theme = useTheme();
  const { t } = useTranslation("components");
  const can = usePermissions();
  const isSchoolTeam = useIsSchoolTeam();

  const path = useRouteMatch<{ path: string }>("/:path")?.params?.path;

  const tabs = useMemo(
    () => [
      {
        label: t(
          isSchoolTeam
            ? "schoolInquiries.schoolTeamTitle"
            : "schoolInquiries.title",
          { ns: "pages" }
        ),
        to: "/schoolinquiries",
        base: "schoolinquiries",
        visible: true,
      },
      {
        label: t("shopCatalog.title", { ns: "pages" }),
        to: {
          pathname: "/shop",
          state: {
            restoreParams: true,
            scrollToTop: true,
          },
        },
        base: "shop",
        visible: true,
      },
      {
        label: t("users.title", { ns: "pages" }),
        to: "/users",
        base: "users",
        visible: can("viewUsers") || can("viewUsersMenu"),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [can, isSchoolTeam, path, t] //TODO check that place
  );

  return (
    <Tabs
      sx={{
        marginRight: "52px",
        alignSelf: "stretch",
        overflow: "visible !important",

        "& .MuiTabs-flexContainer": {
          alignItems: "stretch",
          gap: "52px",
          height: "100%",
        },

        "& .MuiTabs-scroller": {
          overflow: "visible !important",
        },

        "& .MuiTab-root": {
          fontSize: "14px",
          lineHeight: "135%",
          fontWeight: "400",
          textTransform: "none",
          color: `${theme.palette.primary.dark} !important`,
          opacity: 0.5,
          padding: 0,
          minWidth: 0,
          transitionDuration: `${theme.transitions.duration.leavingScreen}ms`,

          "&:hover": {
            transitionDuration: `${theme.transitions.duration.enteringScreen}ms`,
            opacity: 1,
          },
        },

        "& .Mui-selected": {
          opacity: 1,
        },

        "& .MuiTabs-indicator": {
          bottom: "-1px",
          height: "2px",
          zIndex: 10,
          backgroundColor: "pink.main",
        },
      }}
      value={path}
    >
      {tabs.map(
        ({ visible, ...props }) =>
          visible && (
            <Tab
              key={typeof props.to === "string" ? props.to : props.to.pathname}
              value={props.base}
              component={Link}
              {...props}
            />
          )
      )}
    </Tabs>
  );
};
