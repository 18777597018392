import React from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { getTitle } from "../../utils/getTitle";
import { usePermissions } from "../../utils/usePermissions";
import { Content } from "./Content/Content";
import { WideDrawer } from "../../components/wideDrawer/WideDrawer";
import { Add } from "./Drawers/Add";
import { Edit } from "./Drawers/Edit";
import { useTableUrlTools } from "../../utils/useTableUrlTools";
import { Details } from "./Drawers/Details/Details";
import { useHeaderTitle } from "../../slices/ui/useHeaderTitle";

export const Products = () => {
  const { t } = useTranslation("pages");
  const can = usePermissions();
  const { addDrawerOpen, editDrawerOpen, detailsDrawerOpen, closeDrawer } =
    useTableUrlTools("/products");

  const title = t("products.title");

  useHeaderTitle(title);

  return (
    <>
      <Helmet>
        <title>{getTitle(title)}</title>
      </Helmet>
      {can("getProducts") && <Content />}
      {can("addProduct") && (
        <WideDrawer open={addDrawerOpen} close={closeDrawer}>
          <Add />
        </WideDrawer>
      )}
      {can("editProduct") && (
        <WideDrawer open={editDrawerOpen} close={closeDrawer}>
          <Edit />
        </WideDrawer>
      )}
      {can("getProduct") && (
        <WideDrawer open={detailsDrawerOpen} close={closeDrawer}>
          <Details />
        </WideDrawer>
      )}
    </>
  );
};
