import React, { useEffect } from "react";
import { Container } from "./Container";
import { ItemsPreviews } from "../ItemsPreviews";
import { SendToAstra } from "./controls/SendToAstra";
import { Context } from "./controls/Context";
import { useGetCurrentFastTrackInquiryQuery } from "../../../../slices/fastTrackInquiries/api";
import { useDispatch, useSelector } from "../../../../store";
import { selectIsFastTrackInquiryOpen } from "../../../../slices/fastTrackInquiries/selectors";
import { setIsOpen } from "../../../../slices/fastTrackInquiries/slice";
import { AddItemButton } from "./AddItemButton";

export const OpenFastTrack = () => {
  const dispatch = useDispatch();

  const isOpen = useSelector(selectIsFastTrackInquiryOpen);

  const { data: currentFastTrackInquiry } =
    useGetCurrentFastTrackInquiryQuery();

  useEffect(() => {
    if (
      !isOpen &&
      currentFastTrackInquiry &&
      currentFastTrackInquiry.requestedItems.length > 0
    ) {
      dispatch(setIsOpen(true));
    }
  }, [currentFastTrackInquiry, dispatch, isOpen]);

  if (!currentFastTrackInquiry || !isOpen) {
    return null;
  }

  return (
    <Container
      inquiryId={currentFastTrackInquiry.id}
      code={currentFastTrackInquiry.fastTrackIndex}
      controls={
        <>
          <SendToAstra
            inquiryId={currentFastTrackInquiry.id}
            totalProducts={currentFastTrackInquiry.requestedItems.length}
          />
          <Context inquiryId={currentFastTrackInquiry.id} />
        </>
      }
    >
      <AddItemButton />
      <ItemsPreviews
        items={currentFastTrackInquiry.requestedItems.map((requestedItem) => ({
          id: requestedItem.id,
          image: requestedItem.images[0],
          tooltip: requestedItem.productCode ?? requestedItem.productName ?? "",
        }))}
      />
    </Container>
  );
};
