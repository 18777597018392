import React, { useCallback } from "react";
import { AddItem as AddItemForm } from "../../components/forms/AddItem/AddItem";
import { AddItemForm as AddItemFormProp } from "../../components/forms/AddItem/types";
import { useSubmitFormErrorHander } from "../../utils/formHelpers";
import { useTranslation } from "react-i18next";
import { useFeedSuccess } from "../../utils/feedHooks";
import { useAddItemToFastTrackInquiryMutation } from "../../slices/fastTrackInquiries/api";

type AddItemProps = {
  fastTrackInquiryId: number;
  assignmentId: number;
  closeDrawer: () => void;
};

export const AddItem = ({
  fastTrackInquiryId,
  assignmentId,
  closeDrawer,
}: AddItemProps) => {
  const { t } = useTranslation("reusableContainers");

  const [addItem, { isLoading: isItemAdding }] =
    useAddItemToFastTrackInquiryMutation();

  const feedSuccess = useFeedSuccess();
  const { catchFormError, formError, fieldsErrors } = useSubmitFormErrorHander(
    t("fastTrackInquiries.addItem.defaultErrorMessage")
  );

  const submitHandler = useCallback(
    (form: AddItemFormProp) => {
      addItem({
        fastTrackInquiryId,
        ...form,
      })
        .unwrap()
        .then((response) => {
          feedSuccess(response.message);
          closeDrawer();
        })
        .catch(catchFormError);
    },
    [addItem, catchFormError, closeDrawer, fastTrackInquiryId, feedSuccess]
  );

  return (
    <AddItemForm
      list={assignmentId}
      onSubmit={submitHandler}
      isSubmitting={isItemAdding}
      formError={formError}
      fieldsErrors={fieldsErrors}
      fastTrack
    />
  );
};
