import React from "react";
import { alpha, ButtonBase, ButtonBaseProps, Typography } from "@mui/material";
import { colors } from "../theme/astra/colors";
import { useTranslation } from "react-i18next";
import { Plus } from "./astraIcons/Plus";
import { ButtonBaseTypeMap } from "@mui/material/ButtonBase/ButtonBase";

export const AddItem = <
  RootComponent extends React.ElementType = ButtonBaseTypeMap["defaultComponent"],
  // eslint-disable-next-line @typescript-eslint/ban-types
  AdditionalProps = {}
>({
  fastTrack,
  ...props
}: ButtonBaseProps<RootComponent, AdditionalProps> & {
  fastTrack?: boolean;
}) => {
  const { t } = useTranslation("components");

  return (
    <ButtonBase
      {...props}
      sx={{
        width: "128px",
        height: "128px",
        flexShrink: 0,
        flexGrow: 0,
        border: `1px solid ${
          fastTrack ? alpha(colors.pink, 0.5) : colors.dustBlue4
        }`,
        borderRadius: "4px",
        backgroundColor: colors.white,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "8px",
        color: fastTrack ? colors.textOnPink2 : colors.dustBlue1,

        ...props.sx,
      }}
    >
      <Plus />
      <Typography
        component="span"
        sx={{
          fontSize: "13px",
          lineHeight: "24px",
          fontWeight: "500",
          marginTop: "2px",
        }}
      >
        {t("addItem.label")}
      </Typography>
    </ButtonBase>
  );
};
