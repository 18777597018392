import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useYup } from "../../../utils/validation";
import { useFeedSuccess } from "../../../utils/feedHooks";
import { useForm } from "react-hook-form";
import { AddAssignmentForm } from "../../../slices/schoolInquiries/types";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSubmitFormErrorHander } from "../../../utils/formHelpers";
import { useSetDefaultCurrency } from "../../../utils/useSetDefaultCurrency";
import { Spinner } from "../../../components/wideDrawer/Spinner";
import { useAddAssignmentMutation } from "../../../slices/schoolInquiries/api";
import { addAssignmentSchemaCreator } from "./AssignmentForm/schema";
import { AssignmentForm } from "./AssignmentForm/AssignmentForm";
import { useSchoolInquiriesTableUrlTools } from "../hooks";
import { AstraForm } from "../../../components/astraWideDrawer/AstraForm";

export const AddAssignment = () => {
  const { t } = useTranslation("pages");
  const feedSuccess = useFeedSuccess();
  const {
    closeDrawer,
    inquiryId,
    departmentInquiryId,
    inquiryResult: { data: inquiry, isLoading: isInquiryLoading },
  } = useSchoolInquiriesTableUrlTools();

  const schema = useYup(addAssignmentSchemaCreator);
  const { handleSubmit, control, reset } = useForm<AddAssignmentForm>({
    resolver: yupResolver(schema),
  });
  const { catchFormError, formError, fieldsErrors } = useSubmitFormErrorHander(
    t("schoolInquiryDetails.errors.addAssignment")
  );

  const { isLoading: areCurrenciesLoading } = useSetDefaultCurrency(reset);

  const [addAssignment, { isLoading }] = useAddAssignmentMutation();

  const onSubmit = useCallback(
    (form: AddAssignmentForm) => {
      if (!departmentInquiryId) {
        return null;
      }

      addAssignment({ inquiryId, departmentInquiryId, ...form })
        .unwrap()
        .then((res) => {
          feedSuccess(res.message);
          closeDrawer();
          reset();
        })
        .catch(catchFormError);
    },
    [
      addAssignment,
      catchFormError,
      closeDrawer,
      departmentInquiryId,
      feedSuccess,
      inquiryId,
      reset,
    ]
  );

  if (areCurrenciesLoading || isInquiryLoading) {
    return <Spinner />;
  }

  return (
    <AstraForm
      heading={t("schoolInquiryDetails.addAssignment")}
      onSubmit={handleSubmit(onSubmit)}
      loading={isLoading}
      formError={formError}
      submitText={t("schoolInquiryDetails.addAssignment")}
      sx={{
        padding: 4,
      }}
    >
      <AssignmentForm
        control={control}
        fieldsErrors={fieldsErrors}
        schoolId={inquiry?.school.id}
        isBudget={!!inquiry?.budget || false}
      />
    </AstraForm>
  );
};
