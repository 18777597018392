import React, { useCallback, useEffect } from "react";
import { useSchoolInquiriesTableUrlTools } from "../hooks";
import { useTranslation } from "react-i18next";
import { schoolInquirySchemaCreator } from "./Form/schema";
import { useYup } from "../../../utils/validation";
import { useFeedSuccess } from "../../../utils/feedHooks";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSubmitFormErrorHander } from "../../../utils/formHelpers";
import { Form as SchoolInquiryForm } from "./Form/Form";
import { useAddSchoolInquiryMutation } from "../../../slices/schoolInquiries/api";
import { SchoolInquiryForm as SchoolInquiryFormProp } from "../../../slices/schoolInquiries/types";
import { Spinner } from "../../../components/wideDrawer/Spinner";
import { useSelector } from "../../../store";
import {
  selectSelfId,
  selectSelfRole,
  selectSelfSchoolId,
} from "../../../slices/auth/selectors";
import { DefaultValues } from "react-hook-form/dist/types/form";
import { AstraForm } from "../../../components/astraWideDrawer/AstraForm";
import { useGetSchoolCurrency } from "../../../slices/currencies/hooks";

export const Add = () => {
  const { t } = useTranslation("pages");
  const feedSuccess = useFeedSuccess();
  const { closeDrawer } = useSchoolInquiriesTableUrlTools();

  const schema = useYup(schoolInquirySchemaCreator);
  const { handleSubmit, reset, control } = useForm<SchoolInquiryFormProp>({
    resolver: yupResolver(schema),
  });
  const { catchFormError, formError, fieldsErrors } = useSubmitFormErrorHander(
    t("schoolInquiries.errors.add")
  );

  const selfId = useSelector(selectSelfId);
  const selfRole = useSelector(selectSelfRole);
  const selfSchoolId = useSelector(selectSelfSchoolId);

  const { result: schoolCurrency, isLoading: isSchoolCurrencyLoading } =
    useGetSchoolCurrency();

  useEffect(() => {
    const values: DefaultValues<SchoolInquiryFormProp> = {
      budgetCurrencyId: schoolCurrency?.id,
    };

    if (selfRole === "principal" && selfSchoolId && selfId) {
      values.schoolId = selfSchoolId;
      values.userId = selfId;
    }

    reset(values);
  }, [reset, schoolCurrency?.id, selfId, selfRole, selfSchoolId]);

  const [addInquiry, { isLoading: isAdding }] = useAddSchoolInquiryMutation();

  const onSubmit = useCallback(
    ({
      isBudget,
      budget,
      budgetCurrencyId,
      ...form
    }: SchoolInquiryFormProp) => {
      const formData = {
        isBudget,
        budget: isBudget && !!budget ? budget : null,
        budgetCurrencyId: isBudget && !!budget ? budgetCurrencyId : null,
        ...form,
      };

      addInquiry(formData)
        .unwrap()
        .then(() => {
          feedSuccess(t("schoolInquiries.successes.add"));
          closeDrawer();
        })
        .catch(catchFormError);
    },
    [addInquiry, catchFormError, closeDrawer, feedSuccess, t]
  );

  if (isSchoolCurrencyLoading) {
    return <Spinner />;
  }

  return (
    <AstraForm
      heading={t("schoolInquiries.add")}
      onSubmit={handleSubmit(onSubmit)}
      loading={isAdding}
      formError={formError}
      submitText={t("schoolInquiries.add")}
      sx={{
        padding: 4,
      }}
    >
      <SchoolInquiryForm control={control} fieldsErrors={fieldsErrors} />
    </AstraForm>
  );
};
