import React from "react";
import { AddItem } from "../../../reusable-containers/AddItem/AddItem";
import { useGetCurrentFastTrackInquiryQuery } from "../../../slices/fastTrackInquiries/api";

export const AddItemToFastTrackInquiry = ({
  closeDrawer,
}: {
  closeDrawer: () => void;
}) => {
  const { data: currentFastTrackInquiry } =
    useGetCurrentFastTrackInquiryQuery();

  if (!currentFastTrackInquiry) {
    return null;
  }

  return (
    <AddItem
      fastTrackInquiryId={currentFastTrackInquiry.id}
      assignmentId={currentFastTrackInquiry.assignmentId}
      closeDrawer={closeDrawer}
    />
  );
};
