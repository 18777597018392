import React, { useCallback } from "react";
import { useYup } from "../../../utils/validation";
import { useTranslation } from "react-i18next";
import { useFeedSuccess } from "../../../utils/feedHooks";
import { useSubmitFormErrorHander } from "../../../utils/formHelpers";
import { useAddSchoolDepartmentInquiryMutation } from "../../../slices/schoolInquiries/api";
import {
  AddSchoolDepartmentInquiryForm,
  SchoolDepartmentInquiryForm,
} from "../../../slices/schoolInquiries/types";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Spinner } from "../../../components/wideDrawer/Spinner";
import { useSetDefaultCurrency } from "../../../utils/useSetDefaultCurrency";
import { DepartmentForm } from "./DepartmentForm/DepartmentForm";
import { addDepartmentInquirySchemaCreator } from "./DepartmentForm/schema";
import { useSchoolInquiriesTableUrlTools } from "../hooks";
import { AstraForm } from "../../../components/astraWideDrawer/AstraForm";

export const AddDepartment = () => {
  const { t } = useTranslation("pages");
  const feedSuccess = useFeedSuccess();

  const {
    closeDrawer,
    inquiryId,
    inquiryResult: { data: inquiry, isLoading: isInquiryLoading },
  } = useSchoolInquiriesTableUrlTools();

  const schema = useYup(addDepartmentInquirySchemaCreator);
  const { handleSubmit, control, reset } =
    useForm<AddSchoolDepartmentInquiryForm>({
      resolver: yupResolver(schema),
    });
  const { catchFormError, formError, fieldsErrors } = useSubmitFormErrorHander(
    t("schoolInquiry.errors.addDepartment")
  );

  const [addSchoolDepartmentInquiry, { isLoading }] =
    useAddSchoolDepartmentInquiryMutation();

  const onSubmit = useCallback(
    (form: SchoolDepartmentInquiryForm) => {
      addSchoolDepartmentInquiry({ inquiryId, ...form })
        .unwrap()
        .then((res) => {
          feedSuccess(res.message);
          closeDrawer();
          reset();
        })
        .catch(catchFormError);
    },
    [
      addSchoolDepartmentInquiry,
      catchFormError,
      closeDrawer,
      feedSuccess,
      inquiryId,
      reset,
    ]
  );

  const { isLoading: areCurrenciesLoading } = useSetDefaultCurrency(reset);

  if (isInquiryLoading || areCurrenciesLoading) {
    return <Spinner />;
  }

  if (!inquiry) {
    return null;
  }

  return (
    <AstraForm
      heading={t("schoolInquiryDetails.addDepartment")}
      onSubmit={handleSubmit(onSubmit)}
      loading={isLoading}
      formError={formError}
      submitText={t("schoolInquiryDetails.addDepartment")}
      sx={{
        padding: 4,
      }}
    >
      <DepartmentForm
        control={control}
        fieldsErrors={fieldsErrors}
        schoolId={inquiry.school.id}
        isBudget={!!inquiry?.budget || false}
      />
    </AstraForm>
  );
};
