import React, { useCallback, useMemo } from "react";
import {
  Box,
  Button,
  Card,
  CardMedia,
  Grid,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { usePermissions } from "../../../../../utils/usePermissions";
import { useSchoolInquiriesTableUrlTools } from "../../../hooks";
import { useHistory } from "react-router-dom";
import { GetSchoolInquiriesForPrincipalResponseDto } from "../../../../../slices/schoolInquiries/types";
import {
  useCancelSchoolInquiryMutation,
  useDeleteSchoolInquiryMutation,
} from "../../../../../slices/schoolInquiries/api";
import { useMessageHandlers } from "../../../../../utils/useMessageHandlers";
import { INQUIRY_SCHOOL_STATUS_CHIP_STYLES } from "../../../../../consts";
import { Department } from "./Department";
import { Add } from "@mui/icons-material";
import { colors } from "../../../../../theme/astra/colors";
import { NewDesignContextMenu } from "../../../../../components/NewDesignContextMenu";
import { downloadPrincipalInquiryFileFromServer } from "../../../../../utils/downloadFileFromServer";
import { StatusChip } from "../../../../../components/StatusChip";
import { ConfirmationButton } from "../../../../../components/ConfirmationButton";
import { useDisplayPrice } from "../../../../../utils/useDisplayPrice";
import { useSelector } from "../../../../../store";
import { selectSelfSchoolCurrency } from "../../../../../slices/auth/selectors";
import { Statuses } from "./Statuses";
import { usePreActionCheck } from "../../../../../slices/schoolInquiries/hooks";
import { ProductsWithoutPriceTooltip } from "../../ProductsWithoutPriceTooltip";

type InquiryProps = {
  inquiry: GetSchoolInquiriesForPrincipalResponseDto["data"][number];
};

export const Inquiry = ({
  inquiry: {
    id,
    name,
    school,
    schoolStatus,
    budget,
    totalAmount,
    departmentInquiries,
    productsWithoutPrice,
  },
}: InquiryProps) => {
  const { t } = useTranslation("pages");
  const can = usePermissions();
  const history = useHistory();
  const { handleSuccess, handleError } = useMessageHandlers();
  const displayPrice = useDisplayPrice();
  const selfCurrency = useSelector(selectSelfSchoolCurrency);

  const { baseUrl } = useSchoolInquiriesTableUrlTools();

  const [deleteSchoolInquiry] = useDeleteSchoolInquiryMutation();

  const [cancel] = useCancelSchoolInquiryMutation();

  const clickHandler = useCallback(() => {
    cancel(id)
      .unwrap()
      .then(handleSuccess)
      .then(() => {
        history.push("/schoolinquiries");
      })
      .catch(handleError);
  }, [cancel, handleError, handleSuccess, history, id]);

  const onExportClick = useCallback(
    () => id && downloadPrincipalInquiryFileFromServer(id),
    [id]
  );

  const isAddingDepartmentDisabled =
    schoolStatus !== "new" &&
    schoolStatus !== "inProgress" &&
    schoolStatus !== "pendingApproval";

  const addDepartmentHandler = useCallback(() => {
    history.push(`${baseUrl}/${id}/addDepartment`);
  }, [baseUrl, history, id]);

  const sendToAstraHandler = useCallback(() => {
    history.push(`${baseUrl}/${id}/sendToAstra`);
  }, [baseUrl, history, id]);

  const actions = useMemo(
    () => (
      <NewDesignContextMenu
        sx={{ marginLeft: "auto" }}
        buttons={[
          {
            label: t("actions.edit", { ns: "common" }),
            handler: () => {
              history.push(`${baseUrl}/${id}/edit`);
            },
            disabled:
              !can("editSchoolInquiry") ||
              (schoolStatus !== "new" &&
                schoolStatus !== "inProgress" &&
                schoolStatus !== "pendingApproval" &&
                schoolStatus !== "readyForSending"),
          },
          {
            label: t("schoolInquiryDetails.inquiryControls.exportItems.label"),
            handler: onExportClick,
            disabled: !id || schoolStatus === "cancelled",
          },
          {
            label: t("schoolInquiryDetails.inquiryControls.cancel.label"),
            handler: clickHandler,
            disabled:
              !id ||
              (schoolStatus !== "new" &&
                schoolStatus !== "inProgress" &&
                schoolStatus !== "pendingApproval"),
            confirmation: {
              title: t(
                "schoolInquiryDetails.inquiryControls.cancel.confirmationTitle"
              ),
              description: t("noUndo", { ns: "common" }),
            },
          },
          {
            label: t("schoolInquiries.actions.delete.label"),
            handler: () => {
              deleteSchoolInquiry(id)
                .unwrap()
                .then(handleSuccess)
                .catch(handleError);
            },
            confirmation: {
              title: t("schoolInquiries.actions.delete.confirmationTitle"),
              description: t(
                "schoolInquiries.actions.delete.confirmationDescription"
              ),
            },
            disabled: !can("deleteSchoolInquiry") || schoolStatus !== "new",
          },
        ]}
      />
    ),
    [
      baseUrl,
      can,
      clickHandler,
      deleteSchoolInquiry,
      handleError,
      handleSuccess,
      history,
      id,
      onExportClick,
      schoolStatus,
      t,
    ]
  );

  const showHelpForSendingToAstra = useMemo(() => {
    if (schoolStatus !== "readyForSending") {
      return true;
    }

    return !departmentInquiries.every(
      ({ schoolStatus }) =>
        schoolStatus === "approvedByPrincipal" || schoolStatus === "cancelled"
    );
  }, [departmentInquiries, schoolStatus]);

  const preActionCheck = usePreActionCheck();

  const disableSendingToAstra = preActionCheck(schoolStatus);

  const departmentInquiryLink = useMemo(() => {
    const departmentInquiry = departmentInquiries.find(
      (departmentInquiry) => departmentInquiry.schoolStatus === "approvedByHead"
    );

    if (!departmentInquiry) {
      return null;
    }

    return `/schoolinquiries/${id}/details/${departmentInquiry.id}/items`;
  }, [departmentInquiries, id]);

  return (
    <Card
      variant="outlined"
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        position: "relative",
        border,
        backgroundColor: colors.whitishGray,
      }}
    >
      <CardMedia
        sx={{
          padding: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: 1,
          borderBottom: border,
          paddingInline: "32px",
        }}
      >
        <Box sx={{ display: "flex", flex: "1" }}>
          <Box sx={{ marginRight: "40px", marginLeft: "10px" }}>
            <Title>{name}</Title>
            <School>{school.name}</School>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <StatusChip
              chipStyle={INQUIRY_SCHOOL_STATUS_CHIP_STYLES[schoolStatus]}
              label={t(`schoolInquiryStatuses.${schoolStatus}`, {
                ns: "common",
              })}
            />
          </Box>
        </Box>
        <ProductsWithoutPriceTooltip
          count={productsWithoutPrice}
          placement="bottom-start"
        >
          <Box
            sx={{
              flex: "1",
              display: "flex",
              gap: "8px",
              alignItems: "baseline",
              flexDirection: "column",
              marginLeft: "40px",
            }}
          >
            <Typography sx={{ fontSize: "14px", color: colors.dustBlue1 }}>
              {t("schoolInquiries.budgetInquiryTitle")},{" "}
              {selfCurrency?.code?.toUpperCase() ||
                t("currencies.qar", { ns: "common" })}
              :
            </Typography>
            <Box sx={{ display: "flex", gap: "3px", alignItems: "baseline" }}>
              <BudgetPrice>
                {displayPrice(totalAmount)}
                {productsWithoutPrice > 0 ? "+" : ""}
              </BudgetPrice>
              <BudgetCurrency>
                {selfCurrency?.code?.toUpperCase() ||
                  t("currencies.qar", { ns: "common" })}
              </BudgetCurrency>
              <Typography
                sx={{
                  fontSize: "16px",
                  color: colors.dustBlue2,
                  fontWeight: "500",
                }}
              >
                /
              </Typography>
              <BudgetPrice>{displayPrice(budget) || "N/A"}</BudgetPrice>
              <BudgetCurrency>
                {selfCurrency?.code?.toUpperCase() ||
                  t("currencies.qar", { ns: "common" })}
              </BudgetCurrency>
            </Box>
          </Box>
        </ProductsWithoutPriceTooltip>
        <Box
          sx={{
            flex: "1",
            display: "flex",
            gap: "20px",
            justifyContent: "end",
          }}
        >
          <AddDepartmentButton
            startIcon={<Add />}
            variant="outlined"
            onClick={addDepartmentHandler}
            disabled={isAddingDepartmentDisabled}
          >
            {t("schoolInquiryDetails.addDepartment")}
          </AddDepartmentButton>
          {showHelpForSendingToAstra && !disableSendingToAstra ? (
            <ConfirmationButton
              component={SendToAstraButton}
              confirmationTitle={t(
                "schoolInquiryDetails.inquiryControls.drawer.finilizeAllFirst"
              )}
              confirmationDescription={t(
                "schoolInquiryDetails.inquiryControls.drawer.finilizeAllFirstDescription"
              )}
              variant="contained"
              proceedButtonText={t("ok", { ns: "common" })}
            >
              {t("schoolInquiryDetails.inquiryControls.drawer.label")}
            </ConfirmationButton>
          ) : (
            <SendToAstraButton
              disabled={disableSendingToAstra}
              variant="contained"
              onClick={sendToAstraHandler}
            >
              {t("schoolInquiryDetails.inquiryControls.drawer.label")}
            </SendToAstraButton>
          )}
          <Grid>{actions}</Grid>
        </Box>
      </CardMedia>
      <Stack
        sx={{ marginTop: "10px", paddingInline: "12px", paddingBlock: "20px" }}
      >
        <Statuses
          current={schoolStatus}
          departmentInquiryLink={departmentInquiryLink}
        />
        <Stack>
          {departmentInquiries.map((departmentInquiry) => (
            <Department
              key={`department-list-${id}-department-${departmentInquiry.id}`}
              departmentInquiry={departmentInquiry}
              inquiryId={id}
              inquiryBudget={budget}
              inquirySchoolStatus={schoolStatus}
            />
          ))}
        </Stack>
      </Stack>
    </Card>
  );
};

const AddDepartmentButton = styled(Button)({
  color: colors.dustBlue1,
  backgroundColor: colors.white,
  borderColor: colors.dustBlue4,
  textTransform: "capitalize",
});

const SendToAstraButton = styled(Button)({
  color: colors.white,
  backgroundColor: colors.blue,
});

const Title = styled(Typography)(({ theme }) => ({
  fontSize: "22px",
  fontWeight: "500",
  color: theme.palette.primary.dark,
  lineHeight: "28px",
}));

const School = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  fontWeight: "500",
  color: theme.palette.secondary.main,
  lineHeight: "24px",
}));

const BudgetPrice = styled(Typography)({
  fontSize: "16px",
  color: colors.darkBlue,
  fontWeight: "500",
});

const BudgetCurrency = styled(Typography)({
  fontSize: "12px",
  color: colors.darkBlue,
  fontWeight: "500",
});

const border = "1px solid #CFDFEF";
