import React from "react";
import { AddItem } from "../../../../components/AddItem";
import { Link } from "react-router-dom";

export const AddItemButton = () => {
  return (
    <AddItem
      fastTrack
      to="/schoolinquiries/add-item-to-fast-track-inquiry"
      component={Link}
    />
  );
};
