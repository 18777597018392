import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ListColumn } from "../../../../components/List/types";
import { EnhancedSkeleton } from "../../../../components/EnhancedSkeleton";
import { Box, Link as MaterialLink, Tooltip } from "@mui/material";
import { ImageSliderFixedHeight } from "../../../../components/ImageSliderFixedHeight";
import defaultImage from "../../../../assets/item-default-image.png";
import { Link } from "react-router-dom";
import { getShortText } from "../../../../utils/getShortText";
import { useDisplayPrice } from "../../../../utils/useDisplayPrice";
import { useDisplayTotalAmount } from "../../../../utils/useDisplayTotalAmount";
import { RemoveItem } from "./columns/RemoveItem";
import { AddItemToWishlist } from "./columns/AddItemToWishlist";
import { QuantityField } from "./columns/QuantityField";

export const useFastTrackItemsColumns = ({
  inquiryId,
  isAstra,
}: {
  inquiryId: number;
  isAstra: boolean;
}): Array<
  ListColumn<{
    id: number;
    images: string[];
    itemId: number;
    productCode: string;
    productName: string;
    quantity: number;
    retailPrice: number | null;
    totalAmount: number;
    currency: string | null;
    productId: number | null;
    isInWishlist: boolean;
  }>
> => {
  const { t } = useTranslation("pages");

  const displayPrice = useDisplayPrice();
  const displayTotalAmount = useDisplayTotalAmount();

  const na = t("na", { ns: "common" });
  const priceUponRequest = t("priceUponRequest", { ns: "common" });

  return useMemo(
    () => [
      {
        field: "internalLineNumber",
        name: t("columns.id", { ns: "common" }),
        width: "40px",
      },
      {
        field: "images",
        name: t("fastTrackInquiries.columns.image"),
        width: "120px",
        skipOnSubRow: true,
        renderSkeleton: () => (
          <EnhancedSkeleton
            variant="rectangular"
            sx={{
              width: "100%",
              height: "100px",
            }}
          />
        ),
        renderCell: (row) => (
          <Box
            sx={{
              height: "100%",
              width: "100%",
              paddingTop: 1,
              paddingBottom: 1,
            }}
          >
            {row.images.length > 0 ? (
              <ImageSliderFixedHeight
                height={100}
                images={row.images.map((url) => ({ src: url }))}
              />
            ) : (
              <ImageSliderFixedHeight
                height={100}
                images={[{ src: defaultImage }]}
              />
            )}
          </Box>
        ),
      },
      {
        field: "productCode",
        name: t("fastTrackInquiries.columns.productCode"),
        renderCell: (row) => row.productCode ?? na,
      },
      {
        field: "productName",
        name: t("fastTrackInquiries.columns.productName"),
        renderCell: ({ itemId, productCode, productName }) => {
          const value = productName ?? na;

          return (
            <Tooltip title={value}>
              {productCode ? (
                <MaterialLink
                  component={Link}
                  to={`/fast-track-inquiries/${inquiryId}/${itemId}`}
                >
                  {getShortText(value, 30)}
                </MaterialLink>
              ) : (
                <span>{getShortText(value, 30)}</span>
              )}
            </Tooltip>
          );
        },
      },
      {
        field: "quantity",
        name: t("fastTrackInquiries.columns.quantity"),
        renderCell: ({ id, quantity }) =>
          isAstra ? (
            quantity
          ) : (
            <QuantityField
              fastTrackInquiryId={inquiryId}
              requestedItemId={id}
              quantity={quantity}
            />
          ),
      },
      {
        field: "retailPrice",
        name: t("fastTrackInquiries.columns.retailPrice"),
        mirrorMasterCell: true,
        renderCell: ({ productCode, retailPrice, currency }) => {
          const isPricePresent = retailPrice && currency;

          if (productCode && !isPricePresent) {
            return priceUponRequest;
          }

          const priceFormatted = displayPrice(retailPrice) ?? na;
          const currencyCode = currency ?? na;

          if (!productCode) {
            return priceFormatted;
          }

          return `${priceFormatted} ${currencyCode}`;
        },
      },
      {
        field: "totalAmount",
        name: t("fastTrackInquiries.columns.totalAmount"),
        renderCell: ({ productCode, retailPrice, currency, totalAmount }) =>
          displayTotalAmount({
            productCode,
            retailPrice,
            currency,
            totalAmount,
          }),
      },
      {
        field: "actions",
        name: t("columns.actions", { ns: "common" }),
        boxSx: {
          justifyContent: "flex-end",
        },
        renderCell: ({ id, isInWishlist, productId }) => (
          <Box>
            {typeof productId === "number" && (
              <AddItemToWishlist
                isInWishlist={isInWishlist}
                fastTrackInquiryId={inquiryId}
                productId={productId}
              />
            )}
            <RemoveItem fastTrackInquiryId={inquiryId} requestedItemId={id} />
          </Box>
        ),
      },
    ],
    [
      displayPrice,
      displayTotalAmount,
      inquiryId,
      isAstra,
      na,
      priceUponRequest,
      t,
    ]
  );
};
