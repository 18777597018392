import React from "react";
import { ItemsPreviews } from "../ItemsPreviews";
import { Container } from "./Container";
import { DataBox, DataBoxValue } from "./DataBox";
import { useTranslation } from "react-i18next";
import { Box, styled, Typography } from "@mui/material";
import { colors } from "../../../../theme/astra/colors";
import { GetFastTrackInquiriesResponseDto } from "../../../../slices/fastTrackInquiries/types";
import { useDisplayPrice } from "../../../../utils/useDisplayPrice";

type FastTrackInquiryProps = {
  fastTrackInquiry: GetFastTrackInquiriesResponseDto["data"][number];
};

export const FastTrackInquiry = ({
  fastTrackInquiry,
}: FastTrackInquiryProps) => {
  const { t } = useTranslation("pages");

  const displayPrice = useDisplayPrice();

  return (
    <Container
      inquiryId={fastTrackInquiry.id}
      code={fastTrackInquiry.fastTrackIndex}
      headerElements={
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
            marginLeft: "32px",
          }}
        >
          <DataBox label={t("fastTrackInquiries.products")} reverse>
            {fastTrackInquiry.totalProducts.toString()}
          </DataBox>
          {fastTrackInquiry.productsWithoutPrice > 0 && (
            <>
              <Typography
                component="span"
                sx={{
                  fontSize: "14px",
                  lineHeight: "24px",
                  fontWeight: "400",
                  color: colors.dustBlue1,
                }}
              >
                +
              </Typography>
              <DataBox
                label={t("fastTrackInquiries.productsWithoutPrice", {
                  count: fastTrackInquiry.productsWithoutPrice,
                })}
                reverse
              >
                {fastTrackInquiry.productsWithoutPrice.toString()}
              </DataBox>
            </>
          )}
          <Divider />
          <DataBox label={t("fastTrackInquiries.spentBudget")}>
            <Box>
              <DataBoxValue as="span">
                {displayPrice(fastTrackInquiry.budgetSpent)}
              </DataBoxValue>
              <DataBoxValue
                as="span"
                sx={{
                  fontSize: "12px",
                  lineHeight: "20px",
                  marginLeft: "3px",
                }}
              >
                {fastTrackInquiry.currency}
              </DataBoxValue>
            </Box>
          </DataBox>
        </Box>
      }
    >
      <ItemsPreviews
        items={fastTrackInquiry.requestedItems.map((requestedItem) => ({
          id: requestedItem.id,
          image: requestedItem.images[0],
          tooltip: requestedItem.productCode ?? requestedItem.productName ?? "",
        }))}
      />
    </Container>
  );
};

const Divider = styled(Box)({
  width: "1px",
  height: "24px",
  backgroundColor: colors.dustBlue4,
});
