import React from "react";
import { Content } from "./Content/Content";
import { Helmet } from "react-helmet-async";
import { getTitle } from "../../utils/getTitle";
import { useTranslation } from "react-i18next";
import { usePermissions } from "../../utils/usePermissions";
import { WideDrawer } from "../../components/wideDrawer/WideDrawer";
import { Assign } from "./drawers/Assign";
import { useItemDetailsUrlTools } from "./useItemDetailsUrlTools";
import { ProductDetails } from "./drawers/ProductDetails";
import { Box } from "@mui/material";
import { CommentDrawer } from "../../components/legacyLayout/Comments/CommentDrawer";
import { AssignPurchaseOrder } from "./drawers/AssignPurchaseOrder";
import { AssignMainOrder } from "./drawers/AssignMainOrder";
import { useRouteMatch } from "react-router-dom";
import { RouteMatchId } from "../../types";
import { Edit } from "./drawers/Edit";
import { EditProduct } from "./drawers/EditProduct";

export const ItemDetails: React.FC = () => {
  const { t } = useTranslation("pages");
  const can = usePermissions();

  const { itemId, closeDrawer, baseUrl } = useItemDetailsUrlTools();

  const assignDrawerMatcher = useRouteMatch<RouteMatchId>(
    `${baseUrl}/assign/:id`
  );
  const assignProductDrawer = assignDrawerMatcher?.params.id
    ? parseInt(assignDrawerMatcher.params.id)
    : null;

  const productEditDrawerMatcher = useRouteMatch<RouteMatchId>(
    `${baseUrl}/product/:id/edit`
  );
  const productEditDrawer =
    productEditDrawerMatcher?.params.id && !assignDrawerMatcher
      ? parseInt(productEditDrawerMatcher.params.id)
      : null;

  const productDetailsDrawerMatcher = useRouteMatch<RouteMatchId>(
    `${baseUrl}/product/:id`
  );
  const productDetailsDrawer =
    productDetailsDrawerMatcher?.params.id &&
    !assignDrawerMatcher &&
    !productEditDrawer
      ? parseInt(productDetailsDrawerMatcher.params.id)
      : null;

  const editItemDrawerMatcher = useRouteMatch<RouteMatchId>(`${baseUrl}/edit`);

  const assignPurchaseOrderMatcher = useRouteMatch(
    `${baseUrl}/assignPurchaseOrder`
  );

  const assignMainOrderMatcher = useRouteMatch(`${baseUrl}/assignMainOrder`);

  return (
    <Box sx={{ marginRight: "240px" }}>
      <Helmet>
        <title>{getTitle(t("itemDetails.title"))}</title>
      </Helmet>
      {can("getInquiries") && <Content />}
      {can("matchItem") && (
        <WideDrawer close={closeDrawer} open={assignProductDrawer !== null}>
          {assignProductDrawer !== null && (
            <Assign
              baseUrl={baseUrl}
              closeDrawer={closeDrawer}
              productId={assignProductDrawer}
              itemId={itemId}
            />
          )}
        </WideDrawer>
      )}
      {can("getProduct") && (
        <WideDrawer close={closeDrawer} open={productDetailsDrawer !== null}>
          {productDetailsDrawer !== null && (
            <ProductDetails
              closeDrawer={closeDrawer}
              id={productDetailsDrawer}
            />
          )}
        </WideDrawer>
      )}
      {can("editProduct") && (
        <WideDrawer close={closeDrawer} open={productEditDrawer !== null}>
          {productEditDrawer !== null && (
            <EditProduct closeDrawer={closeDrawer} id={productEditDrawer} />
          )}
        </WideDrawer>
      )}
      {can("assignItemPurchaseOrder") && (
        <WideDrawer open={!!assignPurchaseOrderMatcher} close={closeDrawer}>
          <AssignPurchaseOrder />
        </WideDrawer>
      )}
      {can("assignItemMainOrder") && (
        <WideDrawer open={!!assignMainOrderMatcher} close={closeDrawer}>
          <AssignMainOrder />
        </WideDrawer>
      )}
      {can("editItem") && (
        <WideDrawer open={editItemDrawerMatcher !== null} close={closeDrawer}>
          <Edit />
        </WideDrawer>
      )}
      <CommentDrawer commentableType="item" commentableId={itemId} />
    </Box>
  );
};
