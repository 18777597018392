import React from "react";
import { SubmitSpinnerButtonProps } from "../SubmitSpinnerButton";
import { Alert, Box, BoxProps, Collapse, Typography } from "@mui/material";
import { FormErrorState } from "../../utils/formHelpers";
import { useTranslation } from "react-i18next";
import { colors } from "../../theme/astra/colors";
import { LoadingButton } from "@mui/lab";
import { Button } from "../astraComponents/Button";

type AstraFormProps = SubmitSpinnerButtonProps &
  Omit<BoxProps<"form">, "component" | "onSubmit"> & {
    heading?: string;
    onSubmit: () => void;
    formError: FormErrorState;
    submitText?: string;
    disabledSubmit?: boolean;
    fastTrack?: boolean;
  };

export const AstraForm = ({
  children,
  loading,
  onSubmit,
  heading,
  formError,
  submitText,
  disabledSubmit,
  fastTrack,
  sx,
  ...props
}: AstraFormProps) => {
  const { t } = useTranslation();

  return (
    <Box
      component="form"
      onSubmit={onSubmit}
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        ...sx,
      }}
      {...props}
    >
      {heading && (
        <Typography
          variant="h5"
          sx={{
            marginBottom: 2,
            fontSize: "24px",
            color: colors.darkBlue,
          }}
        >
          {heading}
        </Typography>
      )}
      {formError && (
        <Collapse in={!!formError}>
          <Alert severity="error">{formError}</Alert>
        </Collapse>
      )}
      {children}
      <Button
        component={LoadingButton}
        loading={loading}
        variant="contained"
        type="submit"
        fullWidth
        disabled={disabledSubmit}
        sx={{
          marginTop: "60px",
        }}
        fastTrack={fastTrack}
      >
        {submitText || t("actions.submit", { ns: "common" })}
      </Button>
    </Box>
  );
};
