import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useMessageHandlers } from "../../../../../utils/useMessageHandlers";
import { Trash } from "../../../../../components/astraIcons/Trash";
import { Button } from "../../../../../components/astraComponents/Button";
import { useRemoveItemsFromFastTrackInquiryMutation } from "../../../../../slices/fastTrackInquiries/api";

export const RemoveItems = ({
  inquiryId,
  isAstra,
  selectedIds,
}: {
  inquiryId: number;
  isAstra: boolean;
  selectedIds: number[];
}) => {
  const { t } = useTranslation("pages");
  const { handleError } = useMessageHandlers();

  const [removeItems, { isLoading: isRemoving }] =
    useRemoveItemsFromFastTrackInquiryMutation();

  const clickHandler = useCallback(() => {
    if (selectedIds.length === 0) {
      return;
    }

    removeItems({
      fastTrackInquiryId: inquiryId,
      requestedItemsIds: selectedIds,
    })
      .unwrap()
      .catch(handleError);
  }, [handleError, inquiryId, removeItems, selectedIds]);

  if (isAstra) {
    return null;
  }

  return (
    <Button
      variant="outlined"
      startIcon={<Trash />}
      sx={{
        marginLeft: "16px",
      }}
      disabled={isRemoving}
      onClick={clickHandler}
    >
      {t("fastTrackInquiries.listControls.remove")}
    </Button>
  );
};
