import React from "react";
import { Details } from "./drawers/Details";
import { Add } from "./drawers/Add/Add";
import { Helmet } from "react-helmet-async";
import { getTitle } from "../../../utils/getTitle";
import { useTranslation } from "react-i18next";
import { usePermissions } from "../../../utils/usePermissions";
import { Box } from "@mui/material";
import { useTableUrlTools } from "../../../utils/useTableUrlTools";
import { WideDrawer } from "../../../components/wideDrawer/WideDrawer";
import { DataTable } from "./DataTable/DataTable";
import { useInquiryDetailsUrlTools } from "../hooks";
import { useRouteMatch } from "react-router-dom";
import { Items } from "./drawers/Items";
import { useHeaderTitle } from "../../../slices/ui/useHeaderTitle";

type ItemsDrawerMatchedParams = {
  id: string;
};

export const PurchaseOrders = () => {
  const { t } = useTranslation("pages");
  const can = usePermissions();

  const { baseUrl, inquiryId } = useInquiryDetailsUrlTools();
  const { addDrawerOpen, detailsDrawerOpen, closeDrawer } = useTableUrlTools(
    `${baseUrl}/po`
  );

  const itemsDrawerMatcher = useRouteMatch<ItemsDrawerMatchedParams>(
    `${baseUrl}/po/:id/items`
  );

  const title = t("purchaseOrders.title");

  useHeaderTitle(title);

  return (
    <Box>
      <Helmet>
        <title>{getTitle(title)}</title>
      </Helmet>
      {can("getPurchaseOrders") && <DataTable />}
      {can("addPurchaseOrder") && (
        <WideDrawer open={addDrawerOpen} close={closeDrawer}>
          <Add inquiryId={inquiryId} />
        </WideDrawer>
      )}
      {can("getPurchaseOrder") && (
        <WideDrawer open={detailsDrawerOpen} close={closeDrawer}>
          <Details inquiryId={inquiryId} />
        </WideDrawer>
      )}
      {can("getItems") && (
        <WideDrawer open={!!itemsDrawerMatcher} close={closeDrawer}>
          <Items />
        </WideDrawer>
      )}
    </Box>
  );
};
