import React from "react";
import { useTranslation } from "react-i18next";
import { useHeaderTitle } from "../../slices/ui/useHeaderTitle";
import { getTitle } from "../../utils/getTitle";
import { Helmet } from "react-helmet-async";
import { useScrollToTop } from "../../utils/useScrollToTop";
import { Content } from "./Content/Content";
import { Drawers } from "./Drawers/Drawers";
import { useFastTrackInquiryItemsTools } from "./useFastTrackInquiryItemsTools";

export const FastTrackInquiryItems = () => {
  const { t } = useTranslation("pages");

  const { inquiryId } = useFastTrackInquiryItemsTools();
  // const { data: inquiry } = useGetSchoolInquiryQuery(inquiryId);

  const title: string = t("fastTrackInquiries.title");

  useHeaderTitle(title);
  useScrollToTop();

  return (
    <>
      <Helmet>
        <title>{getTitle(title)}</title>
      </Helmet>
      <Content inquiryId={inquiryId} />
      <Drawers />
    </>
  );
};
