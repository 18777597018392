import { skipToken } from "@reduxjs/toolkit/query";
import { useSelector } from "../../store";
import { selectShopList } from "./selectors";
import { useGetCartQuery, useGetShopListsQuery } from "./api";
import { useMemo } from "react";

export const useShopList = () => {
  const listId = useSelector(selectShopList);

  const { data: shopLists } = useGetShopListsQuery();

  return useMemo(() => {
    if (!shopLists) {
      return null;
    }

    return shopLists.find((shopList) => shopList.id === listId);
  }, [listId, shopLists]);
};

export const useAssignmentBudget = (): number | undefined => {
  const shopList = useShopList();

  return useMemo(() => shopList?.budget ?? undefined, [shopList]);
};

export const useTotalAmount = () => {
  const shopList = useShopList();

  const { data: items, isLoading } = useGetCartQuery(shopList?.id ?? skipToken);

  const result = useMemo(() => {
    return items?.reduce(
      (totalAmount, { product: { retailPrice, retailCurrency }, quantity }) => {
        if (!retailPrice || !retailCurrency) {
          return totalAmount;
        }

        // TODO change it after prices normalize
        return totalAmount + Math.round(retailPrice) * quantity;
      },
      0
    );
  }, [items]);

  return { result, isLoading };
};

export const useInCart = (productId?: number) => {
  const shopList = useShopList();

  const { data: items } = useGetCartQuery(shopList?.id ?? skipToken);

  return useMemo(() => {
    if (!productId) {
      return undefined;
    }

    return items?.find((cartItem) => cartItem.product.id === productId)
      ?.quantity;
  }, [items, productId]);
};

/**
 * This is supposed to never return true since we've added fast-track inquiries,
 * but I'm leaving it here for now.
 */
export const useIsCartDisabled = (skip = false) => {
  const { data: shopLists } = useGetShopListsQuery(
    skip ? skipToken : undefined
  );

  if (!shopLists) {
    return true;
  }

  return shopLists.length === 0;
};

export const useIsFastTrack = () => {
  const shopList = useShopList();

  if (!shopList) {
    return true;
  }

  return shopList.fastTrack;
};
