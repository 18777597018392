import React, { useCallback } from "react";
import { alpha } from "@mui/material";
import { useTranslation } from "react-i18next";
import { colors } from "../../../../../theme/astra/colors";
import { AstraConfirmationButton } from "../../../../../components/AstraConfirmationButton";
import { AttentionRequiredDialog } from "../../../../../components/AttentionRequiredDialog";
import { Button } from "../../../../../components/astraComponents/Button";
import { useSendFastTrackInquiryToAstraMutation } from "../../../../../slices/fastTrackInquiries/api";
import { useMessageHandlers } from "../../../../../utils/useMessageHandlers";
import { useDispatch } from "../../../../../store";
import { setIsOpen } from "../../../../../slices/fastTrackInquiries/slice";

export const SendToAstra = ({
  inquiryId,
  totalProducts,
}: {
  inquiryId: number;
  totalProducts: number;
}) => {
  const { t } = useTranslation("pages");
  const dispatch = useDispatch();
  const { handleSuccess, handleError } = useMessageHandlers();

  const [sendToAstra, { isLoading: isSendingToAstra }] =
    useSendFastTrackInquiryToAstraMutation();

  const proceedHandler = useCallback(() => {
    sendToAstra({ fastTrackInquiryId: inquiryId })
      .unwrap()
      .then((response) => {
        handleSuccess(response);
        dispatch(setIsOpen(false));
      })
      .catch(handleError);
  }, [dispatch, handleError, handleSuccess, inquiryId, sendToAstra]);

  return (
    <AstraConfirmationButton
      component={Button}
      variant="contained"
      fastTrack
      sx={{
        backgroundColor: colors.pink,

        "&:hover": {
          backgroundColor: alpha(colors.pink, 0.75),
        },
      }}
      onProceed={proceedHandler}
      loading={isSendingToAstra}
      disabled={totalProducts === 0}
      confirmation={({ open, onCancel, onProceed, loading }) => (
        <AttentionRequiredDialog
          open={open}
          onClose={onCancel}
          onProceed={onProceed}
          loading={loading}
          title={t(
            "fastTrackInquiries.controls.sendToAstra.confirmation.title"
          )}
          description={t(
            "fastTrackInquiries.controls.sendToAstra.confirmation.description"
          )}
          checkboxLabel={t(
            "fastTrackInquiries.controls.sendToAstra.confirmation.checkbox"
          )}
          proceedLabel={t(
            "fastTrackInquiries.controls.sendToAstra.confirmation.proceed"
          )}
        />
      )}
    >
      {t("fastTrackInquiries.controls.sendToAstra.label")}
    </AstraConfirmationButton>
  );
};
