import React, { useCallback } from "react";
import { IconButton } from "../../../../../components/astraComponents/IconButton";
import { colors } from "../../../../../theme/astra/colors";
import { useMessageHandlers } from "../../../../../utils/useMessageHandlers";
import {
  useAddWishlistRecordMutation,
  useRemoveWishlistRecordMutation,
} from "../../../../../slices/wishlistRecords/api";
import { AddToFavorites } from "../../../../../components/astraIcons/AddToFavorites";

export const AddItemToWishlist = ({
  isInWishlist,
  fastTrackInquiryId,
  productId,
}: {
  isInWishlist: boolean;
  fastTrackInquiryId: number;
  productId: number;
}) => {
  const { handleError } = useMessageHandlers();

  const [addWishlistRecord, { isLoading: isAddingWishlistRecord }] =
    useAddWishlistRecordMutation();
  const [removeWishlistRecord, { isLoading: isRemovingWishlistRecord }] =
    useRemoveWishlistRecordMutation();

  const clickHandler = useCallback(() => {
    if (isInWishlist) {
      removeWishlistRecord({
        productsIds: [productId],
        fastTrackInquiryId,
      })
        .unwrap()
        .catch(handleError);
    }

    if (!isInWishlist) {
      addWishlistRecord({
        fastTrackInquiryId,
        productsIds: [productId],
      })
        .unwrap()
        .catch(handleError);
    }
  }, [
    addWishlistRecord,
    fastTrackInquiryId,
    handleError,
    isInWishlist,
    productId,
    removeWishlistRecord,
  ]);

  return (
    <IconButton
      variant="transparent"
      loading={isAddingWishlistRecord || isRemovingWishlistRecord}
      onClick={clickHandler}
      sx={{
        color: isInWishlist ? colors.red : colors.dustBlue1,
      }}
    >
      <AddToFavorites />
    </IconButton>
  );
};
