import React, { useCallback, useEffect, useState } from "react";
import { Dialog as ModalComponent } from "../../../../components/Dialog";
import { useTranslation } from "react-i18next";
import { alpha } from "@mui/material";
import { SelectList } from "./SelectList";
import { useDispatch } from "../../../../store";
import { setShopList } from "../../../../slices/shop/actions";
import { useShopList } from "../../../../slices/shop/hooks";
import { Button } from "../../../../components/astraComponents/Button";
import { Refresh } from "../../../../components/astraIcons/Refresh";
import { colors } from "../../../../theme/astra/colors";

export const ChangeList = () => {
  const { t } = useTranslation("pages");
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);

  const openHandler = useCallback(() => {
    setOpen(true);
  }, []);

  const closeHandler = useCallback(() => {
    setOpen(false);
  }, []);

  const [value, setValue] = useState<number | null>(null);
  const shopList = useShopList();
  useEffect(() => {
    if (shopList) {
      setValue(shopList.id);
    }
  }, [shopList]);

  const changeHandler = useCallback((value: number | null) => {
    setValue(value);
  }, []);

  const submitHandler = useCallback(() => {
    if (shopList && typeof value === "number" && value !== shopList.id) {
      dispatch(setShopList(value));
    }

    setOpen(false);
  }, [dispatch, shopList, value]);

  return (
    <>
      <Button
        variant="outlined"
        sx={{
          marginLeft: "auto",
          alignSelf: "flex-end",

          "&.MuiButton-outlined": {
            borderColor: colors.blue,
            color: colors.blue,
            backgroundColor: alpha(colors.blue, 0.05),
          },

          "&.MuiButton-outlined:hover": {
            borderColor: colors.blue,
            backgroundColor: alpha(colors.blue, 0.1),
          },
        }}
        startIcon={<Refresh />}
        onClick={openHandler}
      >
        {t("shopCatalog.currentList.changeList")}
      </Button>
      <ModalComponent
        open={open}
        onClose={closeHandler}
        title={t("shopCatalog.currentList.changeTitle")}
        about={t("shopCatalog.currentList.changeAbout")}
      >
        <SelectList value={value} onChange={changeHandler} />
        <Button
          onClick={submitHandler}
          variant="contained"
          sx={{
            width: "100%",
            marginTop: "52px",
          }}
        >
          {t("shopCatalog.currentList.changeSubmit")}
        </Button>
      </ModalComponent>
    </>
  );
};
