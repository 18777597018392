import React, { useCallback } from "react";
import { IconButton } from "../../components/astraComponents/IconButton";
import { Heart } from "../../components/astraIcons/Heart";
import { colors } from "../../theme/astra/colors";
import {
  useAddWishlistRecordMutation,
  useRemoveWishlistRecordMutation,
} from "../../slices/wishlistRecords/api";
import { useSelector } from "../../store";
import { selectSelfRole } from "../../slices/auth/selectors";

type AddToWishlistProps = {
  productId?: number;
  isInWishlist?: boolean;
};

export const AddToWishlist = ({
  productId,
  isInWishlist,
}: AddToWishlistProps) => {
  const selfRole = useSelector(selectSelfRole);

  const [addWishlistRecord, { isLoading: isWishlistRecordAdding }] =
    useAddWishlistRecordMutation();
  const [removeWishlistRecord, { isLoading: isWishlistRecordRemoving }] =
    useRemoveWishlistRecordMutation();

  const clickHandler = useCallback(() => {
    if (!productId) {
      return;
    }

    if (isInWishlist) {
      removeWishlistRecord({
        productsIds: [productId],
      });

      return;
    }

    addWishlistRecord({
      productsIds: [productId],
    });
  }, [addWishlistRecord, isInWishlist, productId, removeWishlistRecord]);

  if (
    !selfRole ||
    !["principal", "headOfDepartment", "teacher"].includes(selfRole)
  ) {
    return null;
  }

  return (
    <IconButton
      variant="transparent"
      sx={{
        color: isInWishlist ? colors.red : colors.blue,
      }}
      onClick={clickHandler}
      loading={isWishlistRecordAdding || isWishlistRecordRemoving}
    >
      <Heart />
    </IconButton>
  );
};
