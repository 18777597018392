import { createSlice } from "@reduxjs/toolkit";
import { AuthSliceState } from "./types";
import { fetchPermissions, logOut, signIn, validateToken } from "./actions";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    token: null,
    user: null,
    permissions: null,
  } as AuthSliceState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(validateToken.fulfilled, (state, { payload }) => {
      if (!payload) {
        state.token = null;
        state.user = null;
      } else {
        state.token = payload.token;
        state.user = payload.user;
      }
    });

    builder.addCase(fetchPermissions.fulfilled, (state, { payload }) => {
      state.permissions = payload;
    });

    builder.addCase(signIn.fulfilled, (state, { payload }) => {
      state.token = payload.token;
      state.user = payload.user;
    });

    builder.addCase(logOut.pending, (state) => {
      state.token = null;
      state.user = null;
    });
  },
});
