import React, { useCallback } from "react";
import { ChangeSelfPassword } from "./ChangeSelfPassword";
import { useDispatch, useSelector } from "../../../store";
import { selectSelfId } from "../../../slices/auth/selectors";
import { setChangePasswordDrawer } from "../../../slices/ui/slice";
import { selectChangePasswordDrawer } from "../../../slices/ui/selectors";
import { AstraWideDrawer } from "../../astraWideDrawer/AstraWideDrawer";

export const ChangeSelfPasswordDrawer = () => {
  const dispatch = useDispatch();

  const selfId = useSelector(selectSelfId);
  const isOpen = useSelector(selectChangePasswordDrawer);

  const closeHandler = useCallback(() => {
    dispatch(setChangePasswordDrawer(false));
  }, [dispatch]);

  if (!selfId) {
    return null;
  }

  return (
    <AstraWideDrawer anchor="right" open={isOpen} close={closeHandler}>
      <ChangeSelfPassword selfId={selfId} closeDrawer={closeHandler} />
    </AstraWideDrawer>
  );
};
