import React from "react";
import { Typography } from "@mui/material";
import { colors } from "../../../theme/astra/colors";

export const FastTrackTitle = ({ children }: { children: string }) => {
  return (
    <Typography
      component="span"
      sx={{
        fontSize: "20px",
        lineHeight: "24px",
        fontWeight: "500",
        color: colors.pink,
        alignSelf: "center",
      }}
    >
      {children}
    </Typography>
  );
};
