import { alpha, Box } from "@mui/material";
import React from "react";
import { Title } from "./Title";
import { Spent } from "./Spent";
import { SendToAstra } from "./SendToAstra";
import { Context } from "./Context";
import { colors } from "../../../../theme/astra/colors";
import { StatusChip } from "../../../../components/StatusChip";
import { useTranslation } from "react-i18next";

export const Header = ({
  inquiryId,
  title,
  budgetSpent,
  currency,
  totalProducts,
  productsWithoutPrice,
  isAstra,
}: {
  inquiryId: number;
  title: string;
  budgetSpent: number;
  currency: string;
  totalProducts: number;
  productsWithoutPrice: number;
  isAstra: boolean;
}) => {
  const { t } = useTranslation("pages");

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        marginTop: "50px",
      }}
    >
      <Title title={title} />
      {isAstra && (
        <StatusChip
          label={t("fastTrackInquiries.sentToAstra")}
          chipStyle={{
            backgroundColor: alpha(colors.purple, 0.1),
            color: colors.purple,
          }}
          sx={{
            marginLeft: "40px",
          }}
        />
      )}
      <Spent
        spent={budgetSpent}
        currency={currency}
        totalProducts={totalProducts}
        productsWithoutPrice={productsWithoutPrice}
      />
      {!isAstra && (
        <>
          <SendToAstra inquiryId={inquiryId} totalProducts={totalProducts} />
          <Context inquiryId={inquiryId} />
        </>
      )}
    </Box>
  );
};
