import React, { useCallback } from "react";
import { MenuItem, TextField, TextFieldProps, Typography } from "@mui/material";
import { useGetShopListsQuery } from "../../../../slices/shop/api";
import { colors } from "../../../../theme/astra/colors";

export const SelectList = ({
  sx,
  value,
  onChange,
  ...props
}: Omit<TextFieldProps, "select" | "value" | "onChange"> & {
  value: number | null; // assignmentId
  onChange: (value: number | null) => void; // assignmentId
}) => {
  const { data: shopLists } = useGetShopListsQuery();

  const changeHandler = useCallback<NonNullable<TextFieldProps["onChange"]>>(
    (event) => {
      onChange(event.target.value !== "" ? parseInt(event.target.value) : null);
    },
    [onChange]
  );

  if (!shopLists) {
    return null;
  }

  const options = shopLists.map((assignment) => (
    <MenuItem
      key={assignment.id}
      value={assignment.id}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        paddingTop: "12px",
        paddingBottom: "6px",
        paddingLeft: "15px",
      }}
    >
      {assignment.fastTrack ? (
        <>
          <Typography
            component="span"
            sx={{
              fontSize: "14px",
              lineHeight: "24px",
              color: colors.pink,
              fontWeight: "500",
            }}
          >
            {assignment.inquiryName}
          </Typography>
        </>
      ) : (
        <>
          <Typography
            component="span"
            sx={{
              fontSize: "12px",
              lineHeight: "14px",
              color: "primary.dark",
              opacity: 0.5,
            }}
          >
            {assignment.inquiryName}
          </Typography>
          <Typography
            component="span"
            sx={{
              fontSize: "14px",
              lineHeight: "24px",
              fontWeight: "500",
              color: "primary.dark",
            }}
          >
            {assignment.departmentName}
          </Typography>
        </>
      )}
    </MenuItem>
  ));

  return (
    <TextField
      select
      value={value ?? ""}
      onChange={changeHandler}
      sx={{
        width: "100%",
        marginTop: "19px",

        ".MuiSelect-select": {
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          minHeight: "60px !important",
          boxSizing: "border-box",
          paddingTop: "12px",
          paddingBottom: "10px",
          paddingLeft: "15px",
        },

        ...sx,
      }}
      {...props}
    >
      {options}
    </TextField>
  );
};
