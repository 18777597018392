import React from "react";
import { createSvgIcon } from "@mui/material";

export const WarningTriangle = createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.953 16.3535L10.216 5.85653C10.9539 4.38476 13.0547 4.38515 13.7921 5.85718L19.0503 16.3542C19.7164 17.6841 18.7495 19.25 17.2621 19.25H6.74087C5.25314 19.25 4.2862 17.6835 4.953 16.3535Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 10V12"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.5 16C12.5 16.2761 12.2761 16.5 12 16.5C11.7239 16.5 11.5 16.2761 11.5 16C11.5 15.7239 11.7239 15.5 12 15.5C12.2761 15.5 12.5 15.7239 12.5 16Z"
      stroke="currentColor"
    />
  </svg>,
  "WarningTriangle"
);
