import React from "react";
import { Box } from "@mui/material";
import { SearchField, SearchFieldProps } from "./SearchField";
import { TagsField, TagsFieldProps } from "./TagsField";
import { SuggestedTags } from "./SuggestedTags";
import { Drawer } from "./Drawer/Drawer";
import { SchoolLevelsProps } from "./SchoolLevels";
import { PriceRange, PriceRangeProps } from "./PriceRange";
import { WishlistButtonProps } from "./Drawer/WishlistButton";
import { SearchButton } from "./SearchButton";

type FiltersProps = {
  onResetFilters?: () => void;
  TagsFieldProps: TagsFieldProps;
  SearchFieldProps: SearchFieldProps;
  SchoolLevelsProps: SchoolLevelsProps;
  PriceRangeProps: PriceRangeProps;
  WishlistButtonProps: WishlistButtonProps;
};

export const Filters = ({
  onResetFilters,
  TagsFieldProps,
  SearchFieldProps,
  SchoolLevelsProps,
  PriceRangeProps,
  WishlistButtonProps,
}: FiltersProps) => {
  return (
    <Box sx={{ marginTop: "72px" }}>
      <Box
        sx={{
          display: "flex",
          width: "100%",

          "> div": {
            flexGrow: 1,
            flexShrink: 1,
          },
        }}
      >
        <TagsField {...TagsFieldProps} />
        <SearchField {...SearchFieldProps} />
        <SearchButton />
      </Box>
      <SuggestedTags {...TagsFieldProps} />
      <Drawer
        onResetFilters={onResetFilters}
        {...{ SchoolLevelsProps, PriceRangeProps, WishlistButtonProps }}
      >
        {/* <SchoolLevels {...SchoolLevelsProps} /> */}
        {/* Temporary Hide PC-989 */}
        <PriceRange {...PriceRangeProps} />
      </Drawer>
    </Box>
  );
};
