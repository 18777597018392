import React from "react";
import { useFastTrackInquiryItemsTools } from "../useFastTrackInquiryItemsTools";
import { AstraWideDrawer } from "../../../components/astraWideDrawer/AstraWideDrawer";
import { useRouteMatch } from "react-router-dom";
import { baseUrl } from "../baseUrl";
import { AddItem } from "../../../reusable-containers/AddItem/AddItem";
import { useGetFastTrackInquiryQuery } from "../../../slices/fastTrackInquiries/api";
import { ItemDetails } from "../../../reusable-containers/ItemDetails";

export const Drawers = () => {
  const { inquiryId, itemId, closeDrawer } = useFastTrackInquiryItemsTools();
  const addItemMatcher = useRouteMatch(`${baseUrl}/${inquiryId}/add-item`);

  console.log("itemId", itemId);

  const { data: fastTrackInquiry } = useGetFastTrackInquiryQuery({
    fastTrackInquiryId: inquiryId,
  });

  const addItemDrawerOpen = !!addItemMatcher;
  const itemDetailsDrawerOpen = typeof itemId === "number";

  if (!fastTrackInquiry) {
    return null;
  }

  return (
    <>
      <AstraWideDrawer open={addItemDrawerOpen} close={closeDrawer}>
        <AddItem
          fastTrackInquiryId={inquiryId}
          assignmentId={fastTrackInquiry.assignmentId}
          closeDrawer={closeDrawer}
        />
      </AstraWideDrawer>
      <AstraWideDrawer open={itemDetailsDrawerOpen} close={closeDrawer}>
        {itemDetailsDrawerOpen && (
          <ItemDetails
            inquiryId={inquiryId}
            itemId={itemId}
            closeDrawer={closeDrawer}
          />
        )}
      </AstraWideDrawer>
    </>
  );
};
