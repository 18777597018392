import { GridSortModel } from "@mui/x-data-grid";

export type Noop = () => void;

export type RouteMatchId = {
  id: string;
};

export type RouteState =
  | Partial<{
      restoreParams: boolean;
      justGoBack: boolean;
      scrollToTop: boolean;
    }>
  | undefined;

export const matchOptions = ["A", "B"] as const;
export type MatchOption = typeof matchOptions[number];

export type MessageResponseDto = {
  message: string;
};

export type AssignMessageResponseDto = MessageResponseDto & {
  additional: string;
};

export type PaginatedRequestDto = {
  page: number;
  pageSize: number;
};

export type SearchableRequestDto = {
  search?: string;
};

export type SortableRequestDto = {
  sortModel?: GridSortModel;
};

export type SearchablePaginatedRequestDto = SearchableRequestDto &
  PaginatedRequestDto;

export type SortableSearchablePaginatedRequestDto =
  SearchablePaginatedRequestDto & PaginatedRequestDto & SortableRequestDto;

export type PaginatedResponseDto<Data> = {
  count: number;
  data: Array<Data>;
};

export type PaginationData = {
  page: number;
  pageSize: number;
};

export const inquiryStatuses = [
  "new",
  "underProcess",
  "submitted",
  "confirmed",
  "ordering",
  "inDelivery",
  "delivered",
  "lost",
] as const;
export type InquiryStatus = typeof inquiryStatuses[number];

export const inquiryPriorities = ["normal", "high"] as const;
export type InquiryPriority = typeof inquiryPriorities[number];

export const discountType = ["amount", "rate"] as const;
export type DiscountType = typeof discountType[number];

export const inquirySchoolStatus = [
  "new",
  "inProgress",
  "pendingApproval",
  "readyForSending",
  "sentToAstra",
  "cancelled",
] as const;
export type InquirySchoolStatus = typeof inquirySchoolStatus[number];

export const departmentInquirySchoolStatuses = [
  "new",
  "inProgress",
  "pendingApproval",
  "needReviewByHead",
  "readyForSending",
  "approvedByHead",
  "approvedByPrincipal",
  "sentToAstra",
  "cancelled",
] as const;
export type DepartmentInquirySchoolStatus =
  typeof departmentInquirySchoolStatuses[number];

export const assignmentSchoolStatuses = [
  "new",
  "inProgress",
  "needReviewByTeacher",
  "readyForSending",
  "approvedByTeacher",
  "approvedByHead",
  "sentToPrincipal",
  "approvedByPrincipal",
  "sentToAstra",
  "cancelled",
] as const;
export type AssignmentSchoolStatus = typeof assignmentSchoolStatuses[number];

export const requestedItemSchoolStatuses = [
  "new",
  "approvedByTeacher",
  "needReviewByTeacher",
  "approvedByHead",
  "approvedByPrincipal",
  "cancelled",
] as const;
export type RequestedItemSchoolStatus =
  typeof requestedItemSchoolStatuses[number];

export const requestedItemSearchRequestStatus = [
  "open",
  "inProgress",
  "closed",
] as const;
export type RequestedItemSearchRequestStatus =
  typeof requestedItemSearchRequestStatus[number];

export const deliveryTerms = ["DDP", "CIF"] as const;
export type DeliveryTerms = typeof deliveryTerms[number];

export type StatusColor = {
  border?: string;
  color: string;
  backgroundColor?: string;
};

export type Orientation = "portrait" | "landscape";
