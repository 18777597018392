import React from "react";
import { useTranslation } from "react-i18next";
import { useDisplayPrice } from "../../utils/useDisplayPrice";
import { Box, Divider, styled, Typography } from "@mui/material";
import { ListDataCell } from "./ListDataCell";
import { colors } from "../../theme/astra/colors";
import { ReactComponent as WalletIcon } from "../../assets/newDesignItems/wallet.svg";

export type BudgetProps = {
  spent?: number | null;
  assigned?: number | null;
  currency: string;
  productsWithoutPrice?: number | null;
  totalProducts: number;
};

export const Budget = ({
  spent,
  assigned,
  currency,
  productsWithoutPrice,
  totalProducts,
}: BudgetProps) => {
  const { t } = useTranslation("components");
  const displayPrice = useDisplayPrice();

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        marginLeft: "40px",
      }}
    >
      <Box
        sx={{
          border: "1px solid #E0E8F1",
          borderRadius: "50px",
          width: "40px",
          height: "40px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginRight: "16px",
        }}
      >
        <WalletIcon />
      </Box>
      <ListDataCell
        label={t("budget.spentBudget")}
        value={displayPrice(spent) ?? "0"}
        currency={currency}
      />
      {productsWithoutPrice && productsWithoutPrice > 0 ? (
        <>
          <Typography
            component="span"
            sx={{
              color: colors.darkBlue,
              fontSize: "12px",
              lineHeight: "20px",
              marginLeft: "16px",
              marginRight: "16px",
            }}
          >
            +
          </Typography>
          <ListDataCell
            label={t("budget.productsWithoutPrice", {
              count: productsWithoutPrice,
            })}
            value={`${productsWithoutPrice}`}
          />
        </>
      ) : null}
      <StyledDivider orientation="vertical" />
      <ListDataCell
        label={t("budget.assignedBudget")}
        value={displayPrice(assigned) ?? t("na", { ns: "common" })}
        currency={currency}
      />
      <StyledDivider orientation="vertical" />
      <ListDataCell
        label={t("budget.totalProducts")}
        value={`${totalProducts}`}
      />
    </Box>
  );
};

const StyledDivider = styled(Divider)({
  height: "40px",
  borderColor: colors.dustBlue5,
  marginLeft: "16px",
  marginRight: "16px",
});
