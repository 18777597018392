import React from "react";
import { useRouteMatch } from "react-router-dom";
import { EditDepartment } from "./EditDepartment";
import { ItemDetails } from "./ItemDetails";
import { EditAssignment } from "./EditAssignment";
import { AstraWideDrawer } from "../../../components/astraWideDrawer/AstraWideDrawer";
import { AddItem } from "./AddItem";
import { useSchoolInquiryItemsTools } from "../useSchoolInquiryItemsTools";

export const Drawers = () => {
  const { baseUrl, closeDrawer, itemId } = useSchoolInquiryItemsTools();
  const editDepartmentMatcher = useRouteMatch(`${baseUrl}/edit/department`);
  const editAssignmentMatcher = useRouteMatch(`${baseUrl}/edit/assignment`);
  const addItemMatcher = useRouteMatch(`${baseUrl}/add/item`);

  return (
    <>
      <AstraWideDrawer open={!!editDepartmentMatcher} close={closeDrawer}>
        <EditDepartment />
      </AstraWideDrawer>
      <AstraWideDrawer open={!!editAssignmentMatcher} close={closeDrawer}>
        <EditAssignment />
      </AstraWideDrawer>
      <AstraWideDrawer open={!!itemId} close={closeDrawer}>
        <ItemDetails />
      </AstraWideDrawer>
      <AstraWideDrawer open={!!addItemMatcher} close={closeDrawer}>
        <AddItem />
      </AstraWideDrawer>
    </>
  );
};
