import { ItemInquiryStatus, ItemOrderStatus } from "../../slices/items/types";
import { InquiryStatus } from "../../types";

export default {
  users: {
    title: "Users",
    add: "Add user",
    addTitle: "Add User",
    edit: "Edit user",
    editTitle: "Edit User",
    changePasswordFor: "Change password for {{name}}",
    addSuccess: "User has been added",
    editSuccess: "User has been edited",
    changeActivitySuccess: "User activity has been changed",
    deleteSuccess: "User has been removed",

    actions: {
      edit: {
        label: "Edit",
      },
      changePassword: {
        label: "Change password",
      },
      changeActivity: {
        confirmationTitle: "Are you sure you want to change the user activity?",
        confirmationDescription: "You will be able to undo this action",
      },
      delete: {
        label: "Delete",
        confirmationTitle: "Are you sure you want to delete the user?",
        confirmationDescription: "You won't be able to undo this action",
      },
    },

    fields: {
      email: "Email",
      password: "Password",
      firstName: "First name",
      lastName: "Last name",
      school: "School",
      title: "Title",
      role: "Role",
      active: "Active",
      status: "Status",
      inquiries: "Inquiries",
    },

    statusValues: {
      active: "Active",
      inactive: "Inactive",
    },

    errors: {
      refetch: "Unable to load users, try again",
      add: "Something went wrong adding a user, try again",
      edit: "Something went wrong editing the user, try again",
      delete: "Something went wrong deleting the user, try again",
    },
  },

  countries: {
    title: "Countries",

    fields: {
      defaultAstraEntity: "Default Astra Entity",
    },
  },

  suppliers: {
    title: "Suppliers",
  },

  departments: {
    title: "Departments",

    fields: {
      id: "ID",
      active: "Active",
      public: "Public",
      relevancesCategory: "Category relevance",
      relevancesTag: "Tag relevance",
      addRelevance: "Add relevance",
      relevance: "Relevance",
      category: "Category",
      tag: "Tag",
    },
  },

  commoditycodes: {
    title: "Commodity codes",
  },

  astraEntities: {
    title: "Astra entities",
    addAstraEntity: "Add Astra entity",
    editAstraEntity: "Edit Astra entity",
    logo: "{{name}} logo",

    actions: {
      edit: {
        label: "Edit",
      },
      delete: {
        label: "Delete",
        confirmationTitle: "Are you sure you want to delete this Astra entity?",
        confirmationDescription: "You won't be able to undo this action",
      },
    },

    columns: {
      address: "Address",
    },

    fields: {
      logo: {
        label: "Logo",
        pick: "Pick a logo",
      },
      name: "Name",
      code: "Code",
      addressLine: "Address line 1",
      addressLine2: "Address line 2",
      state: "State",
      city: "City/Town",
      zipCode: "ZIP Code",
      country: "Country",
      phone: "Phone",
      email: "Email",
      website: "Website",
      crNumber: "CR Number",
    },

    successes: {
      add: "Astra entity has been added",
      edit: "Astra entity has been edited",
      delete: "Astra entity has been removed",
    },

    errors: {
      add: "Something went wrong adding an Astra entity, try again",
      edit: "Something went wrong editing the Astra entity, try again",
      delete: "Something went wrong deleting the Astra entity, try again",
      refetch: "Unable to load Astra entities, try again",
    },
  },

  categories: {
    title: "Categories",
  },

  schools: {
    title: "Schools",
    add: "Add school",
    edit: "Edit school",

    changeActiveStatusTitle: "Are you sure you want to change active status?",
    changeActiveStatusDescription: "You will be able to undo this action",
    confirmDeleteTitle: "Are you sure you want to delete the school?",
    confirmDeleteDescription: "You won't be able to undo this action",

    fields: {
      name: "Name",
      country: "Country",
      active: "Active",
      contactPersonName: "Contact person name",
      address: "Address",
      phoneNumber: "Phone number",
      email: "Email",
      currency: "School currency",
    },

    successes: {
      add: "School has been added",
      edit: "School has been edited",
      delete: "School has been removed",
      changeActiveStatus: "School's activation status has been changed",
    },

    errors: {
      add: "Something went wrong adding a school, try again",
      edit: "Something went wrong editing the school, try again",
      delete: "Something went wrong deleting the school, try again",
      refetch: "Unable to load schools, try again",
      changeActiveStatus:
        "Something went wrong changing activation status for the school, try again",
    },
  },

  inquiryDetails: {
    title: "Departments Inquiries",
    namePlaceholder: "Inquiry",
    basicDetails: "Basic details",
    departments: "Departments",
    inquiryManagers: "Inquiry managers",
    orderManagers: "Order managers",
    codetails: "Commercial offer details",
    customFields: "Custom fields",
    user: "User",

    principalNote: {
      title: "Principal note",
      noteSubtitle: "Note",
    },

    details: {
      shippingCost: "Delivery cost",
      shippingCostCurrency: "Delivery cost currency",
    },

    groups: {
      details: {
        label: "Details",
        tooltip: "Basic details",
      },
      departments: {
        label: "Departments",
        tooltip: "List of Departments",
      },
      co: {
        label: "CO",
        tooltip: "List of Commercial Offers",
      },
      po: {
        label: "PO",
        tooltip: "List of Purchase Orders",
      },
      mo: {
        label: "MO",
        tooltip: "List of Main Orders",
      },
      dn: {
        label: "DN",
        tooltip: "List of Delivery Notes",
      },
    },

    actions: {
      lose: {
        label: "Lose",
        confirmationTitle:
          "Are you sure you want to set department's status to lost?",
        confirmationDescription:
          "You'll be able to change department's status again by changing one of items' status or by adding one",
      },
    },

    export: "Export items",

    generateCommercialOffer: {
      button: "CO",
      tooltip: "Generate commercial offer for all departments",
      confirmationTitle: "Commercial offer generation",
      confirmationDescription:
        "You are about to generate a Commercial offer with {{count}} items in submitted status. ",
      additionalDescription:
        "Please, do not forget that you also have {{count}} items in new and matched statuses.",
      notFullCoDetails: "Please, fill in all Commercial Offer details first",
      noAstraEntitySet: "Please, set an Astra entity for this inquiry first",
      goToDetails: "Go to details",

      existingFields: {
        index: "Item No",
        department: "Department",
        supplier: "Supplier",
        supplierProductCode: "Product ID",
        description: "Description",
        quantity: "Quantity",
        unitCost: "Unit Cost",
        totalAmount: "Total Amount",
      },
    },

    commercialOfferPreview: {
      title: "Commercial offer preview",
      proceedButtonText: "Download PDF",
    },

    deliveryNotePreview: {
      title: "Delivery note preview",
      proceedButtonText: "Download PDF",
    },

    purchaseOrderPreview: {
      title: "Purchase order preview",
      proceedButtonText: "Download PDF",
    },

    mainOrderPreview: {
      title: "Main order preview",
      proceedButtonText: "Download PDF",
    },

    generateDN: {
      button: "DN",
      tooltip: "Generate delivery note for all departments",
      confirmationTitle: "Delivery note generation",
      confirmationDescription:
        "You are about to generate a Delivery note with {{count}} items in warehouse status.",
    },

    fields: {
      name: "Name",
      status: "Status",
    },

    successes: {
      lost: "Department's status changed to lost",
    },

    errors: {
      lose: "Something went wrong changing the status to lost, try again",
    },
  },

  inquiries: {
    title: "Astra INQs view",
    add: "Add inquiry",
    edit: "Edit inquiry",
    editCO: "Edit inquiry CO details",
    lose: "Lose",

    actions: {
      lose: {
        label: "Lose",
        confirmationTitle:
          "Are you sure you want to set inquiry's status to lost?",
        confirmationDescription:
          "You'll be able to change inquiry's status again by changing one of items' status or by adding one",
      },
      delete: {
        label: "Delete",
        confirmationTitle: "Are you sure you want to delete the inquiry?",
        confirmationDescription: "You won't be able to undo this action",
      },
    },

    fields: {
      name: "Name",
      status: "Status",
      priority: "Priority",
      dueDate: "Due date",
      school: "School",
      principal: "Principal",
      createdDate: "Created date",
      departments: "Departments",
      shippingCost: "Delivery cost",
      shippingCostCurrency: "Delivery cost currency",
      shippingCostMessage: "Delivery cost comment",
      paymentTerms: "Payment terms",
      deliveryTerms: "Delivery terms",
      leadTime: "Lead time",
      validityOffer: "Validity offer",
      budget: "Budget",
      budgetCurrency: "Budget currency",
      discountType: "Type of discount",
      discountValue: "Discount",
      discountCurrency: "Discount currency",
      inquiryManagers: "Inquiry managers",
      orderManagers: "Order managers",
      astraEntity: "Astra entity",
      customFields: {
        plural: "Custom fields",
        singular: "Field",
        add: "Add a custom field",
        label: "Field",
      },
    },

    filters: {
      status: "Status",
    },

    statuses: {
      new: "New",
      underProcess: "Under process",
      submitted: "Submitted",
      confirmed: "Confirmed",
      ordering: "Ordering",
      inDelivery: "In delivery",
      delivered: "Delivered",
      lost: "Lost",
    } as Record<InquiryStatus, string>,

    successes: {
      add: "Inquiry has been added",
      edit: "Inquiry has been edited",
      delete: "Inquiry has been removed",
      lost: "Inquiry's status changed to lost",
    },

    errors: {
      refetch: "Unable to load inquiries, try again",
      add: "Something went wrong adding a inquiry, try again",
      edit: "Something went wrong editing the inquiry, try again",
      delete: "Something went wrong deleting the inquiry, try again",
      lose: "Something went wrong changing the status to lost, try again",
    },
  },

  fastTrackInquiries: {
    title: "Fast-Track Inquiry",
    backToInquiries: "Back to Inquiries",

    sentToAstra: "Sent to Astra",
    spentBudget: "Spent Budget",
    products: "Products",
    productsWithoutPrice_one: "Product Without Price",
    productsWithoutPrice_other: "Products Without Price",
    date: "Date",

    totalSpent: "Total Spent",
    totalAmountSummary: "Total amount summary",

    itemsSelected_one: "{{count}} product selected",
    itemsSelected_other: "{{count}} products selected",

    controls: {
      sendToAstra: {
        label: "Send to Astra",
        confirmation: {
          title:
            "You will assume full responsibility for all products requested through the Fast-Track Inquiry option.",
          description:
            "Please note that there is no approval process, and the inquiry will be sent directly to ITS Astra. I understand and accept full responsibility for the requested items.",
          checkbox:
            "I understand and accept full responsibility for the ordered products",
          proceed: "I Agree",
        },
      },
      removeFastTrackInquiry: "Remove Fast-Track Inquiry",
    },

    listControls: {
      addItem: "Add product",
      moveToFavorites: "Move to Favorites",
      remove: "Remove",
    },

    columns: {
      image: "Image",
      productCode: "Code",
      productName: "Name",
      quantity: "Quantity",
      retailPrice: "Retail Price",
      totalAmount: "Total Amount",
    },
  },

  schoolInquiries: {
    title: "School INQs view",
    schoolTeamTitle: "Inquiries",
    add: "Add inquiry",
    edit: "Edit inquiry",
    countLabel_one: "Inquiry in total",
    countLabel_other: "Inquiries in total",
    noInquiries: "No inquiries",
    searchLabel: "Search inquiries",
    reviewNow: "Review Now",
    newInquiry: "New Inquiry",

    budgetInquiryTitle: "Budget Spent / Planned",
    spentBudget: "Spent Budget",
    assignedBudget: "Assigned Budget",
    productsWithoutPrice_one: "Product Without Price",
    productsWithoutPrice_other: "Products Without Price",

    inquiryFilters: {
      active: "Active",
      completed: "Completed",
      cancelled: "Cancelled",
      all: "All inquiries",
      fastTrackHistory: "Fast-Track History",
    },

    successes: {
      add: "Inquiry has been added",
      edit: "Inquiry has been edited",
      delete: "Inquiry has been removed",
      lost: "Inquiry's status changed to lost",
    },

    errors: {
      add: "Something went wrong adding a inquiry, try again",
      edit: "Something went wrong editing the inquiry, try again",
      delete: "Something went wrong deleting the inquiry, try again",
      lose: "Something went wrong changing the status to lost, try again",
    },

    fields: {
      school: "School",
      principal: "Principal",
      name: "Inquiry name",
      budgetHint: "You can specify an inquiry budget",
      isBudget: "Inquiry budget",
      budget: "Budget",
      budgetCurrency: "Budget currency",
      totalItems: "Total products",
      totalAmount: "Total amount",
      status: "Status",
    },

    actions: {
      delete: {
        label: "Delete",
        confirmationTitle: "Are you sure you want to delete this inquiry?",
        confirmationDescription: "You won't be able to undo this action",
      },
    },
  },

  schoolInquiryDetails: {
    loadingTitle: "School inquiry details",
    addDepartment: "Add department",
    editDepartment: "Edit department",
    addAssignment: "Add assignment",
    editAssignment: "Edit assignment for",
    addItem: "Add product",
    allItems: "All products",
    list: "{{name}} list",
    addTeacher: "Add Teacher",
    viewAllItems: "View all products",
    listOfAllItems: "List of all products",
    submitEdit: "Save Changes",
    backToInquiries: "Back to Inquiries",
    productsWithoutPrice_one: "Product Without Price",
    productsWithoutPrice_other: "Products Without Price",

    addItemForm: {
      addFromEshopLabel: "Add from E-shop",
      addManuallyLabel: "or add manually",
    },

    inquiryControls: {
      submit: {
        label: "Send to Astra",
        confirmationTitle: "Are you sure you want to submit the inquiry?",
      },
      cancel: {
        label: "Cancel",
        confirmationTitle: "Are you sure you want to cancel the inquiry?",
      },
      exportItems: {
        label: "Export products",
      },
      drawer: {
        label: "Send to Astra",
        finilizeAllFirst: "Finalise lists from all departments first. ",
        finilizeAllFirstDescription:
          "Note that the Inquiry can be send to Astra only after lists from all departments have been finalised",
        errorWhileSent:
          "An error occurred while sending the request. Please try again",
        fields: {
          paymentTerms: "Payment terms",
          deliveryTerms: "Delivery terms",
          note: "Note",
        },
      },
    },

    departmentControls: {
      cancel: {
        label: "Cancel",
        confirmationTitle:
          "Are you sure you want to cancel the department inquiry?",
      },
      needReview: {
        label: "Need review",
        confirmationTitle:
          "Are you sure you want to send the department inquiry to review?",
      },
      edit: "Edit",
      delete: {
        label: "Delete",
        confirmationTitle:
          "Are you sure you want to delete the department inquiry?",
        buttonLabel: "Delete Department",
      },
      approve: {
        label: "Approve",
        newLabel: "Finalise Order",
        confirmationTitle:
          "Are you sure you want to approve the department inquiry?",
        approveAllFirst: "Approve all products first",
        approveItemsFirst:
          "The order can be finalized for purchase only after all products have been approved",
      },
      sendToPrincipal: {
        label: "Send to {{principalTitle}}",
        confirmationTitle:
          "Are you sure you want to send the inquiry to {{principalTitle}}?",
        approveAllFirst: "Approve all products first",
        approveAllFirstButtonLabel: "Open List of Products",
        checkListsFirst:
          "The list can be send to {{principalTitle}} for review only after all products have been approved. Please, check individual lists first",
      },
    },

    assignmentControls: {
      edit: "Edit",
      delete: {
        label: "Delete",
        buttonLabel: "Delete Assignment",
        confirmationTitle: "Are you sure you want to delete the assignment?",
      },
      cancel: {
        label: "Cancel",
        confirmationTitle: "Are you sure you want to cancel the assignment?",
      },
      approve: {
        label: "Approve List",
        confirmationTitle: "Are you sure you want to approve the assignment?",
        approveAllFirst: "Approve all products first",
        approveItemsFirst:
          "The list can be approved only after all products in table below have been approved",
      },
      sendToReview: {
        label: "Need review",
        confirmationTitle:
          "Are you sure you want to send assignment to review?",
      },
      sendToHead: {
        label: "Send to {{headOfDepartmentTitle}}",
        goToListLabel: "Open List of Products",
        confirmationTitle:
          "Are you sure you want to approve the {{teacherTitle}} assignment?",

        approveAllFirst: "Approve all products first",
        approveItemsFirst:
          "The list can be approved only after all products in table below have been approved",
        sendToHeadOnlyAfterApproving:
          "The list can be send to {{headOfDepartmentTitle}} for review only after all products have been approved",
      },
    },

    departmentFields: {
      department: "Department",
      departmentTeacher: "Department teacher",
      budget: "Budget",
      budgetCurrency: "Budget currency",
    },

    departmentColumns: {
      image: "Image",
      supplier: "Supplier",
      productCode: "Product code",
      productName: "Product name",
      sourceList: "Source List",
      retailPrice: "Retail price",
      status: "Status",
      quantity: "Quantity",
      totalAmount: "Total amount",
    },

    itemActions: {
      approve: {
        label: "Approve",
      },
      sendToReview: {
        label: "Need review",
        confirmationTitle: "Leave the review comment for item",
        placeholder:
          "Please provide your reasons for returning the item for review",
        reminder:
          "Don't forget to put the whole list to review after you've done with the products",
        submit: {
          label: "Add Comment & Set Status",
          tooltip: "Add comment and set item's status to Need Review",
        },
        submitWithoutComment: {
          label: "Set status w/o comment",
          tooltip: "Set item's status to Need Review without adding a comment",
        },
      },
      cancel: {
        label: "Cancel",
        confirmationTitle: "Cancel item",
        confirmationDescription:
          "Are you sure you want to cancel this item? You will not be able to undo this action",
      },
      delete: {
        label: "Delete",
        confirmationTitle: "Delete item",
        confirmationDescription:
          "Are you sure you want to delete this item? You will not be able to undo this action",
      },
    },

    reviewComments: {
      title: "Review comments for",
      placeholder: "Comment",
      addComment: "Add comment",
    },

    itemsTableActions: {
      addItem: "Add product",
      approve: {
        label: "Approve products",
        confirmationTitle:
          "Are you sure you want to approve selected products in this table?",
      },
      approveAllItems: {
        label: "Approve all products",
        confirmationTitle: "Approve all products",
        confirmationDescription:
          "Are you sure you want to approve all products in this table?",
      },
    },

    assignmentFields: {
      teacher: "Teacher",
      budget: "Budget",
      budgetCurrency: "Budget currency",
    },

    assignmentCells: {
      status: "Request status",
      teacher: "Teacher",
      budget: "Budget",
      totalItems: "Total products",
      totalAmount: "Total amount",
    },

    itemDetailsFields: {
      name: "Name",
      price: "Price",
      description: "Description",
      packSize: "Pack size",
      color: "Color",
      country: "Country",
      weight: "Weight",
      volume: "Volume",
      sizeW: "Size W",
      sizeD: "Size D",
      sizeH: "Size H",
      categories: "Categories",
      tags: "Tags",
      matches: "Matches",
      images: "Images",
    },

    total: {
      totalItems: "Total products",
      totalAmount: "Total amount",
    },

    errors: {
      addDepartment: "Something went wrong adding a deparment, try again",
      addAssignment: "Something went wrong adding an assignment, try again",
      editAssignment: "Something went wrong editing the assignment, try again",
    },
  },

  products: {
    title: "Products",
    productLabel: "Product",
    details: "Details",
    noProducts: "No products found",
    add: "Add product",
    edit: "Edit product",
    viewMore: "View more",
    emptyField: "Not specified",
    export: "Download",
    NA: "N/A",
    imgAlt:
      "Image of product with supplier product code: {{supplierProductCode}}",
    countLabel_one: "product in total",
    countLabel_other: "products in total",

    filters: {
      supplier: "By supplier",
      availability: "Availability",
    },

    actions: {
      delete: {
        label: "Delete",
        confirmationTitle: "Are you sure you want to delete this product?",
        confirmationDescription: "You won't be able to undo this action",
      },
      export: {
        confirmationTitle: "Products export",
        confirmationDescription:
          "Please, remember the export limit of 5,000 products. Specify the search if you want to get other products.",
      },
      changeAvailability: {
        makeUnavailable: "Make unavailable",
        makeAvailable: "Make available",
        confirmationTitle:
          "Are you sure you want to change the product availability?",
        confirmationDescription: "You will be able to undo this action",
      },
    },

    units: {
      weight: "kg",
      volume: "m³",
      WDH: "cm",
    },

    fields: {
      supplierProductCode: "Supplier product code",
      matches: {
        singular: "Match",
        plural: "Matches",
        add: "Add match",
      },
      images: {
        singular: "Image",
        plural: "Images",
        add: "Add image",
        choose: "Choose an image",
      },
      name: "Name",
      productCode: "Product code",
      description: "Description",
      category: "Category",
      packSize: "Pack size",
      color: "Color",
      commodityCode: "Commodity code",
      replacementCommodityCode: "Replacement commodity code",
      supplierPrice: "Supplier price",
      supplierCurrency: "Supplier currency",
      retailPrice: "Retail price",
      retailCurrency: "Retail currency",
      weight: "Weight",
      volume: "Volume",
      sizeW: "Size W",
      sizeD: "Size D",
      sizeH: "Size H",
      supplier: "Supplier",
      country: "Country",
      WDH: "WDH",
      categories: "Categories",
      tags: "Tags",
      popularity: "Popularity",
      availability: "Available",
      schoolLevel: "School level",
      inventory: "Inventory",
    },

    successes: {
      add: "Product has been added",
      edit: "Product has been edited",
      changeAvailability: "Product availability has been changed",
      delete: "Products has been removed",
    },

    errors: {
      add: "Something went wrong adding a product, try again",
      edit: "Something went wrong editing the product, try again",
      changeAvailability:
        "Something went wrong changing the product availability, try again",
      delete: "Something went wrong deleting the product, try again",
      refetch: "Unable to load products, try again",
    },
  },

  itemDetails: {
    title: "Item details",
    editItem: "Edit item",
    editProduct: "Edit product",
    assign: "Assign",
    quickAssign: "Quick assign",
    cantAssignPrice: "Supplier price and/or Retail price are empty",
    unassign: "Unassign",
    viewDetails: "View details",
    noAssignedProduct: "No assigned product",
    matchA: "Match A",
    matchB: "Match B",
    noMatches: "No matches",
    itemLabel: "Item",
    matchedProductLabel: "Matched product",
    searchLabel: "Find a product to assign",
    noResults: "No results",
    assignFormTitleShort: "Assign",
    assignFormTitle: "Assign item",
    inquiryStatus: "Inquiry status",
    orderStatus: "Order status",
    purchaseOrder: "Purchase order",
    mainOrder: "Main order",
    viewProduct: "View product",

    openNew: "Total {{count}} new. ",
    openNewWithCurrent: "{{current}} of {{count}} new. ",
    nextNew: "Next new.",

    assignFormFields: {
      quantity: "Quantity",
      color: "Color",
      match: "Match",
    },

    successes: {
      changeItemInquiryStatus: "Inquiry status has been changed",
      changeItemOrderStatus: "Order status has been changed",
      assign: "Product has been assigned",
    },

    errors: {
      refetch: "Unable to load items, try again",
      changeItemOrderStatus: "Error during changing order status, try again",
      changeItemInquiryStatus:
        "Error during changing inquiry status, try again",
      wrongStatus: "Wrong status was selected, try again",
      assing: "Unable to assign, try again",
    },
  },

  items: {
    title: "Items",
    add: "Add item",
    edit: "Edit item",
    assignPurchaseOrder: "Assign purchase order",
    assignMainOrder: "Assign main order",
    matching: "Matching",
    ordering: "Ordering",
    importFile: "Import items",

    importItems: {
      confirmation: {
        title: "Import items",
        description:
          "You are about to import items. Please, do not forget about the column's order",
      },
    },

    inquiryStatistics: "Inquiry Statistics",
    orderStatistics: "Order Statistics",

    toolbarActions: {
      assignPurchaseOrder: "Assign PO",
      assignMainOrder: "Assign MO",
      bulkSubmit: {
        label: "Bulk move from Matched to Submitted",
        title: "Bulk submit",
        description:
          "You are about to change the status of all selected items in matched status to submitted status",
      },
    },

    actions: {
      assignPurchaseOrder: {
        label: "Assign PO",
      },
      assignMainOrder: {
        label: "Assign MO",
      },
      delete: {
        label: "Delete",
        confirmationTitle: "Are you sure you want to delete the item?",
        confirmationDescription: "You won't be able to undo this action",
      },
    },

    fields: {
      name: "Name",
      productName: "Product name",
      item: "Item",
      supplier: "Supplier",
      product: "Product",
      inquiryStatus: "Inquiry status",
      orderStatus: "Order status",
      supplierProductCode: "Code",
      match: "Match",
      quantity: "Quantity",
      purchaseOrder: "Purchase order",
      mainOrder: "Main order",
      customerLineNumber: "Customer's ID",
      includedInCommercialOffer: "Included in Commercial Offer",
    },

    filters: {
      inquiryStatus: "Inquiry status",
      orderStatus: "Order status",
    },

    statuses: {
      inquiry: {
        new: "New",
        matched: "Matched",
        submitted: "Submitted",
        confirmed: "Confirmed",
        delivered: "Delivered",
        discontinued: "Discontinued",
        canceled: "Canceled",
      } as Record<ItemInquiryStatus, string>,

      order: {
        new: "New",
        placed: "Placed",
        confirmed: "Confirmed",
        readyToDispatch: "Ready to dispatch",
        shipped: "Shipped",
        clearance: "Clearance",
        warehouse: "Warehouse",
        delivered: "Delivered",
        discontinued: "Discontinued",
      } as Record<ItemOrderStatus, string>,
    },

    successes: {
      add: "Item has been added",
      edit: "Item has been edited",
      delete: "Item has been removed",
      changeItemInquiryStatus: "Inquiry status has been changed",
      changeItemOrderStatus: "Order status has been changed",
      assignItemsPurchaseOrder: "Purchase order has been assigned",
    },

    errors: {
      refetch: "Unable to load items, try again",
      add: "Something went wrong adding a item, try again",
      edit: "Something went wrong editing the item, try again",
      delete: "Something went wrong deleting the item, try again",
      wrongStatus: "Wrong status was selected, try again",
      changeItemOrderStatus: "Error during changing order status, try again",
      changeItemInquiryStatus:
        "Error during changing inquiry status, try again",
      assignItemsPurchaseOrder: "Something went wrong assigning purchase order",
      assignMainOrder: "Something went wrong assigning main order",
    },
  },

  newitems: {
    title: "New items view",
    fields: {
      supplier: "Supplier",
      supplierProductCode: "Code",
      name: "Name",
      inqView: "INQ's view",
      inquiry: "Inquiry",
      department: "Department",
    },

    inqView: {
      astra: "Astra",
      school: "School",
    },

    filters: {
      inqView: "INQ's view",
    },

    errors: {
      refetch: "Unable to load new items, try again",
    },
  },

  newSearchRequestItems: {
    title: "Items requests",
    navigationTitle: "Items requests",
    refetch: "Unable to load new items, try again",

    columns: {
      searchRequestStatus: "Status",
      assignee: "Assignee",
      supplier: "Supplier",
      supplierProductCode: "Code",
      name: "Name",
      quantity: "Quantity",
      school: "School",
    },

    details: {
      searchRequestStatus: "Status",
      assignee: "Assignee",
      supplier: "Supplier",
      supplierProductCode: "Code",
      quantity: "Quantity",
      teacherName: "Teacher",
      teacherEmail: "Teacher's email",
      school: "School",
      inquiry: "Inquiry",
      department: "Department",
      image: "Image",
      comment: "Comment",
    },
  },

  currencies: {
    title: "Currencies",
    from: "From",
    to: "To",
    rate: "Rate",
    errors: {
      refetch: "Unable to load currencies, try again",
    },
  },

  comments: {
    title: "Comments history",
    placeholder: "Comment",
    submit: "Submit",
    loadMore: "Load More",
    deleteConfirmationTitle: "Are you sure you want to delete the comment?",
    deleteConfirmationDescription: "You won't be able to undo this action",
    tooShort: "Comment must be longer than {{minLength}} character",
    tooLong: "Comment must be up to {{maxLength}} characters",
    successes: {
      deleted: "Comment has been deleted",
      add: "Comment has been added",
    },
    errors: {
      refetch: "Unable to load comments, try again",
      deleted: "Something went wrong deleting a comment, try again",
      add: "Something went wrong adding a comment, try again",
    },
  },

  commercialOffers: {
    title: "Commercial offers",
    detailsTitle: "Commercial offer details",
    fields: {
      revision: "Revision",
      CONumber: "CO number",
      date: "Date",
      originalFile: "CO file",
      uploadedFile: "Signed CO file",
    },
    successes: {
      deleted: "Commercial offer has been deleted",
      add: "Commercial offer has been added",
      upload: "Signed file for commercial offer has been uploaded",
    },
    errors: {
      refetch: "Unable to load commercial offers, try again",
      deleted: "Something went wrong deleting a commercial offer, try again",
      add: "Something went wrong adding a commercial offer, try again",
      upload:
        "Something went wrong uploading signed file to a commercial offer, try again",
    },
    actions: {
      upload: {
        label: "Upload signed CO",
      },
      delete: {
        label: "Delete",
        confirmationTitle:
          "Are you sure you want to delete the commercial offer?",
        confirmationDescription: "You won't be able to undo this action",
      },
    },
  },

  purchaseOrders: {
    title: "Purchase orders",
    addPurchaseOrder: "Add purchase order",
    detailsTitle: "Purchase order",

    confirmDeleteTitle: "Are you sure you want to delete the purchase order?",
    confirmDeleteDescription: "You won't be able to undo this action",

    fields: {
      id: "ID",
      inquiry: "Inquiry",
      date: "Date",
      referenceNumber: "Reference number",
      uploadedFile: "PO file",
      uploadedBy: "Uploaded by",
      items: "Items",
      value: "Value",
      recievedItemsByNH: "Received items by NH",
      valueOfRecievedItemsByNH: "Value of received items by NH",
      itemsCompletionRateByNH: "Items completions rate by NH",
      valueCompletionRateByNH: "Value completion rate by NH",
      recievedItemsByClient: "Received items by client",
      valueRecievedItemsByClient: "Value of received items by client",
      itemsCompletionRateByClient: "Items completion rate by client",
      valueCompletionRateByClient: "Value completion rate by client",
      valueRateByNH: "Value rate by NH",
      valueRateByClient: "Value rate by Client",
    },
    successes: {
      add: "Purchase order has been added",
      delete: "Purchase order has been deleted",
    },
    error: {
      add: "Something went wrong adding a purchase order, try again",
      delete: "Something went wrong deleting a purchase order, try again",
    },
  },

  mainOrders: {
    title: "Main orders",
    addMainOrder: "Add main order",
    detailsTitle: "Main order",

    confirmDeleteTitle: "Are you sure you want to delete the main order?",
    confirmDeleteDescription: "You won't be able to undo this action",

    fields: {
      id: "ID",
      inquiry: "Inquiry",
      date: "Date",
      supplier: "Supplier",
      referenceNumber: "Reference number",
      uploadedFile: "MO file",
      uploadedBy: "Uploaded by",
      uploadedFileKey: "Uploaded file key",
      items: "Items",
    },
    successes: {
      add: "Main order has been added",
      delete: "Main order has been deleted",
    },
    error: {
      add: "Something went wrong adding a main order, try again",
      delete: "Something went wrong deleting a main order, try again",
    },
  },

  deliveryNotes: {
    title: "Delivery notes",
    detailsTitle: "Delivery note details",
    fields: {
      id: "ID",
      DNNumber: "DN number",
      date: "Date",
      originalFile: "DN file",
      uploadedFile: "Signed DN file",
    },
    successes: {
      deleted: "Delivery note has been deleted",
      add: "Delivery note has been added",
      upload: "Signed file for delivery note has been uploaded",
    },
    errors: {
      refetch: "Unable to load delivery notes, try again",
      deleted: "Something went wrong deleting a delivery note, try again",
      add: "Something went wrong adding a delivery note, try again",
      upload:
        "Something went wrong uploading signed file to a delivery note, try again",
    },
    actions: {
      upload: {
        label: "Upload signed DN",
      },
      delete: {
        label: "Delete",
        confirmationTitle: "Are you sure you want to delete the delivery note?",
        confirmationDescription: "You won't be able to undo this action",
      },
    },
  },

  forgotpassword: {
    sendPin: "Send",
    enterEmail:
      "Share your email, and we'll dispatch the password recovery instructions straight to you",
    forgotpasswordLink: "Forgot Password?",
    forgotpassword: "Forgot Password",
    code: "Code",
    enterCode: "Enter the code",
    sendCodeMessage:
      "If your email address ({{email}}) exists in our database, you will receive a PIN code at your email address in a few minutes.",
    changePassword: "Change Password",
    resetSuccess: "Password has been updated",
    submitNewPassword: "Submit new password",
    rememberpassword: "Remember your password?",
    rememberpasswordLink: "Sign In",
  },

  shopCatalog: {
    title: "E-Shop",
    nothingFound: "Nothing found",
    perhapsYouWereLookingFor: "Perhaps you were looking for: ",
    or: " or ",
    thereAreResultsFor: "There are results for ",
    ifYouResetFilters: " if you reset filters",
    resetFiltersAndSearch: "Reset filters and search",

    currentList: {
      activeShoppingList: "Active shopping list",
      dueDate: "Due date",
      budget: "Budget",
      changeList: "Change list",
      changeTitle: "Change the list?",
      changeAbout:
        "Select the desired List from the dropdown below to keep adding new items to it. Stay organized with ease.",
      changeSubmit: "Change the List",
      goFastTrack: "Go Fast-Track",
    },

    filters: {
      tagsPlaceholder: "Add tags...",
      searchPlaceholder: "Search the item...",
      suggestedTags: "Suggested tags:",
      filter: "Filter",
      filters: "Filters",
      clearAll: "Clear all",
      schoolLevels: "School levels",
      priceRange: "Price range",
      suppliers: "Suppliers",
    },

    list: {
      imageAlt: "{{product}} image",
      noImageAlt: "{{product}} has no image",
      added: "Added to the list",
    },
  },

  shopDetails: {
    title: "Details - E-Shop",
    back: "Back to Catalogue",
    productCode: "Product Code",
    add: "Add product",
    productInfo: "Product Info",

    productNotFound: "Product Not Found",

    imageList: {
      imageAlt: "{{product}} image",
      previewAlt: "{{product}} preview",
      noImageAlt: "{{product}} has no image",
    },

    infoCells: {
      country: "Country",
      weight: "Weight",
      volume: "Volume",
      color: "Color",
      wdhCm: "WDH, cm",
      packSize: "Pack size",
    },
  },

  cart: {
    title: "Cart",
    totalItems: "Total items: {{items}} ({{units}} units)",
    yourBudget: "Your budget",
    totalAmount: "Total amount",
    reviewAndSubmit: "Review and submit",
    cartDisabled: "You have no active inquiries at the moment",
  },

  searches: {
    title: "Searches",
    longTitle: "Searches history",
    detailsTitle: "Search record details",

    tooltips: {
      url: "URL",
      details: "Details",
    },

    defaults: {
      priceFrom: "0.00",
      priceTo: "N/A",
    },

    filters: {
      from: "From",
      to: "To",
      school: "Schools",
      user: "Users",
      tags: "Tags",
      priceRange: "Price range",
      schoolLevels: "School levels",
    },

    columns: {
      date: "Date",
      user: "User",
      search: "Search",
      numberOfFilters: "Number of filters",
      numberOfResults: "Number of results",
      actions: "Actions",
      filters: "Filters",
    },
  },
  topSearch: {
    apply: "Apply",
    viewAll: "View All",
    products: "Products",
    tags: "Tags",
    noResult: "There are no results",
  },

  hints: {
    toggleHelp: "User guide",
    schoolInquiryDetails: {
      assignmentControls: {
        cancel: "The assignment will be cancelled",
        edit: "Editing of the budget of the assignment",
        delete: {
          available: "The assignment will be deleted",
          disabled:
            "You can't delete the assignment if a teacher added at least one product",
          own: "You can't delete your own assignment",
        },
        needReview: "Revision of the assignment by the teacher",
        needReviewSelf: "Revision of the own list",
        approve: {
          available: "Approval of the assignment",
          disabled:
            'The button will be available after approving all items. The request status should be "approved by teacher"',
        },
      },
      departmentControls: {
        cancel: "The department will be cancelled",
        delete: {
          available: "The department will be deleted",
          disabled:
            "You can't delete the department if the head of a department added a new participant or product",
        },
        needReview: "Revision of the product list by the head of department",
        edit: "Editing of a budget of the department",
        approve: {
          disabled:
            'The button will be available after approving all items. The department status should be "approved by head"',
          available: "Approval of the department",
        },
      },
      inquiryControls: {
        cancel: "The inquiry will be cancelled",
        exportItems:
          "The Excel file will be downloaded with information about active items in the inquiry",
        sendToAstra: {
          available: 'The inquiry will be sent to "ITS Astra"',
          disabled:
            "The button will be available after approving all departments",
        },
      },
      addDepartment: "New department for a head of department",
      allItems: "List of items added by all participants of the department ",
      ownList: "Own assignment",
      headList: "Head's assignment",
      teacherList: "Teacher's assignment",
      addButton: "New assignment for a teacher",
      approveItems: {
        disabled:
          "The button will be available after selecting at least one item in the list when all assignments are approved",
        available: "Approval of items",
      },
      approveItemsPrincipal: {
        disabled:
          'The button will be available after selecting at least one item in the list. The department status should be "approved by head"',
        available: "Approval of items",
      },
      sendTo: {
        disabled:
          "The button will be available after approving all items on the “All items” tab",
        available:
          "The product list will be sent to the principal for approval",
      },
      sendToHead: "The button will be available after approving all items",
      sendToHeadEnabled: "Approval of the assignment",
      goToEShop: 'You will be redirected to the "E-shop"',
      addProduct: "You can add an item of our partners",
      approveTableItems: "Approval of items",
    },
  },

  inventory: {
    title: "Inventory",
    addInventory: "Add inventory",
    editInventory: "Edit inventory",
    details: "Inventory details",
    changesHistory: "Changes history",

    filters: {
      country: "Country",
    },

    changesTable: {
      date: "Date",
      user: "User",
      quantity: "Quantity",
      comment: "Comment",
    },

    fields: {
      quantity: "Quantity",
      supplier: "Supplier",
      code: "Code",
      name: "Name",
      country: "Country",
      product: "Product",
      comment: "Comment",
    },

    successes: {
      add: "Inventory entry has been added",
      edit: "Inventory entry has been edited",
      delete: "Inventory entry has been removed",
    },

    errors: {
      add: "Something went wrong adding an inventory entry, try again",
      edit: "Something went wrong editing the inventory entry, try again",
      delete: "Something went wrong deleting the inventory entry, try again",
    },
  },
};
