import React, { useMemo } from "react";
import { ContainerBase } from "../../../../components/layout/ContainerBase";
import { useSchoolInquiriesList } from "../../hooks";
import { SchoolInquiriesToolbar } from "../SchoolInquiriesToolbar";
import { useGetFastTrackInquiriesQuery } from "../../../../slices/fastTrackInquiries/api";
import { FastTrackInquiry } from "../FastTrackInquiry/FastTrackInquiry";
import { Pagination } from "../Pagination";
import { OpenFastTrack } from "../OpenFastTrack/OpenFastTrack";
import { NoInquiries } from "../NoInquiries";

export const FastTrackList = ({
  page,
  pageChangeHandler,
  pageSize,
  pageSizeChangeHandler,
  filter,
  filterChangeHandler,
}: ReturnType<typeof useSchoolInquiriesList>) => {
  const { data: fastTrackInquiries, isLoading: areFastTrackInquiriesLoading } =
    useGetFastTrackInquiriesQuery({
      page,
      pageSize,
    });

  const content = useMemo(() => {
    if (areFastTrackInquiriesLoading || !fastTrackInquiries) {
      return null;
    }

    if (fastTrackInquiries.data.length === 0) {
      return <NoInquiries />;
    }

    const list = fastTrackInquiries.data.map((fastTrackInquiry) => (
      <FastTrackInquiry
        key={fastTrackInquiry.id}
        fastTrackInquiry={fastTrackInquiry}
      />
    ));

    return (
      <>
        {list}
        <Pagination
          page={page}
          onPageChange={pageChangeHandler}
          pageSize={pageSize}
          onPageSizeChange={pageSizeChangeHandler}
          count={fastTrackInquiries.count}
        />
      </>
    );
  }, [
    areFastTrackInquiriesLoading,
    fastTrackInquiries,
    page,
    pageChangeHandler,
    pageSize,
    pageSizeChangeHandler,
  ]);

  return (
    <ContainerBase
      sx={{
        paddingTop: "32px",
        paddingBottom: "32px",
      }}
      maxWidth="xl"
    >
      <SchoolInquiriesToolbar
        isLoading={areFastTrackInquiriesLoading}
        filter={filter}
        onFilterChange={filterChangeHandler}
        filterCount={fastTrackInquiries?.filterCount}
      />
      <OpenFastTrack />
      {content}
    </ContainerBase>
  );
};
