import { alpha, Box, ButtonBase, Typography, useTheme } from "@mui/material";
import React, { useCallback } from "react";
import { ShoppingCartLight } from "../../components/astraIcons/ShoppingCart";
import { useDispatch, useSelector } from "../../store";
import { selectIsCartOpen } from "../../slices/shop/selectors";
import { toggleCart } from "../../slices/shop/slice";
import { useIsFastTrack, useShopList } from "../../slices/shop/hooks";
import { colors } from "../../theme/astra/colors";

export const Header = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const isCartOpen = useSelector(selectIsCartOpen);

  const shopList = useShopList();
  const isFastTrack = useIsFastTrack();

  const headerClickHandler = useCallback(() => {
    dispatch(toggleCart());
  }, [dispatch]);

  if (!shopList) {
    return null;
  }

  return (
    <Box
      sx={{
        display: "flex",
        height: "95px",
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: isFastTrack ? colors.pink : colors.blue,
        backgroundColor: isCartOpen
          ? alpha(isFastTrack ? colors.pink : colors.blue, 0.05)
          : alpha(isFastTrack ? colors.pink : colors.blue, 0.1),
        transition: isCartOpen
          ? theme.transitions.create("backgroundColor", {
              duration: theme.transitions.duration.enteringScreen,
            })
          : theme.transitions.create("backgroundColor", {
              duration: theme.transitions.duration.leavingScreen,
            }),
      }}
    >
      <ButtonBase
        onClick={headerClickHandler}
        sx={{
          borderRightWidth: isCartOpen ? "1px" : 0,
          borderRightStyle: "solid",
          borderRightColor: isFastTrack ? colors.pink : colors.blue,
          width: isCartOpen ? "97px" : "100%",
          height: "100%",
          flexGrow: 0,
          flexShrink: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ShoppingCartLight
          viewBox="0 0 41 40"
          sx={{
            width: "40px",
            height: "40px",
            color: isFastTrack ? colors.pink : colors.blue,
          }}
        />
      </ButtonBase>
      <Box
        sx={{
          flexGrow: 1,
          flexShrink: 1,
          paddingTop: "32px",
          paddingRight: "32px",
          paddingBottom: "30px",
          paddingLeft: "32px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        {shopList.fastTrack ? (
          <Typography
            component="span"
            sx={{
              fontSize: "14px",
              lineHeight: "24px",
              fontWeight: "500",
              color: colors.pink,
            }}
          >
            {shopList.inquiryName}
          </Typography>
        ) : (
          <>
            <Typography
              component="span"
              sx={{
                fontSize: "12px",
                lineHeight: "14px",
                color: colors.darkBlue,
                opacity: 0.5,
              }}
            >
              {shopList.inquiryName}
            </Typography>
            <Typography
              component="span"
              sx={{
                fontSize: "14px",
                lineHeight: "24px",
                fontWeight: "500",
                color: colors.darkBlue,
              }}
            >
              {shopList.departmentName}
            </Typography>
          </>
        )}
      </Box>
    </Box>
  );
};
