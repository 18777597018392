import React from "react";
import { ShoppingCart } from "../../../components/astraIcons/ShoppingCart";
import { alpha, Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { colors } from "../../../theme/astra/colors";

export const ActiveShoppingList = ({ fastTrack }: { fastTrack?: boolean }) => {
  const { t } = useTranslation("pages", {
    keyPrefix: "shopCatalog.currentList",
  });

  return (
    <Box
      sx={{
        display: "flex",
        gap: "6px",
        alignItems: "center",
        marginLeft: "-4px",
      }}
    >
      {fastTrack ? (
        <ShoppingCart
          sx={{
            color: colors.pink,

            'path[fill="#E7F1FF"]': {
              fill: alpha(colors.pink, 0.1),
            },
          }}
        />
      ) : (
        <ShoppingCart />
      )}
      <Typography
        component="span"
        sx={{
          fontSize: "12px",
          lineHeight: "20px",
          fontWeight: "500",
          color: fastTrack ? colors.pink : colors.darkBlue,
        }}
      >
        {t("activeShoppingList")}
      </Typography>
    </Box>
  );
};
