import React from "react";
import { DataTable } from "./DataTable/DataTable";
import { Helmet } from "react-helmet-async";
import { getTitle } from "../../utils/getTitle";
import { WideDrawer } from "../../components/wideDrawer/WideDrawer";
import { Add } from "./Drawers/Add";
import { Edit } from "./Drawers/Edit";
import { Details } from "./Drawers/Details";
import { useTranslation } from "react-i18next";
import { useTableUrlTools } from "../../utils/useTableUrlTools";
import { usePermissions } from "../../utils/usePermissions";
import { useHeaderTitle } from "../../slices/ui/useHeaderTitle";

export const Categories: React.FC = () => {
  const { t } = useTranslation();

  const { addDrawerOpen, editDrawerOpen, detailsDrawerOpen, closeDrawer } =
    useTableUrlTools("/categories");
  const can = usePermissions();

  const title = t("categories.title");

  useHeaderTitle(title);

  return (
    <>
      <Helmet>
        <title>{getTitle(title)}</title>
      </Helmet>
      {can("getCategories") && <DataTable />}
      {can("addCategory") && (
        <WideDrawer open={addDrawerOpen} close={closeDrawer}>
          <Add />
        </WideDrawer>
      )}
      {can("editCategory") && (
        <WideDrawer open={editDrawerOpen} close={closeDrawer}>
          <Edit />
        </WideDrawer>
      )}
      {can("getCategory") && (
        <WideDrawer open={detailsDrawerOpen} close={closeDrawer}>
          <Details />
        </WideDrawer>
      )}
    </>
  );
};
