import React from "react";
import { alpha, Box, ButtonBase, Typography } from "@mui/material";
import { colors } from "../../../../theme/astra/colors";
import { useTranslation } from "react-i18next";
import { ShoppingCart } from "../../../../components/astraIcons/ShoppingCart";
import { Link } from "react-router-dom";

type ContainerProps = {
  inquiryId: number;
  code: string | number;
  controls: React.ReactNode;
  children: React.ReactNode;
};

export const Container = ({
  inquiryId,
  code,
  controls,
  children,
}: ContainerProps) => {
  const { t } = useTranslation("pages");

  return (
    <Box
      sx={{
        border: `1px solid ${alpha(colors.pink, 0.3)}`, // #EFC0F2
        borderRadius: "4px",
        background: alpha(colors.pink, 0.03), // #FDF9FD
        marginBottom: "48px",
      }}
    >
      <Box
        sx={{
          borderBottom: `1px solid ${alpha(colors.pink, 0.3)}`,
          padding: "24px 24px 24px 40px",
          display: "flex",
          alignItems: "center",
          gap: "8px",
        }}
      >
        <ShoppingCart
          sx={{
            width: "32px",
            height: "32px",
            color: colors.pink,

            'path[fill="#E7F1FF"]': {
              fill: alpha(colors.pink, 0.1),
            },
          }}
        />
        <ButtonBase component={Link} to={`/fast-track-inquiries/${inquiryId}`}>
          <Typography
            component="span"
            sx={{
              fontSize: "22px",
              fontWeight: "500",
              lineHeight: "29px",
              letterSpacing: "0.4%",
              color: colors.pink,
            }}
          >
            {t("fastTrackInquiries.title")} #{code}
          </Typography>
        </ButtonBase>
        <Box
          sx={{
            marginLeft: "auto",
            display: "flex",
            alignItems: "center",
            gap: "20px",
          }}
        >
          {controls}
        </Box>
      </Box>
      <Box
        sx={{
          padding: "40px",
          display: "flex",
          gap: "36px",
          overflow: "scroll",
        }}
      >
        {children}
      </Box>
    </Box>
  );
};
