import React from "react";
import { Box, lighten, styled, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "../../store";
import { selectIsCartOpen } from "../../slices/shop/selectors";
import { ArrowRight } from "../../components/astraIcons/ArrowRight";
import {
  useAssignmentBudget,
  useIsFastTrack,
  useShopList,
  useTotalAmount,
} from "../../slices/shop/hooks";
import { selectSelfSchoolCurrency } from "../../slices/auth/selectors";
import { Link } from "react-router-dom";
import { EnhancedSkeleton } from "../../components/EnhancedSkeleton";
import { PriceCurrency } from "../../components/PriceCurrency";
import { colors } from "../../theme/astra/colors";
import { Button } from "../../components/astraComponents/Button";
import { FastForward } from "../../components/astraIcons/FastForward";

export const Checkout = () => {
  const { t } = useTranslation("layouts");

  const shopList = useShopList();
  const isFastTrack = useIsFastTrack();
  const isCartOpen = useSelector(selectIsCartOpen);
  const budget = useAssignmentBudget();
  const { result: totalAmount, isLoading: isTotalAmountLoading } =
    useTotalAmount();
  const schoolCurrency = useSelector(selectSelfSchoolCurrency);

  if (!shopList) {
    return null;
  }

  const currency = schoolCurrency
    ? schoolCurrency?.code?.toUpperCase()
    : t("currencies.qar", { ns: "common" });
  const verticalPadding = isCartOpen ? "20px" : "8px";

  const reviewLink = shopList.fastTrack
    ? `/fast-track-inquiries/${shopList.inquiryId}`
    : `/schoolinquiries/${shopList.inquiryId}/details/${shopList.departmentInquiryId}/assignments/${shopList.id}`;

  return (
    <Box
      sx={{
        paddingTop: verticalPadding,
        paddingRight: "16px",
        paddingBottom: verticalPadding,
        paddingLeft: "16px",
        backgroundColor: isFastTrack
          ? lighten(colors.pink, 0.97)
          : colors.white,
        zIndex: 1,
        position: "relative",
        marginTop: "auto",
      }}
    >
      {isCartOpen ? (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          {!isFastTrack && (
            <Row>
              <Label as="span" isFastTrack={isFastTrack}>
                {t("shop.yourBudget")}:
              </Label>
              <PriceCurrency
                price={budget}
                currency={currency}
                sx={{
                  color: isFastTrack ? colors.textOnPink1 : colors.dustBlue1,
                }}
              />
            </Row>
          )}
          <Row>
            <Label
              as="span"
              isFastTrack={isFastTrack}
              sx={{
                color: isFastTrack ? colors.textOnPink2 : colors.darkBlue,
                fontSize: "12px",
                lineHeight: "24px",
              }}
            >
              {t("shop.totalAmount")}:
            </Label>
            <PriceCurrency
              price={totalAmount}
              currency={currency}
              sx={{ color: isFastTrack ? colors.textOnPink1 : colors.darkBlue }}
              priceProps={{ sx: { fontSize: "20px" } }}
              currencyProps={{ sx: { fontSize: "14px", lineHeight: "24px" } }}
              showPriceZero
            />
          </Row>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Label as="span" isFastTrack={isFastTrack}>
            {t("shop.total")}
          </Label>
          {isTotalAmountLoading ? (
            <EnhancedSkeleton
              variant="text"
              sx={{ lineHeight: "33px" }}
              width={[50, 80, "%"]}
            />
          ) : (
            <PriceCurrency
              sx={{
                color: isFastTrack ? colors.textOnPink1 : colors.darkBlue,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "3px",
                textAlign: "center",
              }}
              price={totalAmount}
              currency={currency}
              currencyProps={{
                sx: {
                  lineHeight: "10px",
                },
              }}
              priceProps={{
                sx: {
                  whiteSpace: "nowrap",
                  width: "80px",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                },
              }}
              showPriceZero
              tooltip
            />
          )}
        </Box>
      )}
      <Button
        variant="contained"
        fastTrack={isFastTrack}
        sx={{
          marginTop: "18px",
          width: isCartOpen ? "100%" : "64px",
          height: "64px",

          ".MuiButton-startIcon": {
            ...(isCartOpen
              ? {
                  position: "absolute",
                  left: "20px",
                }
              : {
                  margin: 0,
                }),

            svg: {
              width: "24px",
              height: "24px",
            },
          },
        }}
        startIcon={
          isFastTrack ? <FastForward /> : <ArrowRight fontSize="medium" />
        }
        component={Link}
        to={{
          pathname: reviewLink,
          state: {
            scrollToTop: true,
          },
        }}
      >
        <Typography
          component="span"
          sx={{
            fontSize: "16px",
            fontWeight: "500",
            lineHeight: "24px",
            display: isCartOpen ? "inline" : "none",
          }}
        >
          {isFastTrack
            ? t("shop.reviewFastTrackInquiry")
            : t("shop.reviewAndSubmit")}
        </Typography>
      </Button>
    </Box>
  );
};

const Row = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
});

const Label = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "isFastTrack",
})<{
  isFastTrack: boolean;
}>(({ isFastTrack }) => ({
  color: isFastTrack ? colors.textOnPink2 : colors.dustBlue2,
  fontSize: "10px",
  lineHeight: "24px",
}));
