import React, { useCallback, useMemo } from "react";
import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  styled,
  Typography,
} from "@mui/material";
import { colors } from "../../../../../theme/astra/colors";
import { useTranslation } from "react-i18next";
import { ASSIGNMENT_STATUS_CHIP_STYLES } from "../../../../../consts";
import { useSelector } from "../../../../../store";
import {
  selectSelfId,
  selectSelfSchoolCurrency,
} from "../../../../../slices/auth/selectors";
import { ReactComponent as WalletIcon } from "../../../../../assets/newDesignItems/wallet.svg";
import { ReactComponent as EditIcon } from "../../../../../assets/newDesignItems/edit.svg";
import { useHistory } from "react-router-dom";
import { useSchoolInquiriesTableUrlTools } from "../../../hooks";
import { StatusChip } from "../../../../../components/StatusChip";
import { GetSchoolInquiriesForHeadsResponseDto } from "../../../../../slices/schoolInquiries/types";
import {
  DepartmentInquirySchoolStatus,
  InquirySchoolStatus,
  RouteState,
} from "../../../../../types";
import { useDisplayPrice } from "../../../../../utils/useDisplayPrice";
import {
  useDisplayAssignmentSchoolStatus,
  usePreActionCheck,
} from "../../../../../slices/schoolInquiries/hooks";
import { ProductsWithoutPriceTooltip } from "../../ProductsWithoutPriceTooltip";

type AssignmentProps = {
  inquiryId: number;
  inquirySchoolStatus: InquirySchoolStatus;
  inquiryBudget: number | null;
  departmentInquiryId: number;
  departmentInquirySchoolStatus: DepartmentInquirySchoolStatus;
  assignment: GetSchoolInquiriesForHeadsResponseDto["data"][number]["departmentInquiries"][number]["assignments"][number];
};

export const Assignment = ({
  assignment,
  departmentInquiryId,
  departmentInquirySchoolStatus,
  inquiryId,
  inquirySchoolStatus,
  inquiryBudget,
}: AssignmentProps) => {
  const { t } = useTranslation();
  const history = useHistory<RouteState>();
  const selfId = useSelector(selectSelfId);
  const selfCurrency = useSelector(selectSelfSchoolCurrency);
  const { baseUrl } = useSchoolInquiriesTableUrlTools();
  const displayPrice = useDisplayPrice();
  const displayAssignmentSchoolStatus = useDisplayAssignmentSchoolStatus();

  const goToAssignmentHandler = useCallback(() => {
    history.push(
      `${baseUrl}/${inquiryId}/details/${departmentInquiryId}/assignments/${assignment.id}`,
      {
        justGoBack: true,
        scrollToTop: true,
      }
    );
  }, [assignment.id, baseUrl, departmentInquiryId, history, inquiryId]);

  const editAssignmentHandler = useCallback(
    (event) => {
      event.stopPropagation();
      history.push(
        `${baseUrl}/${inquiryId}/department/${departmentInquiryId}/assignment/${assignment.id}/editAssignment`,
        { justGoBack: true }
      );
    },
    [assignment.id, baseUrl, departmentInquiryId, history, inquiryId]
  );

  const preActionCheck = usePreActionCheck();

  const disableEdit = useMemo(() => {
    if (!inquiryBudget) {
      return true;
    }

    if (preActionCheck(inquirySchoolStatus, departmentInquirySchoolStatus)) {
      return true;
    }

    return (
      assignment?.schoolStatus !== "new" &&
      assignment?.schoolStatus !== "inProgress" &&
      assignment?.schoolStatus !== "readyForSending" &&
      assignment?.schoolStatus !== "approvedByTeacher" &&
      assignment?.schoolStatus !== "needReviewByTeacher"
    );
  }, [
    assignment?.schoolStatus,
    departmentInquirySchoolStatus,
    inquiryBudget,
    inquirySchoolStatus,
    preActionCheck,
  ]);

  const ownList = assignment.user.id === selfId;

  return (
    <Row>
      <AssignmentCard onClick={goToAssignmentHandler}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "40px",
          }}
        >
          <Box>
            <Typography
              sx={{
                fontSize: "20px",
                color: colors.darkBlue,
                fontWeight: "500",
              }}
            >
              {ownList
                ? "My List"
                : `${assignment.user.firstName} ${assignment.user.lastName}`}
            </Typography>
          </Box>
          <StatusChip
            chipStyle={ASSIGNMENT_STATUS_CHIP_STYLES[assignment.schoolStatus]}
            label={displayAssignmentSchoolStatus({
              schoolStatus: assignment.schoolStatus,
              ownAssignment: assignment.user.id === selfId,
              ...assignment.schoolTitles,
            })}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              border: "1px solid #E0E8F1",
              borderRadius: "50px",
              width: "40px",
              height: "40px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginRight: "20px",
            }}
          >
            <WalletIcon />
          </Box>
          <ProductsWithoutPriceTooltip count={assignment.productsWithoutPrice}>
            <Box sx={{ display: "flex", gap: "20px" }}>
              <AssignmentBudgetBox
                total={`${displayPrice(assignment?.totalAmount) || 0}${
                  assignment.productsWithoutPrice > 0 ? "+" : ""
                }`}
                currency={
                  selfCurrency?.code?.toUpperCase() ||
                  t("currencies.qar", { ns: "common" })
                }
                text={t("schoolInquiries.spentBudget", { ns: "pages" })}
              />
              <Divider
                orientation="vertical"
                sx={{ color: "red", height: "40px" }}
              />
              <AssignmentBudgetBox
                total={displayPrice(assignment.budget) || "N/A"}
                currency={
                  selfCurrency?.code?.toUpperCase() ||
                  t("currencies.qar", { ns: "common" })
                }
                text={t("schoolInquiries.assignedBudget", { ns: "pages" })}
              />
            </Box>
          </ProductsWithoutPriceTooltip>
          {disableEdit ? (
            <Box sx={{ width: "60px" }} />
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: "20px",
              }}
            >
              <Button
                onClick={editAssignmentHandler}
                sx={{
                  minWidth: "40px",
                  minHeight: "40px",
                  height: "40px",
                  width: "40px",
                  borderRadius: "30px",
                  padding: 0,
                }}
              >
                <EditIcon color={"#5E7DA0"} />
              </Button>
            </Box>
          )}
        </Box>
      </AssignmentCard>
    </Row>
  );
};

const AssignmentBudgetBox = ({
  total,
  currency,
  text,
}: {
  total: number | string;
  currency: string;
  text: string;
}) => (
  <Box>
    <Box sx={{ display: "flex", gap: "4px" }}>
      <Typography
        sx={{
          fontSize: "16px",
          fontWeight: "500",
          color: colors.darkBlue,
        }}
      >
        {total}
      </Typography>
      <Typography
        sx={{
          fonetSize: "12px",
          fontWeight: "400",
          color: colors.darkBlue,
        }}
      >
        {currency}
      </Typography>
    </Box>
    <Typography sx={{ fontSize: "10px", color: colors.dustBlue1 }}>
      {text}
    </Typography>
  </Box>
);

const Row = styled(Grid)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "baseline",
  padding: "10px 16px",
});

const AssignmentCard = styled(Card)({
  position: "relative",
  backgroundColor: colors.white,
  border: `1px solid ${colors.dustBlue6}`,
  padding: "32px",
  width: "100%",
  boxShadow: "0px 4px 10px 0px #1641730D",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  cursor: "pointer",
});
