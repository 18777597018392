import React from "react";
import { NewDesignContextMenu } from "../../../../../components/NewDesignContextMenu";
import { colors } from "../../../../../theme/astra/colors";
import { useTranslation } from "react-i18next";
import { useMessageHandlers } from "../../../../../utils/useMessageHandlers";
import { useRemoveFastTrackInquiryMutation } from "../../../../../slices/fastTrackInquiries/api";
import { useDispatch } from "../../../../../store";
import { setIsOpen } from "../../../../../slices/fastTrackInquiries/slice";
import { setShopList } from "../../../../../slices/shop/actions";

export const Context = ({ inquiryId }: { inquiryId: number }) => {
  const { t } = useTranslation("pages");
  const dispatch = useDispatch();
  const { handleError } = useMessageHandlers();

  const [remove, { isLoading: isRemoving }] =
    useRemoveFastTrackInquiryMutation();

  return (
    <NewDesignContextMenu
      sx={{
        color: colors.textOnPink1,

        "svg path": {
          fill: colors.textOnPink1,
        },
      }}
      buttons={[
        {
          label: t("fastTrackInquiries.controls.removeFastTrackInquiry"),
          handler: () => {
            remove({
              fastTrackInquiryId: inquiryId,
            })
              .unwrap()
              .then(() => {
                dispatch(setIsOpen(false));
                dispatch(setShopList(null));
              })
              .catch(handleError);
          },
          loading: isRemoving,
        },
      ]}
    />
  );
};
