import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { useFeedSuccess } from "../../../utils/feedHooks";
import { FormProvider, useForm } from "react-hook-form";
import { useTableUrlTools } from "../../../utils/useTableUrlTools";
import { useAddUserMutation } from "../../../slices/users/api";
import { UserForm, userFormSchemaCreator } from "./UserForm";
import { AddUserForm } from "./types";
import { useSubmitFormErrorHander } from "../../../utils/formHelpers";
import { useYup } from "../../../utils/validation";
import { AddUserRequestDto } from "../../../slices/users/types";
import { useSelector } from "../../../store";
import { AstraForm } from "../../../components/astraWideDrawer/AstraForm";
import {
  selectSelfRole,
  selectSelfSchoolId,
} from "../../../slices/auth/selectors";

export const Add: React.FC = () => {
  const { t } = useTranslation("pages");
  const feedSuccess = useFeedSuccess();
  const schema = useYup(userFormSchemaCreator);
  const formMethods = useForm<AddUserForm>({
    resolver: yupResolver(schema),
    defaultValues: {
      role: "inquiryManager",
    },
  });
  const { handleSubmit, reset } = formMethods;
  const { catchFormError, formError, fieldsErrors } = useSubmitFormErrorHander(
    t("users.errors.add")
  );

  const [addUser, { isLoading }] = useAddUserMutation();

  const selfRole = useSelector(selectSelfRole);
  const selfSchoolId = useSelector(selectSelfSchoolId);

  useEffect(() => {
    if (selfRole && selfSchoolId) {
      reset({
        schoolId: selfSchoolId,
        role:
          selfRole === "principal" || selfRole === "headOfDepartment"
            ? "teacher"
            : "inquiryManager",
      });
    }
  }, [reset, selfRole, selfSchoolId]);

  const { closeDrawer } = useTableUrlTools("/users");
  const onSubmit = useCallback(
    (form: AddUserForm) => {
      const userToSave: AddUserRequestDto = {
        email: form.email,
        firstName: form.firstName,
        lastName: form.lastName,
        title: null,
        role: form.role,
        active: true,
        schoolId: null,
      };

      if (
        form.role === "teacher" ||
        form.role === "headOfDepartment" ||
        form.role === "principal"
      ) {
        userToSave.title = form.title ?? null;
        userToSave.schoolId = form.schoolId ?? null;
      }

      if (form.role === "inquiryManager") {
        userToSave.inquiryManagerInquiriesIds = form.inquiryManagerInquiriesIds;
      }

      if (form.role === "orderManager") {
        userToSave.orderManagerInquiriesIds = form.orderManagerInquiriesIds;
      }

      addUser(userToSave)
        .unwrap()
        .then(({ id }) => {
          feedSuccess(t("users.addSuccess"), `/users/${id}`);
          closeDrawer();
          reset();
        })
        .catch(catchFormError);
    },
    [addUser, catchFormError, closeDrawer, feedSuccess, reset, t]
  );

  return (
    <AstraForm
      heading={t("users.addTitle")}
      onSubmit={handleSubmit(onSubmit)}
      loading={isLoading}
      formError={formError}
      submitText={t("users.add")}
      sx={{
        color: "#164173", //Dark blue
        padding: 4,
      }}
    >
      <FormProvider {...formMethods}>
        <UserForm fieldsErrors={fieldsErrors} />
      </FormProvider>
    </AstraForm>
  );
};
