import React from "react";
import { Details } from "./Drawers/Details";
import { Helmet } from "react-helmet-async";
import { getTitle } from "../../../utils/getTitle";
import { useTranslation } from "react-i18next";
import { usePermissions } from "../../../utils/usePermissions";
import { Box } from "@mui/material";
import { useTableUrlTools } from "../../../utils/useTableUrlTools";
import { WideDrawer } from "../../../components/wideDrawer/WideDrawer";
import { UploadFile } from "./Drawers/UploadFile";
import { DataTable } from "./DataTable";
import { useInquiryDetailsUrlTools } from "../hooks";
import { useHeaderTitle } from "../../../slices/ui/useHeaderTitle";

export const DeliveryNotes = () => {
  const { t } = useTranslation("pages");
  const can = usePermissions();

  const { baseUrl, inquiryId } = useInquiryDetailsUrlTools();
  const { editDrawerOpen, detailsDrawerOpen, closeDrawer } = useTableUrlTools(
    `${baseUrl}/dn`
  );

  const title = t("deliveryNotes.title");

  useHeaderTitle(title);

  return (
    <Box>
      <Helmet>
        <title>{getTitle(title)}</title>
      </Helmet>
      {can("getDeliveryNotes") && <DataTable />}
      {can("editDeliveryNote") && (
        <WideDrawer open={editDrawerOpen} close={closeDrawer}>
          <UploadFile inquiryId={inquiryId} />
        </WideDrawer>
      )}
      {can("getDeliveryNote") && (
        <WideDrawer open={detailsDrawerOpen} close={closeDrawer}>
          <Details inquiryId={inquiryId} />
        </WideDrawer>
      )}
    </Box>
  );
};
