import React, { useCallback } from "react";
import { alpha, Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { IconButton } from "../../../../../components/astraComponents/IconButton";
import { Trash } from "../../../../../components/astraIcons/Trash";
import { useRemoveFromCartMutation } from "../../../../../slices/shop/api";
import { useMessageHandlers } from "../../../../../utils/useMessageHandlers";
import { useShopList } from "../../../../../slices/shop/hooks";
import { colors } from "../../../../../theme/astra/colors";

type RemoveProps = {
  id: number;
  quantityInCart: number;
};

export const Remove = ({ id, quantityInCart }: RemoveProps) => {
  const { t } = useTranslation("pages");
  const { handleSuccess, handleError } = useMessageHandlers();

  const shopList = useShopList();

  const [removeFromCart] = useRemoveFromCartMutation();

  const removeClickHandler = useCallback(() => {
    if (!shopList) {
      return;
    }

    removeFromCart({
      inquiryId: shopList.inquiryId,
      departmentInquiryId: shopList.departmentInquiryId,
      assignmentId: shopList.id,
      productId: id,
      quantity: quantityInCart,
    })
      .unwrap()
      .then(handleSuccess)
      .catch(handleError);
  }, [
    handleError,
    handleSuccess,
    id,
    quantityInCart,
    removeFromCart,
    shopList,
  ]);

  return (
    <>
      <Box
        sx={{
          border: `1px solid ${colors.darkBlue}`,
          color: colors.darkBlue,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderTopLeftRadius: "4px",
          borderBottomLeftRadius: "4px",
          flexGrow: 1,
          flexShrink: 1,
        }}
      >
        <Typography
          component="span"
          sx={{
            fontSize: "16px",
            fontWeight: "500",
            lineHeight: "24px",
          }}
        >
          {t("shopCatalog.list.added")}
        </Typography>
      </Box>
      <IconButton
        onClick={removeClickHandler}
        variant="outlined"
        size="small"
        sx={{
          flexGrow: 0,
          flexShrink: 0,
          flexBasis: "57px",
          marginLeft: "-1px",
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
          borderColor: colors.darkBlue,
          backgroundColor: "transparent",
          color: colors.darkBlue,

          "&:hover": {
            backgroundColor: alpha(colors.darkBlue, 0.1),
          },

          "&:active": {
            backgroundColor: alpha(colors.darkBlue, 0.25),
          },
        }}
      >
        <Trash />
      </IconButton>
    </>
  );
};
