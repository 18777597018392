import React, { useCallback } from "react";
import { Button } from "../../../components/astraComponents/Button";
import { useTranslation } from "react-i18next";
import { FastForward } from "../../../components/astraIcons/FastForward";
import { useDispatch, useSelector } from "../../../store";
import { setShopList } from "../../../slices/shop/actions";
import { useGetCurrentFastTrackInquiryQuery } from "../../../slices/fastTrackInquiries/api";
import { selectSelf } from "../../../slices/auth/selectors";

export const GoFastTrack = () => {
  const { t } = useTranslation("pages");
  const dispatch = useDispatch();

  const self = useSelector(selectSelf);

  const { data: currentFastTrackInquiry } =
    useGetCurrentFastTrackInquiryQuery();

  const clickHandler = useCallback(() => {
    if (currentFastTrackInquiry) {
      dispatch(setShopList(currentFastTrackInquiry.assignmentId));
    }
  }, [currentFastTrackInquiry, dispatch]);

  if (!self) {
    return null;
  }

  if (!["principal", "headOfDepartment", "teacher"].includes(self.role)) {
    return null;
  }

  return (
    <Button
      fastTrack
      variant="outlined"
      sx={{
        alignSelf: "flex-end",
      }}
      startIcon={<FastForward />}
      onClick={clickHandler}
    >
      {t("shopCatalog.currentList.goFastTrack")}
    </Button>
  );
};
