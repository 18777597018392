import React from "react";
import { useTranslation } from "react-i18next";
import { usePermissions } from "../../utils/usePermissions";
import { getTitle } from "../../utils/getTitle";
import { Helmet } from "react-helmet-async";
import { Content } from "./Content/Content";
import { useSchoolInquiriesTableUrlTools } from "./hooks";
import { Add } from "./drawers/Add";
import { Edit } from "./drawers/Edit";
import { useIsSchoolTeam } from "../../utils/useIsSchoolTeam";
import { useCanViewSchoolInquiries } from "../../permissions/commonChecks";
import { AstraWideDrawer } from "../../components/astraWideDrawer/AstraWideDrawer";
import { useRouteMatch } from "react-router-dom";
import { AddDepartment } from "./drawers/AddDepartment";
import { useHeaderTitle } from "../../slices/ui/useHeaderTitle";
import { SendToAstra } from "./drawers/SendToAstra";
import { EditDepartment } from "./drawers/EditDepartment";
import { AddAssignment } from "./drawers/AddAssignment";
import { EditAssignment } from "./drawers/EditAssignment";
import { AddItem } from "./drawers/AddItem";
import { AddItemToFastTrackInquiry } from "./drawers/AddItemToFastTrackInquiry";

export const SchoolInquiries = () => {
  const { t } = useTranslation("pages");
  const isSchoolTeam = useIsSchoolTeam();
  const { addDrawerOpen, editDrawerOpen, closeDrawer, baseUrl } =
    useSchoolInquiriesTableUrlTools();
  const addDepartmentMatcher = useRouteMatch(
    `${baseUrl}/:inquiryId/addDepartment`
  );
  const editDepartmentMatcher = useRouteMatch(
    `${baseUrl}/:inquiryId/department/:departmentInquiryId/editDepartment`
  );

  const addAssignmentMatcher = useRouteMatch(
    `${baseUrl}/:inquiryId/department/:departmentInquiryId/addAssignment`
  );

  const editAssignmentMatcher = useRouteMatch(
    `${baseUrl}/:inquiryId/department/:departmentInquiryId/assignment/:assignmentId/editAssignment`
  );

  const addItemMatcher = useRouteMatch(
    `${baseUrl}/:inquiryId/department/:departmentInquiryId/assignment/:assignmentId/addItem`
  );

  const addItemToFastTrackInquiryMatcher = useRouteMatch(
    `${baseUrl}/add-item-to-fast-track-inquiry`
  );

  const sendToAstraMatcher = useRouteMatch(`${baseUrl}/:inquiryId/sendtoastra`);

  const can = usePermissions();
  const allowedToView = useCanViewSchoolInquiries();

  const title = isSchoolTeam
    ? t("schoolInquiries.schoolTeamTitle")
    : t("schoolInquiries.title");

  useHeaderTitle(title);

  return (
    <>
      <Helmet>
        <title>{getTitle(title)}</title>
      </Helmet>
      {allowedToView && <Content />}
      {can("addSchoolInquiry") && (
        <AstraWideDrawer open={addDrawerOpen} close={closeDrawer}>
          <Add />
        </AstraWideDrawer>
      )}
      {can("editSchoolInquiry") && (
        <AstraWideDrawer open={editDrawerOpen} close={closeDrawer}>
          <Edit />
        </AstraWideDrawer>
      )}
      <AstraWideDrawer open={!!addDepartmentMatcher} close={closeDrawer}>
        <AddDepartment />
      </AstraWideDrawer>
      <AstraWideDrawer open={!!sendToAstraMatcher} close={closeDrawer}>
        <SendToAstra />
      </AstraWideDrawer>
      <AstraWideDrawer open={!!editDepartmentMatcher} close={closeDrawer}>
        <EditDepartment />
      </AstraWideDrawer>
      <AstraWideDrawer open={!!addAssignmentMatcher} close={closeDrawer}>
        <AddAssignment />
      </AstraWideDrawer>
      <AstraWideDrawer open={!!editAssignmentMatcher} close={closeDrawer}>
        <EditAssignment />
      </AstraWideDrawer>
      <AstraWideDrawer open={!!addItemMatcher} close={closeDrawer}>
        <AddItem />
      </AstraWideDrawer>
      <AstraWideDrawer
        open={!!addItemToFastTrackInquiryMatcher}
        close={closeDrawer}
      >
        <AddItemToFastTrackInquiry closeDrawer={closeDrawer} />
      </AstraWideDrawer>
    </>
  );
};
