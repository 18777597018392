import React, { useCallback, useEffect, useState } from "react";
import { useChangeQuantityMutation } from "../../../../../slices/fastTrackInquiries/api";
import { SimpleNumericControl } from "../../../../../components/SimpleNumericControl";
import { useMessageHandlers } from "../../../../../utils/useMessageHandlers";
import { colors } from "../../../../../theme/astra/colors";

type QuantityFieldProps = {
  fastTrackInquiryId: number;
  requestedItemId: number;
  quantity: number;
};

export const QuantityField = ({
  fastTrackInquiryId,
  requestedItemId,
  quantity,
}: QuantityFieldProps) => {
  const { handleSuccess, handleError } = useMessageHandlers();

  const [localValue, setLocalValue] = useState(quantity);
  const [pending, setPending] = useState(false);

  const [changeQuantity, { isLoading: isChangingQuantity }] =
    useChangeQuantityMutation();

  useEffect(() => {
    if (localValue !== quantity) {
      setPending(true);

      const timeoutId = setTimeout(() => {
        changeQuantity({
          fastTrackInquiryId,
          requestedItemId,
          quantity: localValue,
        })
          .unwrap()
          .then(handleSuccess)
          .then(() => {
            setPending(false);
          })
          .catch((e) => {
            handleError(e);
            setLocalValue(quantity);
            setPending(false);
          });
      }, 1000);

      return () => clearTimeout(timeoutId);
    }
  }, [
    changeQuantity,
    fastTrackInquiryId,
    handleError,
    handleSuccess,
    localValue,
    quantity,
    requestedItemId,
  ]);

  const changeHandler = useCallback((value: number) => {
    setLocalValue(value);
  }, []);

  const clickHandler = useCallback<React.MouseEventHandler<HTMLDivElement>>(
    (e) => {
      e.stopPropagation();
    },
    []
  );

  return (
    <SimpleNumericControl
      value={localValue}
      onChange={changeHandler}
      onClick={clickHandler}
      positiveOnly
      disabled={isChangingQuantity}
      pending={pending || isChangingQuantity}
      sx={{
        ".MuiButtonBase-root:not(.Mui-disabled)": {
          color: colors.pink,
        },

        ".MuiButtonBase-root.Mui-disabled": {
          color: colors.dustBlue4,
        },
      }}
    />
  );
};
