import React from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { getTitle } from "../../utils/getTitle";
import { DataTable } from "./DataTable/DataTable";
import { useHeaderTitle } from "../../slices/ui/useHeaderTitle";

export const Currencies = () => {
  const { t } = useTranslation("pages");

  const title = t("currencies.title");

  useHeaderTitle(title);

  return (
    <>
      <Helmet>
        <title>{getTitle(title)}</title>
      </Helmet>
      <DataTable />
    </>
  );
};
