import { createYupSchema } from "../../../utils/validation";
import * as yup from "yup";
import { SUPPLIER_PRODUCT_CODE_REGEXP } from "../../../consts";

export const addItemSchemaCreator = createYupSchema((t) =>
  yup.object({
    supplierId: yup.number().min(0, t("required")).required(t("required")),
    supplierProductCode: yup
      .string()
      .matches(SUPPLIER_PRODUCT_CODE_REGEXP, {
        excludeEmptyString: true,
        message: t("supplierProductCode"),
      })
      .max(256, t("tooLong", { maxLength: 256 }))
      .required(t("required")),
    name: yup
      .string()
      .max(256, t("tooLong", { maxLength: 256 }))
      .required(t("required")),
    quantity: yup
      .number()
      .typeError(t("number"))
      .min(1, t("numMustBeGreater", { minNum: 0 }))
      .required(t("required")),
  })
);
