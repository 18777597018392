import React from "react";
import { Box, Typography } from "@mui/material";
import { colors } from "../../theme/astra/colors";

type CellProps = {
  label: string;
  value: string;
  currency?: string | null;
  horizontal?: boolean;
};

export const ListDataCell = ({
  label,
  value,
  currency,
  horizontal,
}: CellProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: horizontal ? "row" : "column",
        alignItems: horizontal ? "center" : "flex-start",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "baseline" }}>
        <Typography
          component="span"
          sx={{
            color: colors.darkBlue,
            fontSize: "16px",
            lineHeight: "24px",
            fontWeight: "500",
          }}
        >
          {value}
        </Typography>
        {currency && (
          <Typography
            component="span"
            sx={{
              color: colors.darkBlue,
              fontSize: "12px",
              lineHeight: "20px",
              fontWeight: "400",
              marginLeft: "4px",
            }}
          >
            {currency}
          </Typography>
        )}
      </Box>
      <Typography
        component="span"
        sx={{
          color: colors.dustBlue1,
          fontSize: "10px",
          lineHeight: "15px",
          marginTop: horizontal ? "0" : "-2px",
          marginLeft: horizontal ? "8px" : "0",
        }}
      >
        {label}
      </Typography>
    </Box>
  );
};
