import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { AddToFavorites } from "../../../../../components/astraIcons/AddToFavorites";
import { Button } from "../../../../../components/astraComponents/Button";
import { useAddWishlistRecordMutation } from "../../../../../slices/wishlistRecords/api";
import { useGetFastTrackInquiryQuery } from "../../../../../slices/fastTrackInquiries/api";
import { useMessageHandlers } from "../../../../../utils/useMessageHandlers";

export const MoveToFavorites = ({
  inquiryId,
  selectedIds,
}: {
  inquiryId: number;
  selectedIds: number[];
}) => {
  const { t } = useTranslation("pages");
  const { handleError } = useMessageHandlers();

  const { data: fastTrackInquiry } = useGetFastTrackInquiryQuery({
    fastTrackInquiryId: inquiryId,
  });

  const [addWishlistRecords, { isLoading: areWishlistRecordsAdding }] =
    useAddWishlistRecordMutation();

  const clickHandler = useCallback(() => {
    if (!fastTrackInquiry || selectedIds.length === 0) {
      return;
    }

    const productsIds = fastTrackInquiry.requestedItems.reduce<number[]>(
      (accumulator, requestedItem) =>
        selectedIds.includes(requestedItem.id) &&
        typeof requestedItem.productId === "number"
          ? [...accumulator, requestedItem.productId]
          : accumulator,
      []
    );

    if (productsIds.length === 0) {
      return;
    }

    addWishlistRecords({
      productsIds,
    })
      .unwrap()
      .catch(handleError);
  }, [addWishlistRecords, fastTrackInquiry, handleError, selectedIds]);

  return (
    <Button
      fastTrack
      variant="outlined"
      startIcon={<AddToFavorites />}
      onClick={clickHandler}
      disabled={areWishlistRecordsAdding}
    >
      {t("fastTrackInquiries.listControls.moveToFavorites")}
    </Button>
  );
};
