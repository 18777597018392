import React, { useCallback, useState } from "react";
import { alpha, Box, Checkbox, Dialog, Typography } from "@mui/material";
import { colors } from "../theme/astra/colors";
import { WarningTriangle } from "./astraIcons/WarningTriangle";
import { useTranslation } from "react-i18next";
import { Button } from "./astraComponents/Button";
import { LoadingButton } from "@mui/lab";

export const AttentionRequiredDialog = ({
  open,
  onClose,
  onProceed,
  loading,
  title,
  description,
  checkboxLabel,
  checkedByDefault = false,
  proceedLabel,
}: {
  open: boolean;
  onClose: () => void;
  onProceed: () => void;
  loading?: boolean;
  title: string;
  description?: string;
  checkboxLabel: string;
  checkedByDefault?: boolean;
  proceedLabel?: string;
}) => {
  const { t } = useTranslation("components");

  const [isChecked, setIsChecked] = useState(checkedByDefault);

  const checkboxClickHandler = useCallback(() => {
    setIsChecked((current) => !current);
  }, []);

  const proceedClickHandler = useCallback(() => {
    if (isChecked) {
      onProceed();
    }
  }, [isChecked, onProceed]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        ".MuiDialog-paper": {
          width: "619px",
          padding: 0,
          border: "none",
          borderRadius: "4px",
          boxShadow: `2px 0 16px 10px ${alpha(colors.blue, 0.05)}`,
        },
      }}
    >
      <Box
        sx={{
          border: `1px solid ${colors.pink}`,
          backgroundColor: alpha(colors.pink, 0.2),
          padding: "32px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "4px 4px 0 0",
          color: colors.textOnPink1,
          boxShadow: `2px 0 16px 10px ${alpha(colors.blue, 0.05)}`,
        }}
      >
        <WarningTriangle />
        <Typography
          component="span"
          sx={{
            fontSize: "20px",
            lineHeight: "28px",
            fontWeight: "400",
          }}
        >
          {t("attentionRequiredDialog.attention")}
        </Typography>
      </Box>
      <Box
        sx={{
          paddingTop: "25px",
          paddingRight: "40px",
          paddingBottom: "59px",
          paddingLeft: "40px",
          display: "flex",
          flexDirection: "column",
          color: colors.darkBlue,
          border: `1px solid ${colors.dustBlue5}`,
          borderTop: "none",
          borderRadius: "0 0 4px 4px",
        }}
      >
        <Typography
          component="span"
          sx={{
            fontSize: "16px",
            lineHeight: "22px",
            fontWeight: "700",
            letterSpacing: 0,
          }}
        >
          {title}
        </Typography>
        {description && description.length !== 0 && (
          <Typography
            component="span"
            sx={{
              fontSize: "14px",
              lineHeight: "19px",
              fontWeight: "400",
              letterSpacing: 0,
              marginTop: "16px",
            }}
          >
            {description}
          </Typography>
        )}
        <Box
          sx={{
            marginTop: "20px",
            display: "flex",
            alignItems: "flex-start",
          }}
        >
          <Checkbox
            checked={isChecked}
            onChange={checkboxClickHandler}
            sx={{
              marginLeft: "-8px",
            }}
          />
          <Typography
            component="span"
            sx={{
              fontWeight: "400",
              fontSize: "14px",
              lineHeight: "16px",
              color: colors.darkBlue,
              marginTop: "10px",
            }}
          >
            {checkboxLabel}
          </Typography>
        </Box>
        <Box
          sx={{
            marginTop: "20px",
            display: "flex",
            gap: "8px",
          }}
        >
          <Button
            fastTrack
            variant="outlined"
            sx={{
              width: "calc(50% - 4px)",
            }}
            onClick={onClose}
          >
            {t("cancel", { ns: "common" })}
          </Button>
          <Button
            fastTrack
            component={LoadingButton}
            loading={loading}
            disabled={!isChecked}
            variant="contained"
            onClick={proceedClickHandler}
            sx={{
              width: "calc(50% - 4px)",
            }}
          >
            {proceedLabel ?? t("proceed", { ns: "common" })}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};
