import React, { useEffect, useRef, useState } from "react";
import { Tooltip, Typography } from "@mui/material";
import { colors } from "../../../../theme/astra/colors";
import { useIsFastTrack } from "../../../../slices/shop/hooks";

type CroppedTextWithTrailingProps = {
  text: string;
};

export const CroppedTextWithTrailing = ({
  text,
}: CroppedTextWithTrailingProps) => {
  const isFastTrack = useIsFastTrack();

  const textElementRef = useRef<HTMLInputElement | null>(null);

  const compareSize = () => {
    const compare =
      (textElementRef.current?.scrollHeight || 0) >
      (textElementRef.current?.clientHeight || 0);
    setHover(compare);
  };

  useEffect(() => {
    compareSize();
    window.addEventListener("resize", compareSize);
  }, []);

  useEffect(
    () => () => {
      window.removeEventListener("resize", compareSize);
    },
    []
  );

  const [hoverStatus, setHover] = useState(false);

  return (
    <Tooltip title={text || ""} disableHoverListener={!hoverStatus}>
      <Typography
        ref={textElementRef}
        component="span"
        dir="auto"
        sx={{
          fontSize: "14px",
          lineHeight: "20px",
          fontWeight: "500",
          color: isFastTrack ? colors.textOnPink2 : colors.dustBlue1,
          marginBottom: "auto",
          overflow: "hidden",
          textOverflow: "ellipsis",
          maxHeight: "40px",
          width: "215px",
          display: "-webkit-box",
          WebkitLineClamp: "2",
          WebkitBoxOrient: "vertical",
        }}
      >
        {text}
      </Typography>
    </Tooltip>
  );
};
