import React from "react";
import { useYup } from "../../../utils/validation";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { AddItemForm, ListProp } from "./types";
import { addItemSchemaCreator } from "./schema";
import { Box } from "@mui/material";
import { Header } from "./Header";
import { AstraForm } from "../../astraWideDrawer/AstraForm";
import { Fields } from "./Fields";
import { FieldsErrorState, FormErrorState } from "../../../utils/formHelpers";

type AddItemProps = {
  list: ListProp;
  onSubmit: (form: AddItemForm) => void;
  isSubmitting?: boolean;
  formError: FormErrorState;
  fieldsErrors: FieldsErrorState;
  fastTrack?: boolean;
};

export const AddItem = ({
  list,
  onSubmit,
  isSubmitting,
  formError,
  fieldsErrors,
  fastTrack = false,
}: AddItemProps) => {
  const { handleSubmit, control, setValue } = useForm<AddItemForm>({
    resolver: yupResolver(useYup(addItemSchemaCreator)),
  });

  return (
    <Box
      sx={{
        width: "100%",
        padding: "20px 32px 0px 32px",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <Header list={list} fastTrack={fastTrack} />
      <AstraForm
        onSubmit={handleSubmit(onSubmit)}
        loading={isSubmitting}
        formError={formError}
        fastTrack={fastTrack}
      >
        <Fields {...{ fieldsErrors, control, setValue, fastTrack }} />
      </AstraForm>
    </Box>
  );
};
