import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import { colors } from "../../../../theme/astra/colors";
import { useDisplayPrice } from "../../../../utils/useDisplayPrice";
import { ListDataCell } from "../../../../components/Budget/ListDataCell";

type BottomRowProps = {
  budgetSpent: number;
  currency: string;
  totalProducts: number;
  productsWithoutPrice: number;
};

export const BottomRow = ({
  budgetSpent,
  currency,
  totalProducts,
  productsWithoutPrice,
}: BottomRowProps) => {
  const { t } = useTranslation("pages");

  const displayPrice = useDisplayPrice();

  return (
    <>
      <Box
        sx={{
          borderTop: `1px solid ${colors.dustBlue5}`,
          padding: "20px",
          gridColumn: "1 / 8",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <Typography
          component="span"
          sx={{
            fontSize: "13px",
            lineHeight: "24px",
            fontWeight: "500",
            color: colors.darkBlue,
          }}
        >
          {t("fastTrackInquiries.totalAmountSummary")}:
        </Typography>
      </Box>
      <Box
        sx={{
          borderTop: `1px solid ${colors.dustBlue5}`,
          padding: "20px 12px",
          gridColumn: "8 / 10",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "4px",
            alignItems: "flex-end",
          }}
        >
          <Typography
            component="span"
            sx={{
              fontSize: "16px",
              lineHeight: "24px",
              fontWeight: "500",
              color: colors.darkBlue,
            }}
          >
            {displayPrice(budgetSpent)}
          </Typography>
          <Typography
            component="span"
            sx={{
              fontSize: "12px",
              lineHeight: "20px",
              fontWeight: "400",
              color: colors.darkBlue,
            }}
          >
            {currency}
          </Typography>
        </Box>
        <Box
          sx={{
            width: "1px",
            height: "20px",
            backgroundColor: colors.dustBlue5,
            marginLeft: "16px",
            marginRight: "16px",
          }}
        />
        <ListDataCell
          label={t("fastTrackInquiries.products")}
          value={`${totalProducts}`}
          horizontal
        />
        {productsWithoutPrice > 0 && (
          <>
            <Typography
              component="span"
              sx={{
                color: colors.darkBlue,
                fontSize: "12px",
                lineHeight: "20px",
                marginLeft: "16px",
                marginRight: "16px",
              }}
            >
              +
            </Typography>
            <ListDataCell
              label={t("fastTrackInquiries.productsWithoutPrice", {
                count: productsWithoutPrice,
              })}
              value={`${productsWithoutPrice}`}
              horizontal
            />
          </>
        )}
      </Box>
    </>
  );
};
