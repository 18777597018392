import { Box } from "@mui/material";
import React from "react";
import { useSelector } from "../../../../store";
import { selectIsCartOpen } from "../../../../slices/shop/selectors";
import { colors } from "../../../../theme/astra/colors";

import { useIsFastTrack } from "../../../../slices/shop/hooks";

type BadgeProps = {
  quantity?: number;
};

export const Badge = ({ quantity }: BadgeProps) => {
  const isCartOpen = useSelector(selectIsCartOpen);
  const isFastTrack = useIsFastTrack();

  if (!quantity || isCartOpen) {
    return null;
  }

  return (
    <Box
      sx={{
        position: "absolute",
        top: "-7px",
        right: "-7px",
        width: "16px",
        height: "16px",
        backgroundColor: colors.whitishGray,
        color: isFastTrack ? colors.pink : colors.darkBlue,
        border: `1px solid ${isFastTrack ? colors.pink : colors.dustBlue4}`,
        fontSize: "10px",
        lineHeight: "135%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 1,
        borderRadius: "100%",
      }}
    >
      {quantity}
    </Box>
  );
};
