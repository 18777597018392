import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getUrl, prepareHeaders } from "../utils/api";
import { wishlistRecordsApi } from "./wishlistRecords/api";
import { shopApi } from "./shop/api";
import { schoolInquiriesApi } from "./schoolInquiries/api";
import { inquiriesApi } from "./inquiries/api";
import { inventoryApi } from "./inventory/api";
import { itemsApi } from "./items/api";
import { mainOrdersApi } from "./mainOrders/api";
import { productsApi } from "./products/api";
import { purchaseOrdersApi } from "./purchaseOrders/api";
import { newSearchRequestItemsApi } from "./new-search-request-items/api";
import { fastTrackInquiriesApi } from "./fastTrackInquiries/api";

/**
 * A mistake was made and this project currently has more than one api instance,
 * while the recommended approach is to have a single api unless absolutely necessary.
 * So from now on only add new apis here.
 */
export const api = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: getUrl("/"),
    prepareHeaders,
  }),
  tagTypes: [
    "Item",
    "MatchedProductsList",
    "Product",
    "ProductPreviews",
    "Inquiry",
    "FastTrackInquiry",
    "Assignment",
    "DetailedInquiry",
    "ItemReviewComment",
    "RequestedItemReviewComment",
    "PurchaseOrders",
    "MainOrders",
    "NextNewItemsList",
    "RequestedItem",
    "CartAssignments",
    "Inventory",
  ],
  endpoints: () => ({}),
});

/**
 * This is needed due to rtk-query's design flow where TypeScript doesn't know
 * which endpoints you have because you add them dynamically via .injectEndpoints.
 * So far I only use this coreApi for optimistic updates.
 */
export const coreApi = api as typeof api &
  typeof inquiriesApi &
  typeof inventoryApi &
  typeof itemsApi &
  typeof mainOrdersApi &
  typeof purchaseOrdersApi &
  typeof productsApi &
  typeof schoolInquiriesApi &
  typeof shopApi &
  typeof wishlistRecordsApi &
  typeof newSearchRequestItemsApi &
  typeof fastTrackInquiriesApi;
