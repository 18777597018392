import React, { useCallback } from "react";
import { FastForward } from "../../../components/astraIcons/FastForward";
import { Button } from "../../../components/astraComponents/Button";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "../../../store";
import { setIsOpen } from "../../../slices/fastTrackInquiries/slice";
import { selectIsFastTrackInquiryOpen } from "../../../slices/fastTrackInquiries/selectors";

export const FastTrackInquiryButton = () => {
  const { t } = useTranslation("pages");
  const dispatch = useDispatch();

  const isOpen = useSelector(selectIsFastTrackInquiryOpen);

  const clickHandler = useCallback(() => {
    dispatch(setIsOpen(true));
  }, [dispatch]);

  return (
    <Button
      fastTrack
      variant="contained"
      startIcon={<FastForward />}
      onClick={clickHandler}
      disabled={isOpen}
    >
      {t("fastTrackInquiries.title")}
    </Button>
  );
};
