import React, { useCallback } from "react";
import { Box, BoxProps, IconButton, Typography } from "@mui/material";
import { Add, Remove } from "@mui/icons-material";

type SimpleNumericControlProps = Omit<BoxProps, "onChange"> & {
  value: number;
  onChange: (value: number) => void;
  positiveOnly?: boolean;
  disabled?: boolean;
  pending?: boolean;
};

export const SimpleNumericControl = ({
  value,
  onChange,
  positiveOnly,
  disabled,
  pending,
  sx,
  ...props
}: SimpleNumericControlProps) => {
  const addHandler = useCallback(() => {
    onChange(value + 1);
  }, [onChange, value]);

  const subtractHandler = useCallback(() => {
    const newValue = value - 1;

    if (positiveOnly && newValue < 0) {
      return;
    }

    onChange(newValue);
  }, [onChange, positiveOnly, value]);

  return (
    <Box
      {...props}
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 1,
        ...sx,
      }}
    >
      <IconButton
        onClick={subtractHandler}
        disabled={(positiveOnly && value < 2) || disabled}
        size="small"
      >
        <Remove fontSize="small" />
      </IconButton>
      <Typography color={pending ? "gray" : "main"}>{value}</Typography>
      <IconButton onClick={addHandler} disabled={disabled} size="small">
        <Add fontSize="small" />
      </IconButton>
    </Box>
  );
};
