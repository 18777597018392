import React from "react";
import { Typography } from "@mui/material";
import { colors } from "../../../../theme/astra/colors";

export const Title = ({ title }: { title: string }) => {
  return (
    <Typography
      component="span"
      sx={{
        fontSize: "22px",
        fontWeight: "500",
        lineHeight: "29px",
        letterSpacing: "0.4%",
        color: colors.pink,
      }}
    >
      {title}
    </Typography>
  );
};
