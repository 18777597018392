import React, { useCallback, useEffect, useState } from "react";
import { CartItem } from "../../../slices/shop/types";
import { useMessageHandlers } from "../../../utils/useMessageHandlers";
import { useShopList } from "../../../slices/shop/hooks";
import { NumericFieldProps } from "../../../components/fields/NumericField";
import {
  useAddToCartMutation,
  useRemoveFromCartMutation,
} from "../../../slices/shop/api";
import { Item } from "./Item/Item";

type RegularItemProps = {
  item: CartItem;
};

export const RegularItem = ({
  item: {
    product: { id, images, name, retailCurrency },
    quantity,
    totalAmount,
    requestedItemSchoolStatus,
    assignmentSchoolStatus,
  },
}: RegularItemProps) => {
  const { handleSuccess, handleError } = useMessageHandlers();

  const shopList = useShopList();

  const [localQuantity, setLocalQuantity] =
    useState<NumericFieldProps["value"]>(quantity);

  useEffect(() => {
    setLocalQuantity(quantity);
  }, [quantity]);

  const [addToCart, { isLoading: isAdding }] = useAddToCartMutation();
  const [removeFromCart, { isLoading: isRemoving }] =
    useRemoveFromCartMutation();

  const changeQuantityHandler = useCallback(
    (currentQuantity: number, newQuantity: number) => {
      if (!shopList) {
        return;
      }

      if (currentQuantity > newQuantity) {
        removeFromCart({
          inquiryId: shopList.inquiryId,
          departmentInquiryId: shopList.departmentInquiryId,
          assignmentId: shopList.id,
          productId: id,
          quantity: currentQuantity - newQuantity,
        })
          .unwrap()
          .then(handleSuccess)
          .catch(handleError);
      }

      if (currentQuantity < newQuantity) {
        addToCart({
          inquiryId: shopList.inquiryId,
          departmentInquiryId: shopList.departmentInquiryId,
          assignmentId: shopList.id,
          productId: id,
          quantity: newQuantity - currentQuantity,
        })
          .unwrap()
          .then(handleSuccess)
          .catch(handleError);
      }
    },
    [addToCart, handleError, handleSuccess, id, removeFromCart, shopList]
  );

  const [
    removeFromCartCompletely,
    { isLoading: isRemovingFromCartCompletely },
  ] = useRemoveFromCartMutation();

  const removeItemHandler = useCallback(() => {
    if (!shopList) {
      return;
    }

    removeFromCartCompletely({
      inquiryId: shopList.inquiryId,
      departmentInquiryId: shopList.departmentInquiryId,
      assignmentId: shopList.id,
      productId: id,
      quantity,
    })
      .unwrap()
      .then(handleSuccess)
      .catch(handleError);
  }, [
    handleError,
    handleSuccess,
    id,
    quantity,
    removeFromCartCompletely,
    shopList,
  ]);

  return (
    <Item
      {...{
        id,
        images,
        name,
        totalAmount,
        retailCurrency,
        quantity,
        requestedItemSchoolStatus,
        assignmentSchoolStatus,
      }}
      quantityInputValue={localQuantity}
      onQuantityInputValueChange={setLocalQuantity}
      onQuantityChange={changeQuantityHandler}
      isChangingQuantity={isAdding || isRemoving}
      onRemoveItem={removeItemHandler}
      isRemoving={isRemovingFromCartCompletely}
    />
  );
};
