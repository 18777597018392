import React, { useCallback, useEffect, useMemo } from "react";
import { alpha, Box, BoxProps } from "@mui/material";
import {
  ListColumn,
  ListExpandedIds,
  ListExpandedIdsChangeHandler,
  ListIsEverythingExpanded,
  ListIsEverythingSelected,
  ListSelectedIds,
  ListSelectedIdsChangeHandler,
  RowBase,
} from "./types";
import { colors } from "../../theme/astra/colors";
import { Header } from "./Header";
import { useListGrid } from "./useListGrid";
import { Rows } from "./Rows/Rows";

type ListProps<
  Row extends RowBase,
  SubRow extends RowBase = never,
  SubRowsKey extends keyof Row | undefined = undefined
> = BoxProps & {
  columns: ListColumn<Row, SubRow, SubRowsKey>[];
  rows: Row[];
  selectedIds?: ListSelectedIds;
  onSelectedIdsChange?: ListSelectedIdsChangeHandler;
  displaySkeleton?: boolean;
  subRowsKey?: SubRowsKey;
  expandedIds?: ListExpandedIds;
  onExpandedIdsChange?: ListExpandedIdsChangeHandler;
  bottomRow?: React.ReactNode | undefined | null;
};

/**
 * Table-like list
 */
export const List = <
  Row extends RowBase,
  SubRow extends RowBase = never,
  SubRowsKey extends keyof Row | undefined = undefined
>({
  columns,
  rows,
  sx,
  selectedIds,
  onSelectedIdsChange,
  displaySkeleton,
  subRowsKey,
  expandedIds,
  onExpandedIdsChange,
  bottomRow = null,
  ...props
}: ListProps<Row, SubRow, SubRowsKey>) => {
  const displayExpandableRows = typeof subRowsKey === "string";

  const grid = useListGrid({
    columns,
    displayCheckbox: !!selectedIds,
    displayExpandableRows,
  });

  useEffect(() => {
    const rowsIds = rows.map((row) => row.id);

    if (
      selectedIds &&
      onSelectedIdsChange &&
      selectedIds.length > 0 &&
      selectedIds.some((selectedId) => !rowsIds.includes(selectedId))
    ) {
      onSelectedIdsChange([]);
    }

    if (
      expandedIds &&
      onExpandedIdsChange &&
      expandedIds.length > 0 &&
      expandedIds.some((expandedId) => !rowsIds.includes(expandedId))
    ) {
      onExpandedIdsChange([]);
    }
  }, [
    expandedIds,
    onExpandedIdsChange,
    onSelectedIdsChange,
    rows,
    selectedIds,
  ]);

  const isEverythingSelected = useMemo<ListIsEverythingSelected>(() => {
    if (!selectedIds) {
      return undefined;
    }

    if (selectedIds.length === 0) {
      return "none";
    }

    if (rows.every(({ id }) => selectedIds.includes(id))) {
      return "all";
    }

    return "part";
  }, [rows, selectedIds]);

  const isEverythingExpanded = useMemo<ListIsEverythingExpanded>(() => {
    if (!expandedIds || !displayExpandableRows) {
      return undefined;
    }

    if (expandedIds.length === 0) {
      return "none";
    }

    if (rows.every(({ id }) => expandedIds.includes(id))) {
      return "all";
    }

    return "part";
  }, [displayExpandableRows, expandedIds, rows]);

  const expandHandler = useCallback(() => {
    if (!isEverythingExpanded || !onExpandedIdsChange) {
      return;
    }

    if (isEverythingExpanded === "none" || isEverythingExpanded === "part") {
      onExpandedIdsChange(rows.map(({ id }) => id));
    }

    if (isEverythingExpanded === "all") {
      onExpandedIdsChange([]);
    }
  }, [isEverythingExpanded, onExpandedIdsChange, rows]);

  const selectEverythingHandler = useCallback(
    (checked: boolean) => {
      if (onSelectedIdsChange) {
        onSelectedIdsChange(checked ? rows.map((row) => row.id) : []);
      }
    },
    [onSelectedIdsChange, rows]
  );

  return (
    <Box
      sx={{
        ...grid,
        border: `1px solid ${colors.dustBlue5}`,
        borderRadius: "4px",
        boxShadow: `2px 4px 10px 0px ${alpha(colors.darkBlue, 0.05)}`,
        ...sx,
      }}
      {...props}
    >
      <Header
        columns={columns}
        selected={isEverythingSelected}
        onSelectedChange={selectEverythingHandler}
        expanded={isEverythingExpanded}
        onExpand={expandHandler}
      />
      <Rows
        columns={columns}
        rows={rows}
        selectedIds={selectedIds}
        onSelectedIdsChange={onSelectedIdsChange}
        displaySkeleton={displaySkeleton}
        subRowsKey={subRowsKey}
        expandedIds={expandedIds}
        onExpandedIdsChange={onExpandedIdsChange}
      />
      {bottomRow}
    </Box>
  );
};
