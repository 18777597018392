import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDisplayPrice } from "../utils/useDisplayPrice";
import { useIsSchoolTeam } from "../utils/useIsSchoolTeam";
import { useGetCategoriesQuery } from "../slices/categories/api";
import { useGetSchoolInquiryItemQuery } from "../slices/schoolInquiries/api";
import { EnhancedSkeleton } from "../components/EnhancedSkeleton";
import { Box, Divider, Paper, Typography } from "@mui/material";
import { ImageSliderFixedHeight } from "../components/ImageSliderFixedHeight";
import { AstraDisplayContainer } from "../components/astraWideDrawer/AstraDisplayContainer";
import { colors } from "../theme/astra/colors";
import { Description } from "../components/Description";
import { NewDesignEntityDetailsGrid } from "../components/NewDesignEntityDetailsGrid";

type ItemDetailsProps = {
  inquiryId: number;
  itemId: number;
  closeDrawer: () => void;
};

export const ItemDetails = ({
  inquiryId,
  itemId,
  closeDrawer,
}: ItemDetailsProps) => {
  const { t } = useTranslation("reusableContainers");

  const displayPrice = useDisplayPrice();
  const isSchoolUser = useIsSchoolTeam();

  const { isLoading: areCategoriesLoading } = useGetCategoriesQuery();

  const {
    data: item,
    isLoading: isItemLoading,
    error: itemError,
  } = useGetSchoolInquiryItemQuery({
    inquiryId,
    itemId,
  });

  useEffect(() => {
    if (itemError) {
      closeDrawer();
    }
  }, [closeDrawer, itemError]);

  const na = t("na", { ns: "common" });
  const priceUponRequest = t("priceUponRequest", { ns: "common" });

  const isLoading = isItemLoading || areCategoriesLoading || !item;

  const image = useMemo(() => {
    if (isLoading) {
      return (
        <EnhancedSkeleton
          variant="text"
          sx={{ width: "100%", height: "360px" }}
        />
      );
    }

    if (item.images.length === 0) {
      return (
        <Typography variant="body2" sx={{ marginTop: 0.5 }}>
          {na}
        </Typography>
      );
    }

    return (
      <ImageSliderFixedHeight
        images={item.images.map(({ url }) => ({ src: url }))}
        height={360}
        sx={{
          width: "100%",
          height: "auto",
          borderRadius: "4px",
          margin: 0,
          marginTop: "-20px",
          marginBottom: "-15px",
          "& img": {
            borderRadius: "4px",
          },
        }}
      />
    );
  }, [isLoading, item?.images, na]);

  const price = useMemo(() => {
    if (!item) {
      return na;
    }
    const isPricePresent =
      item.productRetailPrice && item.productRetailCurrency;

    if (item.supplierProductCode && !isPricePresent) {
      return priceUponRequest;
    }

    const priceFormatted = displayPrice(item.productRetailPrice) ?? na;
    const currencyCode = item.productRetailCurrency?.code ?? na;

    if (!item.supplierProductCode) {
      return priceFormatted;
    }

    return `${priceFormatted} ${currencyCode}`;
  }, [displayPrice, item, na, priceUponRequest]);

  return (
    <AstraDisplayContainer>
      <Paper
        sx={{
          margin: 1,
          padding: 1,
        }}
      >
        {image}
        <Typography
          dir="auto"
          sx={{
            fontSize: "20px",
            fontWeight: "500",
            color: colors.darkBlue,
            marginTop: "24px",
            marginBottom: "8px",
          }}
        >
          {isLoading ? <EnhancedSkeleton variant="text" /> : item.name ?? na}
        </Typography>
        {isLoading ? (
          Array.from(new Array(3)).map((_value, index) => (
            <EnhancedSkeleton
              key={index}
              width={index === 2 ? [50, 70, "%"] : "100%"}
              sx={{ lineHeight: "19px" }}
            />
          ))
        ) : (
          <Description
            content={item.description ?? na}
            sx={{
              "p:first-child": { marginTop: 0.5 },
              "p:last-child": { marginBottom: 0 },
              fontSize: "13px",
              fontWeight: "400",
              color: colors.darkBlue,
            }}
          />
        )}
        <Box
          sx={{
            marginTop: "32px",
            marginBottom: "12px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "16px",
          }}
        >
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "500",
              color: colors.dustBlue1,
            }}
          >
            Product Info
          </Typography>
          <Divider
            sx={{
              flexGrow: 1,
              borderBottomColor: colors.dustBlue4,
            }}
          />
        </Box>
        <NewDesignEntityDetailsGrid
          headerStyles={{
            fontSize: "12px",
            fontWeight: "400",
            color: colors.dustBlue2,
            marginBottom: "0px",
          }}
          valueStyles={{
            fontSize: "13px",
            fontWeight: "400",
            color: colors.darkBlue,
          }}
          blocks={[
            [
              {
                label: t("itemDetails.fields.productCode"),
                ...(isLoading
                  ? { loading: true }
                  : {
                      value: `${item.supplierProductCode.code}`,
                    }),
                xs: 6,
              },
              {
                label: t("itemDetails.fields.price"),
                ...(isLoading ? { loading: true } : { value: price }),
                xs: 6,
              },
            ],
            [
              {
                label: t("itemDetails.fields.weight"),
                ...(isLoading
                  ? { loading: true }
                  : {
                      value:
                        (item.weight ?? na) +
                        ` ${t("units.kg", { ns: "common" })}`,
                    }),
                xs: 6,
              },
              {
                label: t("itemDetails.fields.wdhCm"),
                ...(isLoading
                  ? { loading: true }
                  : {
                      value:
                        item.sizeW && item.sizeD && item.sizeH
                          ? `${item.sizeW} x ${item.sizeD} x ${item.sizeH}`
                          : na,
                    }),
                xs: 6,
              },
              {
                label: t("itemDetails.fields.volume"),
                ...(isLoading
                  ? { loading: true }
                  : {
                      value:
                        (item.volume ?? na) +
                        ` ${t("units.m3", { ns: "common" })}`,
                    }),
                xs: 6,
              },
            ],
            isSchoolUser
              ? []
              : [
                  {
                    label: t("itemDetails.fields.country"),
                    ...(isLoading
                      ? { loading: true }
                      : { value: item.country?.name ?? na }),
                    xs: 6,
                  },
                  {
                    label: t("itemDetails.fields.color"),
                    ...(isLoading
                      ? { loading: true }
                      : { value: item.color ?? na }),
                    xs: 6,
                  },
                  {
                    label: t("itemDetails.fields.packSize"),
                    ...(isLoading
                      ? { loading: true }
                      : { value: (item.packSize?.toString() ?? na) + " pcs" }),
                    xs: 6,
                  },
                ],
          ]}
        />
      </Paper>
    </AstraDisplayContainer>
  );
};
