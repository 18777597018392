import React from "react";
import {
  AssignmentSchoolStatus,
  DepartmentInquirySchoolStatus,
} from "../../../../../../../types";
import {
  ASSIGNMENT_STATUS_CHIP_STYLES,
  DEPARTMENT_INQUIRY_STATUS_CHIP_STYLES,
} from "../../../../../../../consts";
import { StatusChip } from "../../../../../../../components/StatusChip";
import {
  useDisplayAssignmentSchoolStatus,
  useDisplayDepartmentInquirySchoolStatus,
} from "../../../../../../../slices/schoolInquiries/hooks";
import { Role } from "../../../../../../../permissions/permissions";
import { SchoolTitles } from "../../../../../../../slices/schoolInquiries/types";

type StatusProps =
  | {
      type: "departmentInquiry";
      status: DepartmentInquirySchoolStatus;
      schoolTitles: Pick<SchoolTitles, "principal" | "headOfDepartment">;
    }
  | {
      type: "assignment";
      status: AssignmentSchoolStatus;
      user: string;
      schoolTitles: SchoolTitles;
      role: Role;
    };

export const Status = (props: StatusProps) => {
  const displayDepartmentInquirySchoolStatus =
    useDisplayDepartmentInquirySchoolStatus();
  const displayAssignmentSchoolStatus = useDisplayAssignmentSchoolStatus();

  if (props.type === "departmentInquiry") {
    return (
      <StatusChip
        chipStyle={DEPARTMENT_INQUIRY_STATUS_CHIP_STYLES[props.status]}
        label={displayDepartmentInquirySchoolStatus({
          schoolStatus: props.status,
          ...props.schoolTitles,
        })}
        sx={{ marginLeft: "20px" }}
      />
    );
  }

  return (
    <StatusChip
      chipStyle={ASSIGNMENT_STATUS_CHIP_STYLES[props.status]}
      label={displayAssignmentSchoolStatus({
        schoolStatus: props.status,
        ownAssignment: props.role === "headOfDepartment",
        ...props.schoolTitles,
      })}
      sx={{ marginLeft: "20px" }}
    />
  );
};
